import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Button,
  Modal,
} from "@mui/material";
import Logo from "../assets/images/logo.png";
import AuthContext from "../context/authContext";
import { ToWords } from "to-words";
import { useReactToPrint } from "react-to-print";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "80%",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  overflow: "scroll",
  backgroundColor: "white",
};

const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

const styles = {
  invoiceContainer: {
    padding: "20px",
    border: "2px solid black",
    minHeight: "297mm", // A4 size height
    boxSizing: "border-box",
  },
  table: {
    marginTop: "20px",
  },
  signature: {
    height: "100px",
    border: "1px solid black",
    width: "200px",
    marginLeft: "auto",
  },
};
const LRpdf = ({ invoiceId, handleCloseInvoice, openInvoice }) => {
  const { userData, setRefresh } = useContext(AuthContext);
  const [editData, setEditData] = useState({});
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    if (invoiceId) {
      setEditData(userData?.consignments?.find((inv) => inv._id === invoiceId));
    }
  }, [invoiceId, openInvoice]);
  return (
    <Modal
      open={openInvoice}
      onClose={handleCloseInvoice}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={confirmStyle}>
        {/* <Container maxWidth="md"> */}
        <Paper elevation={3} style={styles.invoiceContainer} ref={componentRef}>
          {/* Header */}
          <Box textAlign="center" mb={2}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Logo} alt="Logo" style={{ width: "90px" }} />
              <Typography variant="h4" component="div">
                OM VAIRAVA LOGISTICS
              </Typography>
            </Box>
            <Typography variant="h6" component="div">
              TAX INVOICE
            </Typography>
          </Box>
          {/* From and To */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  FROM
                </Typography>
                <Typography variant="body2">Om Vairava Logistics</Typography>
                <Typography variant="body2">
                  {userData?.addressline1 || ""}
                </Typography>
                <Typography variant="body2">
                  {userData?.addressline2 || ""}, {userData?.city || ""}-
                  {userData?.pincode || ""}.
                </Typography>
                <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
                  GSTIN: {userData?.gstin || ""}
                </Typography>
                <Typography variant="body2">
                  Mobile: {userData?.contact || 0}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <Typography variant="subtitle1" fontWeight="bold">
                  TO
                </Typography>
                <Typography variant="body2">
                  {editData?.consignor?.name || ""}
                </Typography>
                <Typography variant="body2">
                  {editData?.consignor?.address}
                </Typography>
                <Typography variant="body2">
                  {editData?.consignor?.city || ""}-
                  {editData?.consignor?.pincode || 0}.
                </Typography>
                <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
                  GSTIN: {editData?.consignor?.gstin || ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Divider style={{ margin: "20px 0" }} />
          {/* Invoice No and Date */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2">
                <strong>Invoice No.</strong> OVL-{editData?.invoiceno || 0}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="body2">
                <strong>Invoice Date</strong>{" "}
                {editData?.docketdate
                  ?.split("T")[0]
                  .split("-")
                  .reverse()
                  .join("-") || ""}
              </Typography>
            </Grid>
          </Grid>
          {/* Descriptions */}
          <Table style={styles.table} size="small" aria-label="invoice table">
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="right">Invoice Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>
                  Being Shipment Charges for Consignment-
                  {editData?.consignmentNumber || 0}
                </TableCell>
                <TableCell align="right">
                  {editData?.taxableAmount || 0}.00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  Taxable Amount
                </TableCell>
                <TableCell align="right">
                  {editData?.taxableAmount || 0}.00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  CGST @ {editData?.gstPercent / 2 || 0}.0%
                </TableCell>
                <TableCell align="right">
                  {editData?.gstValue / 2 || 0}.00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  SGST @ {editData?.gstPercent / 2 || 0}.0%
                </TableCell>
                <TableCell align="right">
                  {editData?.gstValue / 2 || 0}.00
                </TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell colSpan={2}>
                <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                  <strong>Description of Charges:</strong> As per agreed terms
                </Typography>
              </TableCell> */}
                <TableCell align="right" colSpan={2}>
                  <strong>Total Amount</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>{editData?.totalcharges}.00</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* Amount in words */}
          <Box mt={2}>
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", textTransform: "capitalize" }}
            >
              <strong>Amount In Words:</strong>{" "}
              {toWords.convert(Number(editData?.totalcharges || 0))}
            </Typography>
            <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
              <strong>Description of Charges:</strong> As per agreed terms
            </Typography>
          </Box>
          {/* Terms and Conditions */}
          <Box mt={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              Terms and Conditions:
            </Typography>
            <Typography variant="body2">
              1. GST By Road is to be paid by Consignor/Consignee.
            </Typography>
            <Typography variant="body2">
              2. Please let us confirm if any changes in bill. Should intimate
              within 5 hours of receipt of our bill.
            </Typography>
            <Typography variant="body2">
              3. Payment is to be released immediately.
            </Typography>
          </Box>
          {/* Bank Details */}
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
              mt: 2,
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box mt={2}>
              <Typography variant="body2">
                <strong>Bank Name:</strong> INDIAN OVERSEAS BANK
              </Typography>
              <Typography variant="body2">
                <strong>IFSC:</strong> IOBA0003096
              </Typography>
              <Typography variant="body2">
                <strong>Account Name:</strong> OM VAIRAVA LOGISTICS
              </Typography>
              <Typography variant="body2">
                <strong>Account No:</strong> 309602000000271 (CA)
              </Typography>
            </Box>
            <Box mt={2} textAlign="center">
              <Typography variant="body2">
                <strong>For Om Vairava Logistics</strong>
              </Typography>
              <Box mt={2} style={styles.signature}>
                {/* Add Signature Image or Representation here */}
              </Box>
            </Box>
          </Box>
          {/* Address Details */}
          <Box mt={4} textAlign="center">
            <Typography variant="caption">
              <strong>Registered Office:</strong> No.492/2, Thiruvallur High
              Road, Vaniyan Chathiram, Thiruvallur, Chennai-600 052.
              <br />
              <strong>Email:</strong> maharaja@omvairava.com{" "}
              <strong>Web:</strong> www.omvairava.com
            </Typography>
          </Box>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handlePrint}
            style={{ marginTop: 20 }}
          >
            Print
          </Button>
        </Box>
        {/* </Container> */}
      </Box>
    </Modal>
  );
};

export default LRpdf;

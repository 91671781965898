// AuthContext.js
import React, { createContext, useEffect, useState, useMemo } from "react";
import axios from "../api/axios";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [branches, setBranches] = useState([]);
  const [trips, setTrips] = useState([]);
  const [totalConsignments, setTotalConsignments] = useState([]);
  const [totalBranches, setTotalBranches] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);

  // Function to refresh user data
  const refreshUserData = async () => {
    try {
      const response = await axios.get("/handledata", {
        headers: {
          "x-auth-token": localStorage.getItem("authTokens"),
          branchId: localStorage.getItem("branchId"),
          branch_id: localStorage.getItem("currentbranch"),
          currentbranch: localStorage.getItem("currentbranch"),
          companyid: localStorage.getItem("companyId"),
        },
      });
      setUserData(response.data.currentBranch);
      setBranches(response.data.associatedBranches);
      setTrips(response.data.trips);
      setTotalConsignments(response.data.totalConsignments);
      setTotalBranches(response.data.totalBranches);
      setRefresh(false);
      setLoggedIn(true);
    } catch (error) {
      console.error("Error refreshing user data:", error);
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    if (refresh) {
      refreshUserData();
    }
  }, [refresh]);
  useEffect(() => {
    refreshUserData();
  }, []);
  const memoizedUserData = useMemo(() => userData, [userData]);
  const memoizedBranches = useMemo(() => branches, [branches]);
  const memoizedTotalBranches = useMemo(() => totalBranches, [totalBranches]);
  const memoizedTrips = useMemo(() => trips, [trips]);
  const memoizedConsignments = useMemo(
    () => totalConsignments,
    [totalConsignments]
  );

  return (
    <AuthContext.Provider
      value={{
        userData: memoizedUserData,
        branches: memoizedBranches,
        trips: memoizedTrips,
        totalConsignments: memoizedConsignments,
        totalBranches: memoizedTotalBranches,
        setRefresh,
        isLoggedIn: loggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React, { useContext } from "react";
import { Button } from "@mui/material";
import ThemeContext from "../../context/themeContext";

const Buttons = ({ text, onclicks, sx }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Button
      sx={{
        ...sx,
        backgroundColor: `${theme.button.active}`,
      }}
      onClick={onclicks}
      variant="contained"
    >
      {text}
    </Button>
  );
};

export default Buttons;

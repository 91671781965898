import React, { useContext, useEffect, useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import { Edit as EditIcon, Save as SaveIcon } from "@mui/icons-material";
import ThemeContext from "../context/themeContext";
import AuthContext from "../context/authContext";
import toast from "react-hot-toast";
import axiosInstance from "../api/axios";

const ProfileFields = ({ name, value, handleChange, isEditMode, label }) => {
  const { theme } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        color: theme.text.secondary,
        flexWrap: "wrap",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Typography sx={{ fontWeight: "bold", flex: 1, whiteSpace: "nowrap" }}>
        {label}:
      </Typography>
      {isEditMode ? (
        <TextField
          name={name}
          value={value}
          onChange={handleChange}
          size="small"
          sx={{
            fontWeight: "bold",
            flex: 2,
          }}
        />
      ) : (
        <Typography sx={{ fontWeight: "bold", flex: 2 }}>{value}</Typography>
      )}
    </Box>
  );
};

const Account = () => {
  const { theme } = useContext(ThemeContext);
  const { userData, setRefresh } = useContext(AuthContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [profile, setProfile] = useState({
    companyName: "",
    companyType: "",
    address: "",
    email: "",
    officeNumber: "",
    address1: "",
    address2: "",
    pincode: 0,

    currentUserName: "",
    currentUserEmail: "",
    currentUserPhone: "",
    currentUserDesignation: "",

    loginId: "",
    username: "",
    branchCode: "",
    branchLocation: "",
    branchid: "",
    companyid: "",

    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleEditToggle = () => {
    setIsEditMode(!isEditMode);
  };
  const handleChangePassword = async () => {
    // Handle password change logic here
    const { oldPassword, newPassword, confirmPassword } = profile;

    if (newPassword !== confirmPassword) {
      console.log("Passwords do not match");
      toast.error("Passwords do not match");
    }
    const res = await axiosInstance.post("/updatePassword", {
      oldPassword,
      newPassword,
    });
    if (res.status === 200) {
      toast.success("Password changed successfully");
      setRefresh(true);
      setProfile((pro) => ({
        ...pro,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleChange = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    // Handle save logic here, such as API call to update profile
    const newProfile = {
      companyName: profile.companyName,
      companyType: profile.companyType,
      address: profile.address,
      addressline1: profile.address1,
      addressline2: profile.address2,
      email: profile.email,
      officeNumber: profile.officeNumber,
      currentUserName: profile.currentUserName,
      currentUserEmail: profile.currentUserEmail,
      currentUserPhone: profile.currentUserPhone,
      currentUserDesignation: profile.currentUserDesignation,
      pincode: profile.pincode,
    };
    const res = await axiosInstance.post("/updateprofile", { newProfile });
    if (res.status === 200) {
      toast.success("Profile updated successfully");
      setRefresh(true);
      setIsEditMode(false);
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (userData) {
      setProfile({
        companyName: userData?.companyName,
        companyType: userData?.companyType,
        address: userData?.address,
        address1: userData?.addressline1,
        address2: userData?.addressline2,
        pincode: userData?.pincode,
        email: userData?.email,
        officeNumber: userData?.contact,
        currentUserName: userData?.currentUserName,
        currentUserEmail: userData?.currentUserEmail,
        currentUserPhone: userData?.currentUserPhone,
        currentUserDesignation: userData?.currentUserDesignation,
        loginId: userData?.loginId || 0,
        username: userData?.branchid?.toUpperCase(),
        branchCode: userData?.branchCode,
        branchLocation: userData?.branchLocation?.toUpperCase(),
        branchid: userData?.branchid,
        companyid: userData?.companyId?.toUpperCase(),
      });
    }
  }, [userData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "20px",
        flexDirection: "column",
        height: "90%",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          color: theme.text.primary,
          marginTop: "50px",
        }}
      >
        Profile Page
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          gap: "20px",
          marginTop: "20px",
          // flexWrap: "wrap",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {/* Box 1 */}
        <Box
          sx={{
            flex: 2.5,
            display: "flex",
            border: `2px solid  ${theme.text.primary}`,
            borderRadius: "10px",
            flexDirection: "column",
            padding: "20px",
            gap: "20px",
            justifyContent: "space-between",
            overflow: "auto",
            minHeight: "70vh",
          }}
        >
          {/* Edit Button */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            {isEditMode ? (
              <Button
                onClick={handleSave}
                sx={{
                  margin: "10px",
                  border: `2px solid  ${theme.text.primary}`,
                  borderRadius: "10px",
                }}
              >
                <SaveIcon />
              </Button>
            ) : (
              <Button
                onClick={handleEditToggle}
                sx={{
                  margin: "10px",
                  border: `2px solid  ${theme.text.primary}`,
                  borderRadius: "10px",
                }}
              >
                <EditIcon />
              </Button>
            )}
          </Box>
          {/* Company Name */}
          <ProfileFields
            label="Company Name"
            name="companyName"
            value={profile.companyName}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          {/* Company Type */}
          <ProfileFields
            label="Company Type"
            name="companyType"
            value={profile.companyType}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          {/* Company Address */}
          <ProfileFields
            label="Company Address"
            name="address"
            value={profile.address}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          <ProfileFields
            label="Invoice Address line 1"
            name="address1"
            value={profile.address1}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          <ProfileFields
            label=" Invoice Address line 2"
            name="address2"
            value={profile.address2}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          <ProfileFields
            label=" Invoice Pincode"
            name="pincode"
            value={profile.pincode}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          {/* Company Email */}

          <ProfileFields
            label="Company Email"
            name="companyEmail"
            value={profile.companyEmail}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />

          {/* Office Number */}
          <ProfileFields
            label="Office Number"
            name="officeNumber"
            value={profile.officeNumber}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          <Typography
            sx={{
              fontWeight: "bold",
              margin: "10px",
              color: theme.text.secondary,
            }}
          >
            Current User:
          </Typography>
          {/* Name */}

          <ProfileFields
            label="Name"
            name="currentUserName"
            value={profile.currentUserName}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          {/* Email */}
          <ProfileFields
            label="Email"
            name="currentUserEmail"
            value={profile.currentUserEmail}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          {/* Number */}
          <ProfileFields
            label="Phone"
            name="currentUserPhone"
            value={profile.currentUserPhone}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
          {/* User Designation */}

          <ProfileFields
            label="Designation"
            name="currentUserDesignation"
            value={profile.currentUserDesignation}
            handleChange={handleChange}
            isEditMode={isEditMode}
          />
        </Box>
        {/* Box 2 */}
        <Box
          sx={{
            flex: 2,
            display: "flex",
            border: `2px solid  ${theme.text.primary}`,
            borderRadius: "10px",
            flexDirection: "column",
            gap: "50px",
            padding: "20px",
            height: "max-content",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              flex: 2,
              display: "flex",
              borderRadius: "10px",
              flexDirection: "column",
              gap: "20px",
              height: "max-content",
              color: theme.text.secondary,
            }}
          >
            {/* Old Password */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", flex: 1 }}>
                Old Password
              </Typography>

              <TextField
                name="oldPassword"
                value={profile.oldPassword}
                onChange={handleChange}
                size="small"
                sx={{
                  fontWeight: "bold",
                  flex: 2,
                  border: `2px solid  ${theme.text.secondary}`,
                }}
              />
            </Box>
            {/* New Password */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", flex: 1 }}>
                New Password
              </Typography>

              <TextField
                name="newPassword"
                value={profile.newPassword}
                onChange={handleChange}
                size="small"
                sx={{
                  fontWeight: "bold",
                  flex: 2,
                  border: `2px solid  ${theme.text.secondary}`,
                }}
              />
            </Box>
            {/* Confirm Password */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ fontWeight: "bold", flex: 1 }}>
                Confirm Password
              </Typography>

              <TextField
                name="confirmPassword"
                value={profile.confirmPassword}
                onChange={handleChange}
                size="small"
                sx={{
                  fontWeight: "bold",
                  flex: 2,
                  border: `2px solid  ${theme.text.secondary}`,
                }}
              />
            </Box>
            <Button variant="contained" onClick={handleChangePassword}>
              Submit
            </Button>
          </Box>

          <Box
            sx={{
              flex: 2,
              display: "flex",
              borderRadius: "10px",
              flexDirection: "column",
              gap: "20px",
              height: "max-content",
            }}
          >
            {/* LoginID */}
            <ProfileFields
              label="Login ID"
              name="loginId"
              value={profile.loginId}
              handleChange={handleChange}
            />
            {/* Username */}
            <ProfileFields
              label="Username"
              name="username"
              value={profile.username}
              handleChange={handleChange}
            />
            {/* Branch Code */}
            <ProfileFields
              label="Branch Code"
              name="branchCode"
              value={profile.branchCode}
              handleChange={handleChange}
            />
            {/* Branch Location */}
            <ProfileFields
              label="Branch Location"
              name="branchLocation"
              value={profile.branchLocation}
              handleChange={handleChange}
            />
            {/* Branch ID */}
            <ProfileFields
              label="Branch ID"
              name="branchid"
              value={profile.branchid}
              handleChange={handleChange}
            />
            {/* Company ID */}
            <ProfileFields
              label="Company ID"
              name="companyid"
              value={profile.companyid}
              handleChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Account;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { Dropdown, Menu, MenuButton, IconButton, MenuItem } from "@mui/joy";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import Buttons from "../../../components/misc/button";
import Grid from "../../../components/misc/grid";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import AddFTL from "./addftl";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewFTL from "../../../components/modals/viewFTL";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const FTL = () => {
  const navigate = useNavigate();
  const { userData, setRefresh, branches, trips } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [tripTableData, setTripTableData] = useState([]);
  const [OpenAddFTL, setOpenAddFTL] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [openCompleteConfirmation, setOpenCompleteConfirmation] =
    useState(false);
  const currentbranch = localStorage.getItem("currentbranch");
  const [tripId, setTripId] = useState("");
  const [ftlid, setftlid] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editTripData, setEditTripData] = useState({});
  const [openViewConsignment, setOpenViewConsignment] = useState(false);
  const [selectConsData, setSelectConsData] = useState();
  const [selectTripData, setSelectTripData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewConsignments = (id) => {
    setOpenViewConsignment(true);
    const selectTrip = userData?.ftl?.find((trip) => trip._id === id);
    const selectConsignment = userData?.consignments?.filter(
      (consignment) => consignment.tripid === id
    );
    setSelectTripData(selectTrip);
    setSelectConsData(selectConsignment);
  };
  const handleCloseView = () => {
    setOpenViewConsignment(false);
  };

  const handleCloseCompleteConfirmation = () => {
    setOpenCompleteConfirmation(false);
    setTripId("");
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setTripId("");
  };
  const handleCloseAddFTL = () => {
    setOpenAddFTL(false);
    setEditMode(false);
    setEditTripData({});
  };

  const handleOpenAddTrip = () => {
    setEditMode(false);
    setEditTripData({});
    setOpenAddFTL(true);
  };

  const handleDelete = async (ftlid) => {
    const res = await axiosInstance.post(`/ftl/delete/${ftlid}`);
    if (res.status === 200) {
      setRefresh(true);
      toast.success("FTL Deleted Successfully");
    } else {
      toast.error("Something went wrong");
    }
  };
  const handleEdit = (id) => {
    const filtrd = tripTableData.filter((item) => item._id === id);
    setEditTripData(filtrd[0]);
    setEditMode(true);
    setOpenAddFTL(true);
  };
  const handleConfirmation = (id) => {
    setOpenConfirmation(true);
    setTripId(id);
  };

  const handleLoadConsignments = (id) => {
    navigate("/dashboard/loadftl/" + id);
  };

  const handleCompleteTrip = async () => {
    const res = await axiosInstance.post(`/ftl/complete/${ftlid}`);
    if (res.status === 200) {
      setRefresh(true);
      handleCloseCompleteConfirmation();
      toast.success("Trip Completed Successfully");
    } else {
      handleCloseCompleteConfirmation();
      toast.error("Something went wrong");
    }
  };
  const handleStartTrip = async () => {
    const res = await axiosInstance.post(`/ftl/start/${tripId}`);
    if (res.status === 200) {
      setRefresh(true);
      handleCloseConfirmation();
      toast.success("Trip Started Successfully");
    } else {
      handleCloseConfirmation();
      toast.error("Something went wrong");
    }
  };
  const handleCompleteDelivery = async (id) => {
    setftlid(id);
    setOpenCompleteConfirmation(true);
  };

  useEffect(() => {
    if (userData) {
      setTripTableData(
        userData?.ftl?.filter((item) => item.status !== "erased")?.reverse()
      );
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",

      minWidth: 70,
    },
    {
      field: "vehicleno",
      headerName: "Vehicle",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "driverName",
      headerName: "Driver",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "vehicletype",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "deliverycity",
      headerName: "Delivery City",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "loadedConsignments",
      headerName: "Consignments",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "controls",
      headerName: "Controls",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        const isCurrentBranch =
          String(params?.row?.currentBranch) === String(currentbranch);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "100%",
              maxHeight: "100%",
              marginTop: "6px",
              flexDirection: "column",
            }}
          >
            {params?.row?.status === "Ready for Loading" && (
              <Button
                sx={{
                  padding: "5px",
                  width: "100%",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "green",
                    color: "#fff",
                  },
                  backgroundColor: "green",
                  borderRadius: "10px",
                }}
                onClick={() => handleConfirmation(params.row._id)}
              >
                Start Trip
              </Button>
            )}
            {params?.row?.status === "Processing" && (
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "orange",
                    color: "#fff",
                  },
                  backgroundColor: "orange",
                  borderRadius: "10px",
                }}
                onClick={() => handleLoadConsignments(params.row._id)}
              >
                Load Consignments
              </Button>
            )}

            {params?.row?.status === "In-transit" && (
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  width: "100%",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "orange",
                    color: "#fff",
                  },
                  backgroundColor: "orange",
                  borderRadius: "10px",
                }}
                onClick={() => handleCompleteDelivery(params.row._id)}
              >
                Delivered
              </Button>
            )}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              sx={{
                border: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <MoreVertIcon sx={{ color: theme.text.secondary }} />
            </MenuButton>
            <Menu>
              {(params.row.status === "Processing" ||
                params.row.status === "Ready for Loading") && (
                <MenuItem
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleEdit(params.row._id)}
                >
                  Edit Trip
                </MenuItem>
              )}

              {params.row.status === "Processing" && (
                <MenuItem
                  title="Delete Trip"
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleDelete(params.row._id)}
                >
                  Delete Trip
                </MenuItem>
              )}
              {(params.row.status === "Completed" ||
                params.row.status === "Ready for Loading" ||
                params.row.status === "In-transit") && (
                <MenuItem
                  title="View Consignment Details"
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleViewConsignments(params.row._id)}
                >
                  View Consignments
                </MenuItem>
              )}
            </Menu>
          </Dropdown>
        </Box>
      ),
    },
  ];

  const rows = tripTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    pickupCity: item.pickupCity,
    vehicleno: item.vehicleNo,
    vehicletype: item.vehicleType,
    vehicleowner: item.vehicleOwner,
    vehiclePhone: item.vehiclePhone,
    driverName: item.driverName,
    driverPhone: item.driverPhone,
    driverlicenseno: item.driverLicenseNo,
    deliverycity: item.deliveryCity,
    deliveryaddress: item.deliveryAdd,
    notes: item.notes,
    status: item.status,
    loadedConsignments: item?.LoadedConsignments.length || 0,
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Full-Truck Load
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          padding: "10px",
          flexWrap: "wrap",
          gap: "10px",
          mt: 3,
        }}
      >
        <Buttons text={"Create FTL"} onclicks={handleOpenAddTrip} />
        {/* Search Field */}
        <TextField
          label="Search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          variant="standard"
          size="small"
          sx={{
            width: "300px",
            backgroundColor: theme.background.button,
            borderRadius: "10px",

            "& .MuiInputBase-input": {
              color: theme.text.secondary,
            },
          }}
          InputLabelProps={{
            sx: {
              ml: 2,
              color: theme.text.primary, // Label color when not focused
              "&.Mui-focused": {
                color: theme.text.primary, // Label color when focused
              },
            },
          }}
        />
        {/* Enable Filters */}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.text.primary, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.text.primary, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.text.primary, // Label color
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <Grid
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={searchQuery}
        />
      </Box>
      <AddFTL
        OpenAddFTL={OpenAddFTL}
        handleCloseAddFTL={handleCloseAddFTL}
        editData={editTripData}
        editMode={editMode}
      />
      <Modal
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Trip Start?
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseConfirmation}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleStartTrip}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* handle Complete  */}
      <Modal
        open={openCompleteConfirmation}
        onClose={handleCloseCompleteConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Trip Completed?
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseCompleteConfirmation}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCompleteTrip}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* View Consignment */}
      <ViewFTL
        openViewConsignment={openViewConsignment}
        handleCloseView={handleCloseView}
        selectTripData={selectTripData}
        selectConsData={selectConsData}
      />
    </Box>
  );
};

export default FTL;

import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "./context/themeContext";
import axiosInstance from "./api/axios";
import { toast } from "react-hot-toast";

const Tracking = () => {
  const { theme } = useContext(ThemeContext);
  const [trackingId, setTrackingId] = useState("");
  const [trackingData, setTrackingData] = useState();

  const handleTrack = async () => {
    try {
      const res = await axiosInstance.get(`/tracking/${trackingId}`);
      if (res.status === 200) {
        setTrackingData(res.data);
        toast.success("Tracking success");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        margin: "10px 50px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "20px",
          marginTop: "20px",
        }}
      >
        Tracking
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          label="Tracking ID"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
          sx={{
            width: "300px",
            borderRadius: "5px",
            fontSize: "18px",
          }}
          variant="outlined"
        />
        <Button
          sx={{
            padding: "5px",
            backgroundColor: "#009FCF",
            color: "#fff",
            "&:hover": { backgroundColor: "#009FCF", color: "#fff" },
          }}
          onClick={handleTrack}
        >
          Track
        </Button>
      </Box>
      {trackingData && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            margin: "20px 0px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#009FCE",
              fontWeight: "bold",
              fontSize: "22px",
              flex: 1,
            }}
          >
            Tracking ID: {trackingId}
          </Typography>
          <Typography
            sx={{
              color: "#009FCE",
              fontSize: "22px",
              flex: 1,
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            Status: {trackingData?.consignmentStatus}
          </Typography>
        </Box>
      )}
      {trackingData && (
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              textAlign: "center",
              backgroundColor: "#009FCE",
              padding: "10px",
              color: "#fff",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                flex: 1,
                fontSize: "24px",
              }}
            >
              Date
            </Typography>
            <Typography sx={{ flex: 1, fontSize: "24px" }}>Time</Typography>
            <Typography sx={{ flex: 8, fontSize: "24px" }}>Status</Typography>
          </Box>
          {/* Events */}
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              flexDirection: "column",
              mt: 3,
              padding: "10px",
            }}
          >
            {trackingData?.history?.map((el) => (
              <Box sx={{ display: "flex", flex: 1, textAlign: "center" }}>
                <Typography
                  sx={{
                    color: theme.text.secondary,
                    flex: 1,
                    display: "flex",
                    fontSize: "20px",
                  }}
                >
                  {el.eventTime?.split("T")[0]}
                </Typography>
                <Typography
                  sx={{
                    color: theme.text.secondary,
                    flex: 1,
                    fontSize: "20px",
                  }}
                >
                  {el.eventTime?.split("T")[1].split(".")[0]}
                </Typography>
                <Typography
                  sx={{
                    color: theme.text.secondary,
                    flex: 8,
                    textAlign: "left",
                    fontSize: "20px",
                    ml: 3,
                  }}
                >
                  {el.consignmentStatus}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Tracking;

import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const SelectField = ({
  label,
  value,
  onChange,
  name,
  color,
  items,
  sx,
  shrink,
  defaultValue,
  variant,
  multiple = false,
}) => {
  return (
    <FormControl
      margin="normal"
      sx={{
        ...sx,
        "& .MuiInputLabel-root": { color: color }, // Label color
        "& .MuiInput-underline:before": {
          borderBottomColor: color,
        }, // Underline color before focus
        "& .MuiInput-underline:after": {
          borderBottomColor: color,
        }, // Underline color after focus
        "& .MuiInputBase-input": { color: color }, // Input text color
        "&:after": {
          borderBottomColor: color,
        },
        "& .MuiSvgIcon-root": {
          color: color,
        },
      }}
    >
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        name={name}
        variant={variant}
        size="small"
        multiple={multiple}
        defaultValue={defaultValue}
      >
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectField;

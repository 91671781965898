import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  // Stack,
  // Autocomplete,
  // TextField,
  Checkbox,
} from "@mui/material";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import TextFields from "../../../components/misc/textfield";
import SelectField from "../../../components/misc/selectfield";
import { toast } from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { useNavigate, useParams } from "react-router-dom";
import randomstring from "randomstring";

const RateCard = [
  {
    minWeight: 0,
    maxWeight: 25,
    Price: 15,
  },
  {
    minWeight: 25,
    maxWeight: 50,
    Price: 11,
  },
  {
    minWeight: 50,
    maxWeight: 100,
    Price: 10,
  },
  {
    minWeight: 100,
    maxWeight: 400,
    Price: 9,
  },
  {
    minWeight: 400,
    maxWeight: 450,
    Price: 8.5,
  },
  {
    minWeight: 450,
    maxWeight: 700,
    Price: 7.5,
  },
  {
    minWeight: 700,
    maxWeight: 900,
    Price: 7.2,
  },
  {
    minWeight: 900,
    maxWeight: 1000,
    Price: 6.5,
  },
  {
    minWeight: 1000,
    maxWeight: 2000,
    Price: 5,
  },
  {
    minWeight: 2000,
    maxWeight: 3000,
    Price: 4,
  },
  {
    minWeight: 3000,
    maxWeight: 4000,
    Price: 3.5,
  },
  {
    minWeight: 4000,
    maxWeight: 5000,
    Price: 3,
  },
];

const AddConsignments = () => {
  const { id, pickupid } = useParams();
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { userData, branches, setRefresh } = useContext(AuthContext);
  const [consignorType, setConsignorType] = useState("Individual");
  const [consignmentType, setConsignmentType] = useState("Normal");
  // const [businessConsigneeName, setBusinessConsigneeName] = useState("");
  // const [businessConsignorName, setBusinessConsignorName] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [files, setFiles] = useState([]);
  // const [error, setError] = useState("");
  const [insuranceRequired, setInsuranceRequired] = useState(false);
  const [insurance, setInsurance] = useState({
    insuranceno: "",
    insuranceCompany: "",
    insurancestartDate: "",
    insuranceexpiryDate: "",
  });
  const [consignorDetails, setConsignorDetails] = useState({
    name: "",
    contact: 0,
    address: "",
    email: "",
    gstin: "",
    pincode: 0,
    proofType: "",
    proofNo: "",
    city: "",
  });
  const [consigneeDetails, setConsigneeDetails] = useState({
    name: "",
    contact: 0,
    address: "",
    alternate: 0,
    email: "",
    gstin: "",
    pincode: 0,
    proofType: "",
    proofNo: "",
    city: "",
  });
  const [consignmentDetails, setConsignmentDetails] = useState({
    trackingid: "",
    consignmentType: "Outbound",
    additonalCharges: 0,
    deliveryto: "Branch",
    requestedby: "",
    deliverybranch: "",
    taxableAmount: 0,
    consignmentStatus: "",
    docketdate: new Date().toISOString().split("T")[0],
    serviceType: "Surface-Normal",
    deliveryPriority: "Road",
    SafetyPriority: "Economy",
    paymentType: "Prepaid",
    materialHold: false,
    waitingforPermit: false,
    Notes: "",
    // risk: "",
    promocode: "",
    lrWeight: "",
    actualWeightUom: "kgs",
    actualWeight: 0,
    lrnumber: "",
    lrchargefile: "",
    deliveryDate: "",
    includeGST: false,
    gstValue: 0,
    gstPercent: 0,
    totalcharges: 0,
    // invoiceno: "",
    // consignmentPriority: "",
    freight_charge: 0,
    lrCharge: 0,
    rovCharge: 0,
    fuelSurcharge: 0,
    invoice: [
      {
        invoiceno: "",
        invoiceValue: "",
        eWayBillNo: "",
        hsncode: "",
        invoiceDate: "",
        codamount: "",
        quantity: "",
        weightmeasure: "kgs",
        billableWeight: "",
        eWayBillExpiryDate: "",
      },
    ],
    boxes: [
      {
        description: "",
        reference: "",
        packages: 0,
        packageuom: "Bags",
        weight: 0,
        weight_uom: "kgs",
        dimensions: {
          width: 0,
          height: 0,
          length: 0,
          uom: "cms",
        },
      },
    ],
  });

  const handleConsignmentDetailsChange = (name) => (event) => {
    setConsignmentDetails({
      ...consignmentDetails,
      [name]: event.target.value,
    });
  };

  const handleInvoiceChange = (index, name) => (event) => {
    const newInvoices = [...consignmentDetails.invoice];
    newInvoices[index][name] = event.target.value;
    setConsignmentDetails({
      ...consignmentDetails,
      invoice: newInvoices,
    });
  };

  const handleBoxChange = (index, name) => (event) => {
    const newBox = [...consignmentDetails.boxes];
    newBox[index][name] = event.target.value;
    setConsignmentDetails({
      ...consignmentDetails,
      boxes: newBox,
    });
  };
  const handleBoxChangedimensions = (index, name) => (event) => {
    const newBox = [...consignmentDetails.boxes];
    newBox[index].dimensions[name] = event.target.value;
    setConsignmentDetails({
      ...consignmentDetails,
      boxes: newBox,
    });
  };

  // Remove Row Invoices
  const handleRemoveInvoiceRow = (index) => {
    const newInvoiceRows = [...consignmentDetails.invoice];
    newInvoiceRows.splice(index, 1);
    setConsignmentDetails({ ...consignmentDetails, invoice: newInvoiceRows });
  };
  // Add Row Invoices
  const handleAddInvoiceRow = () => {
    setConsignmentDetails({
      ...consignmentDetails,
      invoice: [
        ...consignmentDetails.invoice,
        {
          invoiceno: "",
          invoiceValue: "",
          eWayBillNo: "",
          hsncode: "",
          invoiceDate: "",
          codamount: "",
          quantity: "",
          weightmeasure: "kgs",
          billableWeight: "",
          eWayBillExpiryDate: "",
        },
      ],
    });
  };

  // Remove Row Boxes
  const handleRemoveBoxRow = (index) => {
    const newBoxRows = [...consignmentDetails.boxes];
    newBoxRows.splice(index, 1);
    setConsignmentDetails({ ...consignmentDetails, boxes: newBoxRows });
  };
  // Add Row Boxes
  const handleAddBoxRow = () => {
    setConsignmentDetails({
      ...consignmentDetails,
      boxes: [
        ...consignmentDetails.boxes,
        {
          description: "",
          reference: "",
          packages: 0,
          packageuom: "Bags",
          weight: 0,
          weight_uom: "kgs",
          dimensions: {
            width: 0,
            height: 0,
            length: 0,
            uom: "",
          },
        },
      ],
    });
  };

  // const handleBusinessConsignorName = () => {
  //   // setConsignorDetails({
  //   //   ...consignorDetails,
  //   //   name: userData.name,
  //   // });
  // };
  // const handleBusinessConsigneeName = () => {
  //   // setConsigneeDetails({
  //   //   ...consigneeDetails,
  //   //   name: userData.name,
  //   // });
  // };

  const handleInsuranceRequired = () => {
    setInsuranceRequired(!insuranceRequired);
  };
  // const getDeliveryDate = () => {
  //   const currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() + 10);
  //   return currentDate.toISOString().split("T")[0];
  // };

  const handleIncludeGST = () => {
    setConsignmentDetails((prevDetails) => ({
      ...prevDetails,
      includeGST: !prevDetails.includeGST,
    }));
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  useEffect(() => {
    // Find the rate for the given actualWeight
    const rate = RateCard.find(
      (rate) =>
        consignmentDetails.actualWeight >= rate.minWeight &&
        consignmentDetails.actualWeight < rate.maxWeight
    );

    let updatedFreightCharge = consignmentDetails?.freight_charge;

    if (rate) {
      // If a valid rate is found, calculate the freight charge
      updatedFreightCharge = rate.Price * consignmentDetails.actualWeight;
    }

    const total =
      Number(updatedFreightCharge) +
      Number(consignmentDetails.rovCharge) +
      Number(consignmentDetails.lrCharge) +
      Number(consignmentDetails.fuelSurcharge) +
      Number(consignmentDetails.additonalCharges);

    const gstValue = consignmentDetails.includeGST
      ? total * (Number(consignmentDetails.gstPercent) / 100)
      : 0;

    setConsignmentDetails((prevDetails) => ({
      ...prevDetails,
      freight_charge: updatedFreightCharge,
      taxableAmount: total,
      gstValue: gstValue,
      totalcharges: total + gstValue,
    }));
  }, [
    consignmentDetails.rovCharge,
    consignmentDetails.lrCharge,
    consignmentDetails.fuelSurcharge,
    consignmentDetails.additonalCharges,
    consignmentDetails.gstPercent,
    consignmentDetails.includeGST,
    consignmentDetails.actualWeight,
    consignmentDetails.freight_charge,
  ]);

  useEffect(() => {
    if (id) {
      setEditMode(true);
      const currentConsignment = userData?.consignments?.find(
        (item) => item?._id === id
      );
      setEditData(userData?.currentConsignment);
      setInsuranceRequired(
        currentConsignment?.insurance?.insuranceno ? true : false
      );
      setInsurance({
        ...insurance,
        insuranceno: currentConsignment?.insurance?.insuranceno,
        insuranceCompany: currentConsignment?.insurance?.insuranceCompany,
        insurancestartDate:
          currentConsignment?.insurance?.insurancestartDate?.split("T")[0],
        insuranceexpiryDate:
          currentConsignment?.insurance?.insuranceexpiryDate?.split("T")[0],
      });
      setConsignorDetails(currentConsignment?.consignor);
      setConsigneeDetails(currentConsignment?.consignee);
      setConsignmentDetails({
        ...consignmentDetails,
        includeGST: currentConsignment?.gstValue ? true : false,
        consignmentStatus: currentConsignment?.consignmentStatus,
        gstValue: currentConsignment?.gstValue,
        gstPercent: currentConsignment?.gstPercent,
        totalcharges: currentConsignment?.totalcharges,
        taxableAmount: currentConsignment?.taxableAmount,
        actualWeight: currentConsignment?.actualWeight,
        additonalCharges: currentConsignment?.additonalCharges,
        deliveryto: currentConsignment?.deliveryto,
        trackingid: currentConsignment?.trackingid,
        deliverybranch: currentConsignment?.deliverybranch,
        consignmentType: currentConsignment?.consignmentType,
        requestedby: currentConsignment?.requestedby,
        serviceType: currentConsignment?.serviceType,
        paymentType: currentConsignment?.paymentType,
        freight_charge: currentConsignment?.freight_charge,
        lrCharge: currentConsignment?.lrCharge,
        rovCharge: currentConsignment?.rovCharge,
        fuelSurcharge: currentConsignment?.fuelSurcharge,
        deliveryDate: currentConsignment?.deliveryDate,
        actualWeightUom: currentConsignment?.actualWeightUom,
        deliveryPriority: currentConsignment?.deliveryPriority,
        SafetyPriority: currentConsignment?.SafetyPriority,
        lrWeight: currentConsignment?.lrWeight,
        docketdate: currentConsignment?.docketdate?.split("T")[0],
        promocode: currentConsignment?.promocode,
        Notes: currentConsignment?.Notes,
        invoice: currentConsignment?.invoices,
        boxes: currentConsignment?.boxes,
        lrnumber: currentConsignment?.lrnumber,
      });
    }
  }, [id, userData]);

  // Handle Submit
  const handleSubmit = async () => {
    if (
      // !consignmentDetails?.deliverybranch ||
      !consignmentDetails?.lrnumber ||
      !consignorDetails.name ||
      !consignorDetails.contact ||
      !consignorDetails.pincode ||
      !consigneeDetails.name ||
      !consigneeDetails.contact ||
      !consigneeDetails.pincode ||
      !consignmentDetails?.actualWeight ||
      consignmentDetails?.totalcharges < 0
    ) {
      // setError("Please select required fields");
      toast.error("Complete all required (*) fields");
      return;
    }
    const isftl = consignmentType === "FTL" ? true : false;
    const trackingid = randomstring.generate({
      length: 10,
      charset: "alphabetic",
      capitalization: "uppercase",
    });
    const setDeliveryBranch = isftl
      ? localStorage.getItem("currentbranch")
      : consignmentDetails.deliverybranch;
    const newConsignment = {
      isftl: isftl,
      includeGST: consignmentDetails.includeGST,
      gstValue: consignmentDetails.gstValue,
      gstPercent: consignmentDetails.gstPercent,
      taxableAmount: consignmentDetails.taxableAmount,
      additonalCharges: consignmentDetails.additonalCharges,
      trackingid: trackingid,
      pickupbranch: localStorage.getItem("currentbranch"),
      deliverybranch: setDeliveryBranch,
      consignmentStatus: "Processing",
      consignmentType: consignmentDetails.consignmentType,
      requestedby: consignmentDetails.requestedby,
      serviceType: consignmentDetails.serviceType,
      actualWeight: consignmentDetails.actualWeight,
      paymentType: consignmentDetails.paymentType,
      freight_charge: consignmentDetails.freight_charge,
      lrCharge: consignmentDetails.lrCharge,
      rovCharge: consignmentDetails.rovCharge,
      fuelSurcharge: consignmentDetails.fuelSurcharge,
      totalcharges: consignmentDetails.totalcharges,
      deliveryDate: consignmentDetails.deliveryDate,
      actualWeightUom: consignmentDetails.actualWeightUom,
      deliveryPriority: consignmentDetails.deliveryPriority,
      SafetyPriority: consignmentDetails.SafetyPriority,
      lrWeight: consignmentDetails.lrWeight,
      docketdate: consignmentDetails.docketdate,
      promocode: consignmentDetails.promocode,
      Notes: consignmentDetails.Notes,
      deliveryto: consignmentDetails.deliveryto,
      // waitingforPermit: consignmentDetails.waitingforPermit,
      // materialHold: consignmentDetails.materialHold,
      invoices: consignmentDetails.invoice,
      boxes: consignmentDetails.boxes,
      insurance: {
        insuranceno: insurance.insuranceno,
        insuranceCompany: insurance.insuranceCompany,
        insurancestartDate: insurance.insurancestartDate,
        insuranceexpiryDate: insurance.insuranceexpiryDate,
      },
      consignor: {
        name: consignorDetails.name,
        contact: consignorDetails.contact,
        city: consignorDetails.city,
        address: consignorDetails.address,
        email: consignorDetails.email,
        gstin: consignorDetails.gstin,
        pincode: consignorDetails.pincode,
        proofType: consignorDetails.proofType,
        proofNo: consignorDetails.proofNo,
      },
      consignee: {
        name: consigneeDetails.name,
        contact: consigneeDetails.contact,
        alternate: consigneeDetails.alternate,
        address: consigneeDetails.address,
        email: consigneeDetails.email,
        gstin: consigneeDetails.gstin,
        pincode: consigneeDetails.pincode,
        proofType: consigneeDetails.proofType,
        proofNo: consigneeDetails.proofNo,
        city: consigneeDetails.city,
      },

      // lrchargefile: files,
      branch: localStorage.getItem("currentbranch"),
      lrnumber: consignmentDetails.lrnumber,
    };

    try {
      const response = await axiosInstance.post(
        "/consignment/add",
        newConsignment
      );

      if (response.status === 200) {
        toast.success("Consignment Added Successfully");
        setRefresh(true);
        navigate("/dashboard/bookings/consignment");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error);
    }
  };

  // Handle Update
  const handleUpdate = async () => {
    const consignmentUpdate = {
      additonalCharges: consignmentDetails.additonalCharges,
      deliveryto: consignmentDetails.deliveryto,
      deliverybranch: consignmentDetails.deliverybranch,
      consignmentStatus: "Processing",
      consignmentType: consignmentDetails.consignmentType,
      requestedby: consignmentDetails.requestedby,
      serviceType: consignmentDetails.serviceType,
      actualWeight: consignmentDetails.actualWeight,
      paymentType: consignmentDetails.paymentType,
      freight_charge: consignmentDetails.freight_charge,
      lrCharge: consignmentDetails.lrCharge,
      rovCharge: consignmentDetails.rovCharge,
      fuelSurcharge: consignmentDetails.fuelSurcharge,
      totalcharges: consignmentDetails.totalcharges,
      deliveryDate: consignmentDetails.deliveryDate,
      actualWeightUom: consignmentDetails.actualWeightUom,
      deliveryPriority: consignmentDetails.deliveryPriority,
      SafetyPriority: consignmentDetails.SafetyPriority,
      lrWeight: consignmentDetails.lrWeight,
      docketdate: consignmentDetails.docketdate,
      promocode: consignmentDetails.promocode,
      Notes: consignmentDetails.Notes,
      includeGST: consignmentDetails.includeGST,
      gstValue: consignmentDetails.gstValue,
      gstPercent: consignmentDetails.gstPercent,
      taxableAmount: consignmentDetails.taxableAmount,
      // deliveryto: consignmentDetails.deliveryto,
      // waitingforPermit: consignmentDetails.waitingforPermit,
      // materialHold: consignmentDetails.materialHold,
      invoices: consignmentDetails.invoice,
      boxes: consignmentDetails.boxes,
      insurance: {
        insuranceno: insurance.insuranceno,
        insuranceCompany: insurance.insuranceCompany,
        insurancestartDate: insurance.insurancestartDate,
        insuranceexpiryDate: insurance.insuranceexpiryDate,
      },
      consignor: {
        name: consignorDetails.name,
        contact: consignorDetails.contact,
        address: consignorDetails.address,
        email: consignorDetails.email,
        gstin: consignorDetails.gstin,
        pincode: consignorDetails.pincode,
        proofType: consignorDetails.proofType,
        proofNo: consignorDetails.proofNo,
      },
      consignee: {
        name: consigneeDetails.name,
        contact: consigneeDetails.contact,
        alternate: consigneeDetails.alternate,
        address: consigneeDetails.address,
        email: consigneeDetails.email,
        gstin: consigneeDetails.gstin,
        pincode: consigneeDetails.pincode,
        proofType: consigneeDetails.proofType,
        proofNo: consigneeDetails.proofNo,
      },

      // lrchargefile: files,
      branch: localStorage.getItem("currentbranch"),
      lrnumber: consignmentDetails.lrnumber,
    };

    try {
      const response = await axiosInstance.post(
        `/consignment/updatepickupconsignment/${id}/${pickupid}`,
        consignmentUpdate
      );

      if (response.status === 200) {
        setRefresh(true);
        toast.success("Consignment Updated Successfully");
        navigate("/dashboard/bookings/consignment");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error);
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          color: theme.text.primary,
          fontSize: "20px",
          margin: "10px",
          textAlign: "center",
        }}
      >
        {editMode ? "Edit Consignment" : "Add New Consignment"}
      </Typography>
      {editMode && (
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
            margin: "0px 20px",
            color: theme.text.secondary,
          }}
        >
          <Typography sx={{ fontWeight: "bolder" }}>
            Delivery Status: {consignmentDetails?.consignmentStatus}
          </Typography>
          <Typography sx={{ fontWeight: "bolder" }}>
            Tracking ID: {consignmentDetails?.trackingid}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          margin: "10px",
          overflow: "auto",
          flexDirection: "column",
        }}
      >
        {/* Basic Details */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* Consignment Type & Delivery Branch */}
          <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
            <SelectField
              label="Consignor Type"
              value={consignorType}
              onChange={(e) => setConsignorType(e.target.value)}
              name="consignortype"
              type="text"
              items={["Business", "Individual"]}
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              variant={"standard"}
            />
            <SelectField
              label="Consignment Type"
              value={consignmentType}
              onChange={(e) => setConsignmentType(e.target.value)}
              name="consignmenttype"
              type="text"
              items={["Normal", "FTL"]}
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              variant={"standard"}
            />
            <TextFields
              label="LR Number *"
              value={consignmentDetails.lrnumber}
              onChange={handleConsignmentDetailsChange("lrnumber")}
              name="lrnumber"
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
            />

            {consignmentType !== "FTL" && (
              <FormControl
                margin="normal"
                sx={{
                  width: "200px",
                  "& .MuiInputLabel-root": { color: theme.text.secondary }, // Label color
                  "& .MuiInput-underline:before": {
                    borderBottomColor: theme.text.secondary,
                  }, // Underline color before focus
                  "& .MuiInput-underline:after": {
                    borderBottomColor: theme.text.secondary,
                  }, // Underline color after focus
                  "& .MuiInputBase-input": { color: theme.text.secondary }, // Input text color
                  "&:after": {
                    borderBottomColor: theme.text.secondary,
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme.text.secondary,
                  },
                }}
              >
                <InputLabel>Delivery Branch *</InputLabel>
                <Select
                  value={consignmentDetails.deliverybranch}
                  onChange={handleConsignmentDetailsChange("deliverybranch")}
                  variant="standard"
                  size="small"
                >
                  {branches?.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.branchName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <SelectField
              label="Delivery To *"
              value={consignmentDetails.deliveryto}
              onChange={handleConsignmentDetailsChange("deliveryto")}
              name="deliveryto"
              type="text"
              items={["Branch", "Consignee"]}
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              variant={"standard"}
            />

            {/* <SelectField
              label="Requested By"
              value={consignmentDetails.requestedby}
              onChange={handleConsignmentDetailsChange("requestedby")}
              name="requestedby"
              type="text"
              items={["Outbound", "Inbound"]}
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              variant={"standard"}
            /> */}
          </Box>
        </Box>
        {/* Invoice Details */}
        <Box>
          <Typography sx={{ color: theme.text.primary, fontWeight: "bolder" }}>
            Invoice Details
          </Typography>
          {/* OuterBorderBox */}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            {/* count of invoices */}
            {consignmentDetails?.invoice?.map((invoice, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  backgroundColor: theme.background.button,
                  padding: "10px 20px ",
                }}
              >
                <TextFields
                  label="Invoice No"
                  name="invoiceno"
                  value={invoice.invoiceno}
                  onChange={handleInvoiceChange(index, "invoiceno")}
                  type="text"
                  sx={{ width: "100px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="Invoice Value"
                  name="invoiceValue"
                  value={invoice.invoiceValue}
                  onChange={handleInvoiceChange(index, "invoiceValue")}
                  type="text"
                  sx={{ width: "80px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="E-way Bill No"
                  name="eWayBillNo"
                  value={invoice.eWayBillNo}
                  onChange={handleInvoiceChange(index, "eWayBillNo")}
                  type="text"
                  sx={{ width: "150px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="HSN Code"
                  name="hsncode"
                  value={invoice.hsncode}
                  onChange={handleInvoiceChange(index, "hsncode")}
                  type="text"
                  sx={{ width: "100px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="Invoice Date"
                  name="invoiceDate"
                  value={invoice.invoiceDate}
                  onChange={handleInvoiceChange(index, "invoiceDate")}
                  type="date"
                  sx={{ width: "120px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="COD Amount"
                  name="codamount"
                  value={invoice.codamount}
                  onChange={handleInvoiceChange(index, "codamount")}
                  type="text"
                  sx={{ width: "100px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="Quantity"
                  name="quantity"
                  value={invoice.quantity}
                  onChange={handleInvoiceChange(index, "quantity")}
                  type="text"
                  sx={{ width: "50px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <SelectField
                  label="Weight UOM"
                  name="weightmeasure"
                  value={invoice.weightmeasure}
                  onChange={handleInvoiceChange(index, "weightmeasure")}
                  sx={{ width: "80px" }}
                  color={theme.text.secondary}
                  items={["kgs", "tonnes"]}
                  shrink={true}
                />
                <TextFields
                  label="Billable Weight"
                  name="billableWeight"
                  value={invoice.billableWeight}
                  onChange={handleInvoiceChange(index, "billableWeight")}
                  type="text"
                  sx={{ width: "100px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <TextFields
                  label="E-way Bill Expiry Date"
                  name="eWayBillExpiryDate"
                  value={invoice.eWayBillExpiryDate}
                  onChange={handleInvoiceChange(index, "eWayBillExpiryDate")}
                  type="date"
                  sx={{ width: "120px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                {consignmentDetails.invoice.length > 1 && (
                  <Button onClick={() => handleRemoveInvoiceRow(index)}>
                    Delete
                  </Button>
                )}
              </Box>
            ))}
            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                color: theme.text.primary,
                width: "max-content",
                alignSelf: "flex-end",
              }}
              onClick={handleAddInvoiceRow}
            >
              Add more invoices
            </Typography>
          </Box>
        </Box>
        {/* Box Details */}
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ color: theme.text.primary, fontWeight: "bolder" }}>
            Box Details
          </Typography>
          {consignmentDetails?.boxes?.map((box, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "space-between",
                backgroundColor: theme.background.button,
                padding: "10px 20px ",
              }}
            >
              <TextFields
                label="Description"
                name="description"
                value={box.description}
                onChange={handleBoxChange(index, "description")}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />

              <TextFields
                label="Reference"
                name="reference"
                value={box.reference}
                onChange={handleBoxChange(index, "reference")}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />

              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextFields
                  label="Packages"
                  name="packages"
                  value={box.packages}
                  onChange={handleBoxChange(index, "packages")}
                  type="number"
                  sx={{ width: "60px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <SelectField
                  label="UOM"
                  name="packageuom"
                  value={box.packageuom}
                  onChange={handleBoxChange(index, "packageuom")}
                  sx={{ width: "80px" }}
                  color={theme.text.secondary}
                  items={["Bags", "Carton"]}
                  shrink={true}
                />
              </Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <TextFields
                  label="weight"
                  name="weight"
                  value={box.weight}
                  onChange={handleBoxChange(index, "weight")}
                  type="number"
                  sx={{ width: "60px" }}
                  color={theme.text.secondary}
                  shrink={true}
                />
                <SelectField
                  label="Weight UOM"
                  name="weightmeasure"
                  value={box.weight_uom}
                  onChange={handleBoxChange(index, "weight_uom")}
                  sx={{ width: "80px" }}
                  color={theme.text.secondary}
                  items={["kgs", "tonnes"]}
                  shrink={true}
                />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "flex-end" }}
                >
                  <TextFields
                    label="Width"
                    name="width"
                    value={box.dimensions.width}
                    onChange={handleBoxChangedimensions(index, "width")}
                    type="number"
                    sx={{ width: "50px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                  <TextFields
                    label="height"
                    name="width"
                    value={box.dimensions.height}
                    onChange={handleBoxChangedimensions(index, "height")}
                    type="number"
                    sx={{ width: "50px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                  <TextFields
                    label="Length"
                    name="length"
                    value={box.dimensions.length}
                    onChange={handleBoxChangedimensions(index, "length")}
                    type="number"
                    sx={{ width: "50px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                  <SelectField
                    label="UOM"
                    name="dimensionsuom"
                    value={box.dimensions.uom}
                    onChange={handleBoxChangedimensions(index, "uom")}
                    sx={{ width: "60px" }}
                    color={theme.text.secondary}
                    items={["cms", "inches", "feet"]}
                    shrink={true}
                  />
                </Box>
                <Typography
                  sx={{ textAlign: "center", color: theme.text.secondary }}
                >
                  Dimensions
                </Typography>
              </Box>

              {consignmentDetails.boxes.length > 1 && (
                <Button onClick={() => handleRemoveBoxRow(index)}>
                  Delete
                </Button>
              )}
            </Box>
          ))}
          <Typography
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: theme.text.primary,
              width: "max-content",
              alignSelf: "flex-end",
            }}
            onClick={handleAddBoxRow}
          >
            Add more boxes
          </Typography>
        </Box>
        {/* consignor Details */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            // backgroundColor: theme.background.button,
            // padding: "10px 20px ",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Typography sx={{ fontWeight: "bold", color: theme.text.primary }}>
              Consignor Details
            </Typography>
          </Box>

          {/* Consignor Details */}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "0px 20px ",
              backgroundColor: theme.background.button,
            }}
          >
            <TextFields
              label="consignor Name *"
              name="name"
              value={consignorDetails?.name}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  name: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            {/* {consignorType === "Individual" ? (
              <TextFields
                label="consignor Name *"
                name="name"
                value={consignorDetails?.name}
                // onChange={(e) =>
                //   setConsignorDetails({
                //     ...consignorDetails,
                //     name: e.target.value,
                //   })
                // }
                onChange={(e) =>
                  setConsignorDetails({
                    ...consignorDetails,
                    name: e.target.value,
                  })
                }
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
            ) : (
              <Stack spacing={2} sx={{ width: 300 }}>
                <Autocomplete
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                    color: theme.text.secondary,
                    borderRadius: "30px",
                  }}
                  defaultValue={null}
                  disableClearable
                  // onChange={(e, value) => {
                  //   setSearchCafNo(value);
                  //   handleSearchCafNo(e, value);
                  // }}
                  onChange={handleBusinessConsignorName}
                  value={businessConsignorName}
                  freeSolo
                  options={
                    (userData &&
                      userData.customers &&
                      userData.customers
                        .filter((item) => item.customer_status === "Active")
                        .map(
                          (option) =>
                            option.caf_no.toUpperCase() +
                            " " +
                            option.mobile +
                            " " +
                            option.customer_name
                        )) ||
                    []
                  }
                  filterOptions={(options, { inputValue }) =>
                    options
                      .filter((option) =>
                        option.toLowerCase().includes(inputValue.toLowerCase())
                      )
                      .slice(0, 7)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      sx={{ color: theme.text.secondary }}
                      label="Search"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Stack>
            )} */}

            <TextFields
              label="Contact *"
              name="contact"
              value={consignorDetails?.contact}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  contact: e.target.value,
                })
              }
              type="number"
              sx={{ width: "120px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Email"
              name="email"
              value={consignorDetails?.email}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  email: e.target.value,
                })
              }
              type="email"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Address"
              name="address"
              value={consignorDetails?.address}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  address: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="City *"
              name="city"
              value={consignorDetails?.city}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  city: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />

            <TextFields
              label="Pincode *"
              name="pincode"
              value={consignorDetails?.pincode}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  pincode: e.target.value,
                })
              }
              type="number"
              sx={{ width: "100px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="GSTIN"
              name="gstin"
              value={consignorDetails?.gstin}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  gstin: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Proof Type"
              name="proofType"
              value={consignorDetails?.proofType}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  proofType: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Proof No"
              name="proofNo"
              value={consignorDetails?.proofNo}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  proofNo: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            {/* <TextFields
              label="Attachments"
              name="proofNo"
              value={consignorDetails.documents}
              onChange={(e) =>
                setConsignorDetails({
                  ...consignorDetails,
                  documents: e.target.value,
                })
              }
              type="file"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            /> */}
          </Box>
        </Box>
        {/* consignee Details */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            // backgroundColor: theme.background.button,
            // padding: "10px 20px ",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Typography sx={{ fontWeight: "bold", color: theme.text.primary }}>
              Consignee Details
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexWrap: "wrap",
              padding: "0px 20px ",
              backgroundColor: theme.background.button,
            }}
          >
            <TextFields
              label="consignee Name *"
              name="name"
              value={consigneeDetails?.name}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  name: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            {/* {consignorType === "Individual" ? (
              <TextFields
                label="consignee Name *"
                name="name"
                value={consigneeDetails?.name}
                onChange={(e) =>
                  setConsigneeDetails({
                    ...consigneeDetails,
                    name: e.target.value,
                  })
                }
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
            ) : (
              <Stack spacing={2} sx={{ width: 300 }}>
                <Autocomplete
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: 300,

                    borderRadius: "30px",
                  }}
                  defaultValue={null}
                  disableClearable
                  variant="standard"
                  // onChange={(e, value) => {
                  //   setSearchCafNo(value);
                  //   handleSearchCafNo(e, value);
                  // }}
                  onChange={handleBusinessConsigneeName}
                  value={businessConsigneeName}
                  freeSolo
                  options={
                    (userData &&
                      userData.customers &&
                      userData.customers
                        .filter((item) => item.customer_status === "Active")
                        .map(
                          (option) =>
                            option.caf_no.toUpperCase() +
                            " " +
                            option.mobile +
                            " " +
                            option.customer_name
                        )) ||
                    []
                  }
                  filterOptions={(options, { inputValue }) =>
                    options
                      .filter((option) =>
                        option.toLowerCase().includes(inputValue.toLowerCase())
                      )
                      .slice(0, 7)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Business Name"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                    />
                  )}
                />
              </Stack>
            )} */}
            <TextFields
              label="Contact *"
              name="contact"
              value={consigneeDetails?.contact}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  contact: e.target.value,
                })
              }
              type="number"
              sx={{ width: "120px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Alternate Contact"
              name="alternate"
              value={consigneeDetails?.alternate}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  alternate: e.target.value,
                })
              }
              type="number"
              sx={{ width: "120px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Email"
              name="email"
              value={consigneeDetails?.email}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  email: e.target.value,
                })
              }
              type="email"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Address"
              name="address"
              value={consigneeDetails?.address}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  address: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="City *"
              name="city"
              value={consigneeDetails?.city}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  city: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="GSTIN"
              name="gstin"
              value={consigneeDetails?.gstin}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  gstin: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Pincode *"
              name="pincode"
              value={consigneeDetails?.pincode}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  pincode: e.target.value,
                })
              }
              type="number"
              sx={{ width: "100px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Proof Type"
              name="proofType"
              value={consigneeDetails?.proofType}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  proofType: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Proof No"
              name="proofNo"
              value={consigneeDetails?.proofNo}
              onChange={(e) =>
                setConsigneeDetails({
                  ...consigneeDetails,
                  proofNo: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
          </Box>
        </Box>
        {/* Misc Options */}
        <Box
          sx={{
            padding: "10px 20px ",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 3,
            flexWrap: "wrap",
          }}
        >
          <TextFields
            label="Docket Date *"
            name="docketdate"
            value={consignmentDetails?.docketdate}
            onChange={handleConsignmentDetailsChange("docketdate")}
            type="date"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
            shrink={true}
          />
          <SelectField
            label="Service Type"
            name="serviceType"
            value={consignmentDetails?.serviceType}
            onChange={handleConsignmentDetailsChange("serviceType")}
            items={["Surface-Normal", "Air", "Sea", "Road"]}
            sx={{ width: "200px" }}
            color={theme.text.secondary}
            shrink={true}
          />
          <SelectField
            label="Delivery Priority"
            name="deliveryPriority"
            value={consignmentDetails?.deliveryPriority}
            onChange={handleConsignmentDetailsChange("deliveryPriority")}
            items={["Air", "Sea", "Road"]}
            sx={{ width: "200px" }}
            color={theme.text.secondary}
            shrink={true}
          />
          <SelectField
            label="Safety Priority"
            name="safetypriority"
            value={consignmentDetails?.SafetyPriority}
            onChange={handleConsignmentDetailsChange("SafetyPriority")}
            items={["Economy", "Normal", "Express"]}
            sx={{ width: "200px" }}
            color={theme.text.secondary}
            shrink={true}
          />
          <SelectField
            label="Payment Type"
            name="paymenttype"
            value={consignmentDetails?.paymentType}
            onChange={handleConsignmentDetailsChange("paymentType")}
            items={["Prepaid", "To Pay", "To Bill"]}
            sx={{ width: "200px" }}
            color={theme.text.secondary}
            shrink={true}
          />
          <FormControl>
            <FormControlLabel
              sx={{ color: theme.text.secondary }}
              control={
                <Checkbox
                  sx={{ color: theme.text.secondary }}
                  name="Insurance Required"
                  onChange={handleInsuranceRequired}
                />
              }
              label="Insurance Required"
            />
          </FormControl>
        </Box>
        {/* Insurance Details */}
        {insuranceRequired && (
          <Box
            sx={{
              padding: "10px 20px",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <TextFields
              label="Insurance No"
              name="insuranceNo"
              value={insurance?.insuranceno}
              onChange={(e) =>
                setInsurance({
                  ...insurance,
                  insuranceno: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Insurance Company"
              name="insuranceCompnay"
              value={insurance?.insuranceCompany}
              onChange={(e) =>
                setInsurance({
                  ...insurance,
                  insuranceCompany: e.target.value,
                })
              }
              type="text"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Insurance Date"
              name="insurancestartDate"
              value={insurance?.insurancestartDate}
              onChange={(e) =>
                setInsurance({
                  ...insurance,
                  insurancestartDate: e.target.value,
                })
              }
              type="date"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <TextFields
              label="Expiry Date"
              name="insuranceexpiryDate"
              value={insurance?.insuranceexpiryDate}
              onChange={(e) =>
                setInsurance({
                  ...insurance,
                  insuranceexpiryDate: e.target.value,
                })
              }
              type="date"
              sx={{ width: "200px" }}
              color={theme.text.secondary}
              shrink={true}
            />
          </Box>
        )}
        {/* Other Details */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: "10px 20px",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* Consignment Left */}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {/* Actual Weight */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <TextFields
                label="Actual Weight *"
                name="actualWeight"
                value={consignmentDetails.actualWeight}
                onChange={handleConsignmentDetailsChange("actualWeight")}
                type="text"
                sx={{ width: "150px" }}
                color={theme.text.secondary}
              />
              <SelectField
                label="Unit"
                name="actualWeightUom"
                value={consignmentDetails.actualWeightUom}
                onChange={handleConsignmentDetailsChange("actualWeightUom")}
                items={["kgs", "gms"]}
                sx={{ width: "80px" }}
                color={theme.text.secondary}
              />
            </Box>
            {/* LR Weight */}
            <Box
              sx={{
                color: theme.text.secondary,
                display: "flex",
                gap: "10px",
              }}
            >
              <Typography sx={{ fontWeight: "bolder" }}>LR Weight:</Typography>
              <Typography>
                {consignmentDetails.actualWeight +
                  " " +
                  consignmentDetails.actualWeightUom || "0 kgs"}
              </Typography>
            </Box>
            {/* Material Hold & Waiting for Permits */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControl>
                <FormControlLabel
                  sx={{ color: theme.text.secondary }}
                  control={
                    <Checkbox
                      sx={{ color: theme.text.secondary }}
                      name="materialHold"
                      onChange={handleConsignmentDetailsChange("materialHold")}
                    />
                  }
                  label="Material Hold"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  sx={{ color: theme.text.secondary }}
                  control={
                    <Checkbox
                      sx={{ color: theme.text.secondary }}
                      name="waitingforPermit"
                      onChange={handleConsignmentDetailsChange(
                        "waitingforPermit"
                      )}
                    />
                  }
                  label="Waiting for Permits"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  sx={{ color: theme.text.secondary }}
                  control={
                    <Checkbox
                      sx={{ color: theme.text.secondary }}
                      name="includeGST"
                      onChange={handleIncludeGST}
                    />
                  }
                  label="Include GST"
                />
              </FormControl>
            </Box>
            {/* Promo Code & Delivery Date */}
            <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <TextFields
                label="Promo Code"
                name="promocode"
                value={consignmentDetails.promocode}
                onChange={handleConsignmentDetailsChange("promocode")}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Delivery Date"
                name="deliveryDate"
                value={consignmentDetails.deliveryDate}
                onChange={handleConsignmentDetailsChange("deliveryDate")}
                type="date"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
            </Box>
            <TextFields
              label="Notes"
              name="Notes"
              value={consignmentDetails.Notes}
              onChange={handleConsignmentDetailsChange("Notes")}
              type="text"
              sx={{ width: "300px" }}
              color={theme.text.secondary}
              shrink={true}
            />
            <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <label
                style={{ color: theme.text.secondary, fontWeight: "bolder" }}
              >
                POD
              </label>
              {editData?.podfile ? (
                <Box>
                  <a
                    href={`www.api.omvairava.com${editData.podfile}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View POD
                  </a>
                  <Button onClick={handleFileChange}>Upload POD</Button>
                </Box>
              ) : (
                <Typography>No POD Uploaded</Typography>
              )}

              {/* <Box sx={{ display: "flex", gap: "20px" }}>
                <label
                  style={{ color: theme.text.secondary, fontWeight: "bolder" }}
                >
                  POD
                </label>

                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept=".pdf, .jpeg, .jpg, .png, .webp"
                  style={{ color: theme.text.secondary }}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
              </Box> */}
              {/* <ul>
                {files.map((file, index) => (
                  <li key={index} style={{ color: theme.text.secondary }}>
                    {file.name}
                    <Button
                      sx={{ color: theme.text.primary }}
                      onClick={() => handleFileRemove(index)}
                    >
                      Remove
                    </Button>
                  </li>
                ))}
              </ul> */}
            </Box>
          </Box>
          {/* Consginment Right and Charges */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  width: "100%",
                }}
              >
                {/* Freight Charges */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, whiteSpace: "nowrap" }}
                  >
                    Freight Charges
                  </Typography>
                  <TextFields
                    // label="Freight Charges"
                    name="freight_charge"
                    value={consignmentDetails.freight_charge}
                    onChange={handleConsignmentDetailsChange("freight_charge")}
                    type="number"
                    sx={{ width: "60px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                </Box>
                {/* LR Charges */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, whiteSpace: "nowrap" }}
                  >
                    LR Charges
                  </Typography>
                  <TextFields
                    // label="Insurance Charges"
                    name="lrCharge"
                    value={consignmentDetails.lrCharge}
                    onChange={handleConsignmentDetailsChange("lrCharge")}
                    type="number"
                    sx={{ width: "60px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                </Box>
                {/* Rov Charges */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",

                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, whiteSpace: "nowrap" }}
                  >
                    Rov Charge:
                  </Typography>
                  <TextFields
                    // label="Rov Charge"
                    name="rovCharge"
                    value={consignmentDetails.rovCharge}
                    onChange={handleConsignmentDetailsChange("rovCharge")}
                    type="number"
                    sx={{ width: "60px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                </Box>
                {/* Fuel Surcharge */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, whiteSpace: "nowrap" }}
                  >
                    Fuel Surcharge:
                  </Typography>
                  <TextFields
                    // label="Fuel Surcharge"
                    name="fuelSurcharge"
                    value={consignmentDetails.fuelSurcharge}
                    onChange={handleConsignmentDetailsChange("fuelSurcharge")}
                    type="number"
                    sx={{ width: "60px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                </Box>
                {/* Additional Charges */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, whiteSpace: "nowrap" }}
                  >
                    Additional Charges:
                  </Typography>
                  <TextFields
                    name="additonalCharges"
                    value={consignmentDetails.additonalCharges}
                    onChange={handleConsignmentDetailsChange(
                      "additonalCharges"
                    )}
                    type="number"
                    sx={{ width: "60px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "flex-end",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  {consignmentDetails.includeGST && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",

                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme.text.secondary,
                          whiteSpace: "nowrap",
                        }}
                      >
                        Tax
                      </Typography>
                      <TextFields
                        name="gstPercent"
                        value={consignmentDetails.gstPercent}
                        onChange={handleConsignmentDetailsChange("gstPercent")}
                        type="number"
                        sx={{ width: "50px" }}
                        color={theme.text.secondary}
                        shrink={true}
                      />
                    </Box>
                  )}
                  {consignmentDetails.includeGST && (
                    <Typography sx={{ color: theme.text.secondary }}>
                      {consignmentDetails.gstValue}.00
                    </Typography>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "flex-end",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, whiteSpace: "nowrap" }}
                  >
                    Total Charges: *
                  </Typography>
                  <TextFields
                    // label="Total Charges"
                    name="totalcharges"
                    value={consignmentDetails.totalcharges}
                    onChange={handleConsignmentDetailsChange("totalcharges")}
                    type="number"
                    sx={{ width: "100px" }}
                    color={theme.text.secondary}
                    shrink={true}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {editMode ? (
          <Button
            onClick={handleUpdate}
            sx={{
              backgroundColor: theme.button.active,
              color: "#fff",
              padding: "5px 30px",
              fontSize: "16px",
              margin: 4,

              "&:hover": {
                backgroundColor: theme.button.active,
              },
            }}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            sx={{
              backgroundColor: theme.button.active,
              color: "#fff",
              padding: "5px 30px",
              fontSize: "16px",
              margin: 4,

              "&:hover": {
                backgroundColor: theme.button.active,
              },
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddConsignments;

import axios from "axios";

export const baseURL = "https://www.api.omvairava.com";
// export const baseURL = "http://localhost:4001";

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Use interceptor to dynamically update auth token and other headers
axiosInstance.interceptors.request.use((config) => {
  const authTokens = localStorage.getItem("authTokens");
  const branchId = localStorage.getItem("branchId");
  const branch_id = localStorage.getItem("currentbranch");
  const currentbranch = localStorage.getItem("currentbranch");
  const companyid = localStorage.getItem("companyId");

  if (authTokens) {
    config.headers["x-auth-token"] = authTokens;
  }
  if (branchId) {
    config.headers["branchId"] = branchId;
  }
  if (branch_id) {
    config.headers["branch_id"] = branch_id;
  }
  if (currentbranch) {
    config.headers["currentbranch"] = currentbranch;
  }
  if (companyid) {
    config.headers["companyid"] = companyid;
  }

  return config;
});

export default axiosInstance;

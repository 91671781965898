import React, { useContext, useState, useEffect } from "react";
import TextFields from "../../components/misc/textfield";
import SelectField from "../../components/misc/selectfield";
import ThemeContext from "../../context/themeContext";
import { Box, Button, Modal, Typography } from "@mui/material";
import axiosInstance from "../../api/axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

const AddUsers = ({
  OpenAddUsers,
  handleCloseAddusers,
  editData,
  editMode,
}) => {
  const { theme } = useContext(ThemeContext);
  const [user, setUser] = useState({
    username: "",
    password: "",
    email: "",
    status: "Active",
    contact: "",
    approvals: false,
    emprole: "",
    accessrole: "",
    empId: "",
    staffType: "",
  });

  useEffect(() => {
    if (editMode && OpenAddUsers) {
      setUser({
        username: editData?.username || "",
        password: editData?.password || "",
        email: editData?.email || "",
        status: editData?.status || "Active",
        contact: editData?.contact || "",
        approvals: editData?.approvals || false,
        emprole: editData?.emprole || "",
        accessrole: editData?.accessrole || "",
        empId: editData?.empId || "",
        staffType: editData?.staffType || "",
      });
    } else if (OpenAddUsers && !editMode) {
      setUser({
        username: "",
        password: "",
        email: "",
        status: "Active",
        contact: "",
        approvals: false,
        emprole: "",
        accessrole: "",
        empId: "",
        staffType: "",
      });
    }
  }, [editData, OpenAddUsers, editMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const newUser = user;

    try {
      const res = await axiosInstance.post("branches/adduser", newUser);

      if (res.status === 200) {
        toast.success("User Added Successfully");
        handleCloseAddusers();
      }
    } catch (error) {
      toast.error("Error Adding User");
      console.error(error.response.data.message);
    }
  };

  const handleUpdate = async () => {
    const newUser = user;
    try {
      const res = await axiosInstance.put(
        `/branches/edituser/${editData._id}`,
        newUser
      );
      if (res.status === 200) {
        toast.success("User Updated Successfully");
        handleCloseAddusers();
      }
    } catch (error) {
      toast.error("Error Updating User");
      console.error("Error updating user:", error);
    }
  };

  return (
    <Modal
      open={OpenAddUsers}
      onClose={handleCloseAddusers}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "300px", sm: "750px" },
          bgcolor: theme.background.default,
          color: "#000",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: "20px",
            margin: "10px",
            textAlign: "center",
          }}
        >
          {editMode ? "Edit User" : "Add New User"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            margin: "10px",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontWeight: "bold", color: theme.text.primary }}>
            User Details
          </Typography>

          <Box
            sx={{
              padding: "20px",
              borderRadius: "10px",
              border: `2px solid ${theme.text.primary}`,
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <TextFields
                label="User Name"
                value={user.username}
                onChange={handleChange}
                name="username"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Password"
                value={user.password}
                onChange={handleChange}
                name="password"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Email"
                value={user.email}
                onChange={handleChange}
                name="email"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Phone"
                value={user.contact}
                onChange={handleChange}
                name="contact"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Employee Id"
                value={user.empId}
                onChange={handleChange}
                name="empId"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Employee Role"
                value={user.emprole}
                onChange={handleChange}
                name="emprole"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <SelectField
                label="Access Role"
                value={user.accessrole}
                onChange={handleChange}
                name="accessrole"
                sx={{ width: "200px" }}
                items={["Staff", "Branch Head"]}
                color={theme.text.secondary}
              />
              <Box>
                <SelectField
                  label="Staff Type"
                  value={user.staffType}
                  onChange={(e) =>
                    handleChange({
                      target: { name: "staffType", value: e.target.value },
                    })
                  }
                  name="staffType"
                  color={theme.text.secondary}
                  items={["Permanent", "Temporary", "Contract"]}
                  sx={{ width: "200px" }}
                />
              </Box>
              <Box>
                <SelectField
                  label="Status"
                  value={user.status}
                  onChange={(e) =>
                    handleChange({
                      target: { name: "status", value: e.target.value },
                    })
                  }
                  name="status"
                  color={theme.text.secondary}
                  items={["Active", "Inactive"]}
                  sx={{ width: "200px" }}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {editMode ? (
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
                sx={{
                  width: "max-content",
                  backgroundColor: theme.button.active,
                  color: theme.text.secondary,
                  "&:hover": {
                    backgroundColor: theme.button.active,
                  },
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                sx={{
                  width: "max-content",
                  backgroundColor: theme.button.active,
                  color: theme.text.secondary,
                  "&:hover": {
                    backgroundColor: theme.button.active,
                  },
                }}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUsers;

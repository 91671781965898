import React, { useContext, useEffect, useState } from "react";
import TextFields from "../../components/misc/textfield";
import SelectField from "../../components/misc/selectfield";
import { Box, Typography, Button, InputLabel } from "@mui/material";
import ThemeContext from "../../context/themeContext";
import { toast } from "react-hot-toast";
import axiosInstance from "../../api/axios";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../context/authContext";
import ValuedSelectField from "../../components/misc/valuedSelectField";
const AddBranches = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userData, setRefresh } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [basicDetails, setBasicDetails] = useState({
    branchName: "",
    branchCode: "",
    address: "",
    contact: "",
    email: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    gstin: "",
    status: "Active",
    password: "",
    branchid: "",
    branchtype: "",
    branchlogo: "",
    branchLocation: "",
    branchlat: "",
    branchlong: "",
  });
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [users, setUsers] = useState([]);
  const [planner, setPlanner] = useState([]);
  const [editMode, setEditMode] = useState(false);
  // const [editData, setEditData] = useState({});

  const handleBasicDetailsChange = (key) => (e) => {
    setBasicDetails({ ...basicDetails, [key]: e.target.value });
  };

  const handleSubmit = async () => {
    const branchDetails = {
      ...basicDetails,
      drivers,
      vehicles,
      pincodes,
      users,
      planner,
    };
    try {
      const res = await axiosInstance.post("branches/addbranch", branchDetails);
      if (res.status === 200) {
        toast.success("Branch Added Successfully");
        setRefresh(true);
        navigate("/dashboard/branches");
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error adding Branch:", error);
    }
  };
  useEffect(() => {
    if (id) {
      setEditMode(true);
      const baseData = userData?.branch?.filter((item) => item._id === id)[0];
      setBasicDetails({
        branchName: baseData.branchName ?? "",
        branchCode: baseData.branchCode || "",
        address: baseData.address || "",
        contact: baseData.contact || "",
        email: baseData.email || "",
        city: baseData.city || "",
        state: baseData.state || "",
        country: baseData.country || "",
        pincode: baseData.pincode || "",
        gstin: baseData.gstin || "",
        status: baseData.status || "",
        password: baseData.password || "",
        branchid: baseData.branchid || "",
        branchtype: baseData.branchtype || "",
        branchLocation: baseData.branchLocation || "",
        branchlat: baseData.branchlat || "",
        branchlong: baseData.branchlong || "",
      });
      setDrivers(baseData.drivers || []);
      setVehicles(baseData.vehicles || []);
      setPincodes(baseData.pincodes || []);
      setUsers(baseData.users || []);
      setPlanner(baseData.planner || []);
    }
  }, [id]);

  return (
    <Box sx={{ margin: "10px", display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          fontSize: "20px",
          color: theme.text.primary,
          textAlign: "center",
        }}
      >
        Add Branches
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        <Typography sx={{ color: theme.text.primary }}>
          Basic Details
        </Typography>
        <Box
          sx={{
            border: `2px solid ${theme.text.primary}`,
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <TextFields
            label="Branch Name"
            value={basicDetails.branchName}
            onChange={handleBasicDetailsChange("branchName")}
            name="Branch Name"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Branch Code"
            value={basicDetails.branchCode}
            onChange={handleBasicDetailsChange("branchCode")}
            name="Branch Code"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Address"
            value={basicDetails.address}
            onChange={handleBasicDetailsChange("address")}
            name="Address"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Contact"
            value={basicDetails.contact}
            onChange={handleBasicDetailsChange("contact")}
            name="Contact"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Email"
            value={basicDetails.email}
            onChange={handleBasicDetailsChange("email")}
            name="Email"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="City"
            value={basicDetails.city}
            onChange={handleBasicDetailsChange("city")}
            name="City"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="State"
            value={basicDetails.state}
            onChange={handleBasicDetailsChange("state")}
            name="State"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Country"
            value={basicDetails.country}
            onChange={handleBasicDetailsChange("country")}
            name="Country"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Pincode"
            value={basicDetails.pincode}
            onChange={handleBasicDetailsChange("pincode")}
            name="Pincode"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="GSTIN"
            value={basicDetails.gstin}
            onChange={handleBasicDetailsChange("gstin")}
            name="GSTIN"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Branch Location"
            value={basicDetails.branchLocation}
            onChange={handleBasicDetailsChange("branchLocation")}
            name="Branch Location"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Latitude"
            value={basicDetails.branchlat}
            onChange={handleBasicDetailsChange("branchlat")}
            name="Latitude"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Longitude"
            value={basicDetails.branchlong}
            onChange={handleBasicDetailsChange("branchlong")}
            name="Longitude"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <InputLabel sx={{ color: theme.text.secondary }}>
              Branch Logo
            </InputLabel>
            <input
              value={basicDetails.branchlogo}
              onChange={handleBasicDetailsChange("branchlogo")}
              type="file"
              style={{ width: "200px" }}
            />
          </Box>
        </Box>
        <Typography sx={{ color: theme.text.primary }}>
          Login Details
        </Typography>
        <Box
          sx={{
            border: `2px solid ${theme.text.primary}`,
            borderRadius: "10px",
            padding: "20px",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            width: "100%",
          }}
        >
          <TextFields
            label="Branch ID"
            value={basicDetails.branchid}
            onChange={handleBasicDetailsChange("branchid")}
            name="Branch ID"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Password"
            value={basicDetails.password}
            onChange={handleBasicDetailsChange("password")}
            name="Password"
            type="password"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <TextFields
            label="Branch Type"
            value={basicDetails.branchtype}
            onChange={handleBasicDetailsChange("branchtype")}
            name="Branch Type"
            type="text"
            sx={{ width: "200px" }}
            color={theme.text.secondary}
          />
          <SelectField
            label="Status"
            value={basicDetails.status}
            onChange={handleBasicDetailsChange("status")}
            name="Status"
            items={["Active", "Inactive"]}
            color={theme.text.secondary}
            sx={{ width: "200px" }}
          />
          <ValuedSelectField
            label="Users"
            value={users}
            onChange={(e) => setUsers(e.target.value)}
            name="Users"
            items={userData?.users?.map((user) => ({
              _id: user._id,
              content: user.username,
            }))}
            color={theme.text.secondary}
            multiple={true}
            sx={{ width: "250px" }}
          />
          <Box
            sx={{
              color: theme.text.secondary,
              flex: 2,
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              {users.map((item) => (
                <Typography
                  sx={{
                    color: theme.text.secondary,
                    display: "flex",
                    flexWrap: "wrap",
                    backgroundColor: theme.background.button,
                    padding: "2px 15px",
                    borderRadius: "10px",
                  }}
                >
                  {
                    userData?.users?.filter((driv) => driv._id === item)[0]
                      .username
                  }
                </Typography>
              ))}{" "}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "space-evenly",
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {/* Drivers and Vehicles */}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              justifyContent: "space-around",
              flexWrap: "wrap",
            }}
          >
            {/* Drivers */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-around",
                flex: 1,
                borderRadius: "10px",
                border: `2px solid ${theme.text.primary}`,
                padding: "20px",
                flexWrap: "wrap",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <ValuedSelectField
                label="Drivers"
                value={drivers}
                multiple={true}
                onChange={(e) => setDrivers(e.target.value)}
                name="Drivers"
                items={userData?.drivers?.map((driver) => ({
                  _id: driver._id,
                  content: driver.driverName,
                }))}
                color={theme.text.secondary}
                sx={{ width: "250px", flex: 1 }}
              />
              <Box
                sx={{
                  color: theme.text.secondary,
                  flex: 2,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {drivers.map((item) => (
                    <Typography
                      sx={{
                        color: theme.text.secondary,
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: theme.background.button,
                        padding: "2px 15px",
                        borderRadius: "10px",
                      }}
                    >
                      {
                        userData?.drivers?.filter(
                          (driv) => driv._id === item
                        )[0].driverName
                      }
                    </Typography>
                  ))}{" "}
                </Box>
              </Box>
            </Box>
            {/* Vehicles */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-around",
                flex: 1,
                borderRadius: "10px",
                border: `2px solid ${theme.text.primary}`,
                padding: "20px",
                flexWrap: "wrap",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <ValuedSelectField
                label="Vehicles"
                value={vehicles}
                onChange={(e) => setVehicles(e.target.value)}
                name="Vehicles"
                items={userData?.vehicles
                  ?.filter((el) => !el.branch || el.branch === "")
                  .map((vehicle) => ({
                    _id: vehicle._id,
                    content: `${vehicle.vehicleNickName} - ${vehicle.vehicleNumber}`,
                  }))}
                color={theme.text.secondary}
                multiple={true}
                sx={{ width: "250px", flex: 1 }}
              />
              <Box
                sx={{
                  color: theme.text.secondary,
                  flex: 2,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  {vehicles?.map((item) => (
                    <Typography
                      sx={{
                        color: theme.text.secondary,
                        display: "flex",
                        flexWrap: "wrap",
                        backgroundColor: theme.background.button,
                        padding: "2px 5px",
                        borderRadius: "10px",
                      }}
                    >
                      {
                        userData?.vehicles?.filter(
                          (driv) => driv._id === item
                        )[0].vehicleNickName
                      }{" "}
                      +{" "}
                      {
                        userData?.vehicles?.filter(
                          (driv) => driv._id === item
                        )[0].vehicleNumber
                      }
                    </Typography>
                  ))}{" "}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Pincodes and Planner */}
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {/* Pincodes */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
                borderRadius: "10px",
                border: `2px solid ${theme.text.primary}`,
                padding: "20px",
                flexWrap: "wrap",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <SelectField
                label="Pincodes"
                value={pincodes}
                onChange={(e) => setPincodes(e.target.value)}
                name="Pincodes"
                items={["123456", "654321", "112233"]}
                color={theme.text.secondary}
                multiple={true}
                sx={{ width: "250px", flex: 1 }}
              />
              <Box
                sx={{
                  color: theme.text.secondary,
                  flex: 2,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{pincodes + " "}</Typography>
              </Box>
            </Box>
            {/* Planner */}
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                border: `2px solid ${theme.text.primary}`,
                padding: "20px",
                flex: 1,
                borderRadius: "10px",
                flexWrap: "wrap",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <SelectField
                label="Planner"
                value={planner}
                onChange={(e) => setPlanner(e.target.value)}
                name="Planner"
                items={["Planner1", "Planner2", "Planner3"]}
                color={theme.text.secondary}
                multiple={true}
                sx={{ width: "250px", flex: 1 }}
              />
              <Box
                sx={{
                  color: theme.text.secondary,
                  flex: 2,
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>{planner + " "}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {editMode ? (
          <Button
            onClick={handleSubmit}
            sx={{
              marginTop: "20px",
              color: "#000",
              backgroundColor: "#83cbd8",
              width: "max-content",
            }}
          >
            Update
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            sx={{
              marginTop: "20px",
              color: "#000",
              backgroundColor: "#83cbd8",
              width: "max-content",
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddBranches;

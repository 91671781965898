import {
  Box,
  FormControl,
  InputLabel,
  Modal,
  Select,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import TextFields from "../../../components/misc/textfield";
import axiosInstance from "../../../api/axios";
import { toast } from "react-hot-toast";

const AddTrips = ({ OpenAddTrip, handleCloseAddTrip, editMode, editData }) => {
  const { theme } = useContext(ThemeContext);
  const { userData, setRefresh, branches } = useContext(AuthContext);
  const [customDetails, setCustomDetails] = useState(false);

  const [trip, setTrip] = useState({
    lane: "",
    manifest_date: new Date().toISOString().split("T")[0],
    serviceOptions: "",
    vehicle: "",
    driver: "",
    tripType: "",
    notes: "",
    driverName: "",
    driverPhone: "",
    vehicleNo: "",
    vehicleOwner: "",
    vehiclePhone: "",
    vehicleType: "",
    driverlicenseno: "",
  });

  const handleChangeTrip = (e) => {
    const { name, value } = e.target;
    setTrip((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    if (
      !trip.lane ||
      !trip.vehicleNo ||
      !trip.driverName ||
      !trip.driverPhone
    ) {
      return toast.error("Please fill all the fields");
    }

    const findRoute = userData?.planner?.find(
      (el) => String(el._id) === String(trip.lane)
    )?.route;
    const tripstogo = [];
    tripstogo.push(
      ...(findRoute?.stops?.map((item) => item.branchId) || []),
      findRoute?.destination
    );
    const newTrip = {
      planner_id: trip.lane,
      // driver_id: trip.driver,
      currentBranch: localStorage.getItem("currentbranch"),
      branch_id: localStorage.getItem("currentbranch"),
      // vehicle_id: trip.vehicle,
      balanceStops: tripstogo,
      nextBranch: tripstogo[0],
      startdate: trip.manifest_date,
      driverName: trip.driverName,
      driverPhone: trip.driverPhone,
      vehicleNo: trip.vehicleNo,
      vehicleOwner: trip.vehicleOwner,
      vehiclePhone: trip.vehiclePhone,
      vehicleType: trip.vehicleType,
      driverlicenseno: trip.driverlicenseno,
      status: "Active",
      route: findRoute,
      deliveryBranch: findRoute.destination,
      pickupBranch: findRoute.origin,
      events: [
        {
          date: new Date(),
          description: "Trip Created",
        },
      ],
    };
    const res = await axiosInstance.post("/trips/add", newTrip);
    if (res.status === 200) {
      toast.success("Trip Added Successfully");
      setRefresh(true);
      handleCloseAddTrip();
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleUpdate = async () => {
    const res = await axiosInstance.post(`/trips/update/${editData._id}`, {
      trip,
    });
    if (res.status === 200) {
      toast.success("Trip Updated Successfully");
      setRefresh(true);
      handleCloseAddTrip();
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (editMode) {
      const lanes = userData?.planner?.find(
        (el) => String(el._id) === String(editData.planner_id)
      );
      setTrip({
        manifest_date: editData.startdate.split("T")[0],
        lane: lanes._id,
        notes: editData.notes,
        driverName: editData.driverName,
        driverPhone: editData.driverPhone,
        vehicleNo: editData.vehicleNo,
        vehicleOwner: editData.vehicleOwner,
        vehiclePhone: editData.vehiclePhone,
        vehicleType: editData.vehicleType,
        driverlicenseno: editData.driverlicenseno,
      });
    } else {
      setTrip({
        manifest_date: new Date().toISOString().split("T")[0],
        notes: "",
        procurement_notes: "",
        driverName: "",
        driverPhone: "",
        vehicleNo: "",
        vehicleOwner: "",
        vehiclePhone: "",
        vehicleType: "",
        driverlicenseno: "",
        lane: "",
      });
    }
  }, [editMode]);

  return (
    <Modal
      open={OpenAddTrip}
      onClose={handleCloseAddTrip}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "300px", sm: "500px" },
          bgcolor: theme.background.default,
          color: "#000",
          borderRadius: "20px",
          padding: "10px",
          height: "80%",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: "20px",
            margin: "10px",
            textAlign: "center",
          }}
        >
          Add New Trip
        </Typography>
        {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={customDetails}
                  onChange={() => setCustomDetails(!customDetails)}
                  name="customDetails"
                  color="primary"
                />
              }
              label="Custom Details"
            />
          </FormControl>
        </Box> */}
        <Box
          sx={{
            padding: "10px",
            borderRadius: "10px",
            border: `2px solid ${theme.text.primary}`,
            marginBottom: "10px",
            overflow: "scroll",
            height: "85%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              padding: "10px",
              borderRadius: "10px",
              flexDirection: "column",
            }}
          >
            {/* Lane and Manifest Data */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <FormControl
                sx={{ width: "200px", borderColor: theme.text.secondary }}
              >
                <InputLabel sx={{ color: theme.text.secondary }}>
                  Lane *
                </InputLabel>
                <Select
                  label="Lane *"
                  value={trip.lane}
                  disabled={editMode}
                  onChange={(e) =>
                    handleChangeTrip({
                      target: { name: "lane", value: e.target.value },
                    })
                  }
                  name="lane"
                  sx={{
                    color: theme.text.secondary,
                    borderColor: theme.text.secondary,
                    backgroundColor: theme.button.primary,
                  }}
                >
                  {/* Fetch and add your branch options here */}
                  {userData?.planner?.map((item) => (
                    <MenuItem value={item?._id} key={item?._id}>
                      {item?.plannerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextFields
                label={"Manifest Date"}
                name="manifest_date"
                value={trip.manifest_date}
                onChange={handleChangeTrip}
                type="date"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
            </Box>
            {/* Vehicle Details */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                padding: "10px",
                borderRadius: "10px",
                flexDirection: "column",
                overflow: "scroll",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "100%",
                }}
              >
                <TextFields
                  label="Vehicle No *"
                  name="vehicleNo"
                  value={trip.vehicleNo}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  color={theme.text.secondary}
                />
                <TextFields
                  label="Vehicle Type"
                  name="vehicleType"
                  value={trip.vehicleType}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  color={theme.text.secondary}
                />
                <TextFields
                  label="Owner Name"
                  name="vehicleOwner"
                  value={trip.vehicleOwner}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  color={theme.text.secondary}
                />
                <TextFields
                  label="Owner Phone"
                  name="vehiclePhone"
                  value={trip.vehiclePhone}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  type="number"
                  color={theme.text.secondary}
                />
                <TextFields
                  label="Driver Name *"
                  name="driverName"
                  value={trip.driverName}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  color={theme.text.secondary}
                />
                <TextFields
                  label="Driver Phone *"
                  name="driverPhone"
                  value={trip.driverPhone}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  type="number"
                  color={theme.text.secondary}
                />
                <TextFields
                  label="License Number"
                  name="driverlicenseno"
                  value={trip.driverlicenseno}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  color={theme.text.secondary}
                />
                <TextFields
                  label="Notes"
                  name="notes"
                  value={trip.notes}
                  onChange={handleChangeTrip}
                  sx={{ width: "200px" }}
                  color={theme.text.secondary}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",

              width: "100%",
              justifyContent: "center",
            }}
          >
            {editMode ? (
              <Button
                onClick={handleUpdate}
                sx={{ color: "#fff", backgroundColor: theme.button.active }}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                sx={{ color: "#fff", backgroundColor: theme.button.active }}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddTrips;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  FormControl,
  Checkbox,
  Modal,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import ThemeContext from "../context/themeContext";
import AuthContext from "../context/authContext";
import Buttons from "../components/misc/button";
import Grid from "../components/misc/grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import axiosInstance from "../api/axios";
import { useNavigate } from "react-router-dom";
import ViewConsModal from "../components/modals/viewConsModal";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const UpcomingTrips = () => {
  const navigate = useNavigate();
  const { userData, setRefresh, branches, trips, totalConsignments } =
    useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [consignmentTableData, setConsignmentTableData] = useState([]);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [openViewConsignment, setOpenViewConsignment] = useState(false);
  const [selectConsData, setSelectConsData] = useState();
  const [selectTripData, setSelectTripData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewConsignments = (id) => {
    setOpenViewConsignment(true);
    const selectTrip = trips?.find((trip) => trip._id === id);
    const selectConsignment = totalConsignments?.filter(
      (consignment) => consignment.tripid === id
    );
    setSelectTripData(selectTrip);
    setSelectConsData(selectConsignment);
  };
  const handleCloseConsView = () => {
    setOpenViewConsignment(false);
  };

  const handleAccept = async (id, requestid) => {
    const acceptTrip = await axiosInstance.post(
      `/trips/accept/${id}/${requestid}`
    );
    if (acceptTrip.status === 200) {
      toast.success("Trip Accepted");
      setRefresh(true);
      navigate("/dashboard/bookings/trips");
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (userData) {
      setConsignmentTableData(userData?.requests);
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 90,
    },
    {
      field: "date",
      headerName: "Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "driverName",
      headerName: "Driver",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "vehicleno",
      headerName: "Vehicle",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "origin",
      headerName: "Origin",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "current",
      headerName: "Current",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "consignments",
      headerName: "Consignments",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "tripStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "controls",
      headerName: "Controls",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              minWidth: "100%",
              maxHeight: "100%",
              marginTop: "6px",
            }}
          >
            {params.row.controlStatus === "active" ? (
              <Button
                sx={{
                  padding: "5px 10px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: theme.button.active,
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: theme.button.active,
                  },
                }}
                onClick={() => handleAccept(params.row.trip_id, params.row._id)}
              >
                Trip Reached
              </Button>
            ) : (
              <Typography
                sx={{
                  color: theme.text.secondary,
                  padding: "5px 10px",
                }}
              >
                Completed
              </Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: "totalWeight",
      headerName: "Weight",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1.5,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          <Button
            title="View Consignments"
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleViewConsignments(params.row.trip_id)}
          >
            <VisibilityIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button>
        </Box>
      ),
    },
  ];

  const rows = consignmentTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    requestTrip: item.trip_id,
    date:
      trips?.find((it) => it._id === item.trip_id)?.startdate?.split("T")[0] ||
      "N/A",
    driverName: trips?.find((it) => it._id === item.trip_id)?.driverName,
    vehicleno: trips?.find((it) => it._id === item.trip_id)?.vehicleNo,
    vehicleType: trips?.find((it) => it._id === item.trip_id)?.vehicleType,
    totalWeight:
      trips
        ?.find((it) => it._id === item.trip_id)
        ?.LoadedConsignments?.reduce((acc, curr) => acc + curr.weight, 0) || 0,
    // consignments: trips.find((it) => it._id === item.trip_id)
    //   ?.LoadedConsignments?.length,
    consignments: totalConsignments?.filter((el) => el.tripid === item.trip_id)
      .length,
    trip_id: item.trip_id,
    requestid: item._id,

    tripStatus: trips.find((it) => it._id === item.trip_id)?.status,
    controlStatus: item.status,
    origin: branches.filter(
      (el) =>
        el._id === trips.find((it) => it._id === item.trip_id)?.pickupBranch
    )[0]?.branchName,
    current: branches.filter(
      (el) =>
        el._id === trips.find((it) => it._id === item.trip_id)?.currentBranch
    )[0]?.branchName,
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Upcoming Trips
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        {/* Search Field */}
        <TextField
          label="Search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          variant="standard"
          size="small"
          sx={{
            width: "300px",
            backgroundColor: theme.background.button,
            borderRadius: "10px",

            "& .MuiInputBase-input": {
              color: theme.text.secondary,
            },
          }}
          InputLabelProps={{
            sx: {
              ml: 2,
              color: theme.text.primary, // Label color when not focused
              "&.Mui-focused": {
                color: theme.text.primary, // Label color when focused
              },
            },
          }}
        />
        {/* Enable Filters */}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.text.primary, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.text.primary, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.text.primary, // Label color
            }}
          />
        </FormControl>
      </Box>

      <Box>
        <Grid
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={searchQuery}
        />
      </Box>
      {/* Consignment Modals */}
      <ViewConsModal
        openViewConsignment={openViewConsignment}
        handleCloseView={handleCloseConsView}
        selectTripData={selectTripData}
        selectConsData={selectConsData}
      />
    </Box>
  );
};

export default UpcomingTrips;

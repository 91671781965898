import React, { useContext, useState } from "react";
import { Box, Button, IconButton, Typography, Drawer } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import ThemeContext from "./context/themeContext";
import logo from "./assets/images/logo.png";
import Heroimg from "./assets/images/heroimg.jpg";

const Home = () => {
  const { theme } = useContext(ThemeContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundColor: "rgba(0, 159, 207, .7)",
          height: "100vh",
          backgroundImage: `url(${Heroimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Navbar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 20px",
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img src={logo} alt="logo" style={{ width: "120px" }} />
            <Typography
              sx={{
                fontSize: { xs: "24px", md: "32px" },
                textTransform: "uppercase",
                color: "#fff",
                fontWeight: "bolder",
              }}
            >
              Om Vairava Logistics
            </Typography>
          </Box>

          {/* Desktop Navbar */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "space-evenly",
              fontWeight: "bolder",
              fontSize: "18px",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Home
            </Link>

            <Link
              to="/about"
              style={{
                textDecoration: "none",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              About
            </Link>
            <Link
              to="/services"
              style={{
                textDecoration: "none",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Services
            </Link>
            <Link
              to="/contact"
              style={{
                textDecoration: "none",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Contact
            </Link>

            <Link to="/tracking">
              <Button
                sx={{
                  backgroundColor: "#009FCF",
                  color: "#fff",
                  borderRadius: "10px",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#009FCF",
                    color: "#fff",
                  },
                }}
              >
                Tracking
              </Button>
            </Link>
          </Box>

          {/* Mobile Menu Button */}
          <IconButton
            sx={{ display: { xs: "block", md: "none" }, color: "#fff" }}
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#009FCF",
              width: "250px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {/* Close Button */}
            <IconButton
              sx={{ alignSelf: "flex-end", color: "#fff" }}
              onClick={handleDrawerToggle}
            >
              <CloseIcon />
            </IconButton>

            {/* Mobile Navbar Links */}
            <Link
              to="/"
              onClick={handleDrawerToggle}
              style={{
                textDecoration: "none",
                color: "#fff",
                fontSize: "18px",
                padding: "10px 0",
              }}
            >
              Home
            </Link>
            <Link
              to="/about"
              onClick={handleDrawerToggle}
              style={{
                textDecoration: "none",
                color: "#fff",
                fontSize: "18px",
                padding: "10px 0",
              }}
            >
              About
            </Link>
            <Link
              to="/services"
              onClick={handleDrawerToggle}
              style={{
                textDecoration: "none",
                color: "#fff",
                fontSize: "18px",
                padding: "10px 0",
              }}
            >
              Services
            </Link>
            <Link
              to="/contact"
              onClick={handleDrawerToggle}
              style={{
                textDecoration: "none",
                color: "#fff",
                fontSize: "18px",
                padding: "10px 0",
              }}
            >
              Contact
            </Link>
            <Link to="/tracking">
              <Button
                sx={{
                  backgroundColor: "#fff",
                  color: "#009FCF",
                  borderRadius: "10px",
                  fontSize: "16px",
                  marginTop: "10px",
                  "&:hover": {
                    backgroundColor: "#009FCF",
                    color: "#fff",
                  },
                }}
                onClick={handleDrawerToggle}
              >
                Tracking
              </Button>
            </Link>
          </Box>
        </Drawer>

        {/* Hero Content */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            flexDirection: "column",
            textAlign: "center",
            padding: "0 20px",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "40px", md: "60px" },
              fontWeight: "bolder",
              textTransform: "uppercase",
              color: "#009FCF",
            }}
          >
            PAN-INDIA Delivery
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: "16px", md: "20px" },
              fontWeight: "bolder",
            }}
          >
            Best in class shipping all over INDIA
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;

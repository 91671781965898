import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Modal,
  Select,
  InputLabel,
  TextField,
  MenuItem as MaterialMenuItem,
} from "@mui/material";

import {
  Dropdown,
  Menu,
  MenuButton,
  IconButton,
  MenuItem as JoyMenuItem,
} from "@mui/joy";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import Buttons from "../../../components/misc/button";
import Grid from "../../../components/misc/grid";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import TextFields from "../../../components/misc/textfield";
import LRpdf from "../../LRpdf";
const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};
const confirmDeliveryStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const Consignment = () => {
  const navigate = useNavigate();
  const { userData, setRefresh, branches, totalConsignments } =
    useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [consignmentTableData, setConsignmentTableData] = useState([]);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [openFAConfirmation, setOpenFAConfirmation] = useState(false);
  const [consid, setConsid] = useState("");
  const [tripid, setTripid] = useState("");
  const [openDeliveryConfirmation, setOpenDeliveryConfirmation] =
    useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [podfile, setPodfile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [reciverName, setReciverName] = useState("");
  const [reciverPhone, setReciverPhone] = useState(0);
  const [unload, setUnload] = useState("");
  const handleCloseFAConfirmation = () => {
    setOpenFAConfirmation(false);
    setConsid("");
  };
  const handleCloseDeliveryConfirmation = () => {
    setOpenDeliveryConfirmation(false);
    setConsid("");
    setPodfile();
  };

  const handleViewPOD = async (id) => {
    const filepath = userData?.consignments?.find(
      (item) => item._id === id
    )?.podfile;
    if (filepath) {
      try {
        // Get the JWT token (assuming it's stored in localStorage or a similar place)
        // const token = localStorage.getItem("token");

        // Open the file in a new tab with authentication
        const response = await axiosInstance.get(
          `www.api.omvairava.com/consignment/pod/${id}`,
          {
            responseType: "blob", // To handle the file response
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filepath.split("/").pop()); // Extract file name
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error("Unauthorized or file not found");
        console.error("Error downloading file:", error);
      }
    } else {
      toast.error("POD file not found");
    }
  };

  const handleViewPODFormat = (id) => {
    navigate(`podformat/${id}`);
  };

  const handleFAConfirmations = async () => {
    if (!tripid || !consid) {
      toast.error("Please select trip");
    }
    // else if (!unload) {
    // toast.error("Please select unloading Branch");
    // }
    else {
      try {
        const res = axiosInstance.post(`/trips/forceadd/${tripid}/${consid}`);
        if (res.status === 200) {
          handleCloseFAConfirmation();
          toast.success("Consignment Added Successfully");
          setRefresh(true);
          setTripid("");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  };

  const handleDeliveryConfirmations = async () => {
    if (!consid || !podfile) {
      toast.error("Please select POD file");
    } else {
      const formdata = new FormData();
      formdata.append("podfile", podfile);
      try {
        const res = await axiosInstance.post(
          `consignment/delivery/${consid}`,
          formdata,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (res.status === 200) {
          handleCloseDeliveryConfirmation();
          toast.success("Marked as delivered successfully");
          setRefresh(true);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  };
  const handleDelete = async (id) => {
    const delUser = await axiosInstance.post(`consignment/delete/${id}`);
    if (delUser.status === 200) {
      toast.success("Consignment Deleted Successfully");
      setRefresh(true);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleEdit = (id) => {
    const filtrd = consignmentTableData.filter((item) => item._id === id);
    navigate(`edit/${filtrd[0]?._id}`);
  };

  const handleInvoice = (id) => {
    setInvoiceId(id);
    setOpenInvoice(true);
  };
  const handleCloseInvoice = () => {
    setOpenInvoice(false);
    setInvoiceId("");
  };

  // const handleViewUser = (id) => {
  //   console.log("view" + id);
  // };
  const handleOpenAddConsignments = () => {
    navigate("add");
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload a JPEG, JPG, or PDF file.");
      return;
    }
    const maxSize = 1048576; // 1MB in bytes
    if (file.size > maxSize) {
      toast.error("File size must be less than 1MB.");
      return;
    }

    setPodfile(file); // Set the file if validations pass
  };

  const handleConfirmDelivery = (id) => {
    setOpenDeliveryConfirmation(true);
    setConsid(id);
  };
  const handleForceAdd = (id) => {
    setOpenFAConfirmation(!openFAConfirmation);
    setConsid(id);
  };

  useEffect(() => {
    if (userData) {
      setConsignmentTableData(
        totalConsignments
          ?.filter(
            (item) =>
              item.consignmentStatus !== "erased" &&
              item.consignmentStatus !== "convert" &&
              item.branch === userData._id
          )
          .reverse()
      );
    }
  }, [userData, totalConsignments]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 90,
      flex: 1,
    },
    {
      field: "lrnumber",
      headerName: "Lr Number",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "invoiceno",
      headerName: "Invoice No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "pickupbranch",
      headerName: "Origin",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "deliverybranch",
      headerName: "Destination",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,
    },

    {
      field: "boxcount",
      headerName: "Box Count",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "actualWeight",
      headerName: "Weight",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "totalcharges",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "ftl",
      headerName: "FTL",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "consignmentStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            backgroundColor:
              (params.row.consignmentStatus === "Delivered" && "lightgreen") ||
              (params.row.consignmentStatus === "In Transit" && "lightpink"),
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",

              color:
                (params.row.consignmentStatus === "Delivered" && "#000") ||
                (params.row.consignmentStatus === "In Transit" && "#000"),
            }}
          >
            {params.row.consignmentStatus}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
      // width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            width: "100%",
            height: "100%",
          }}
        >
          {params.row.consignmentStatus !== "Ready for Shipping" && (
            <Dropdown>
              <MenuButton
                slots={{ root: IconButton }}
                sx={{
                  border: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <MoreVertIcon sx={{ color: theme.text.secondary }} />
              </MenuButton>
              <Menu>
                {(params.row.consignmentStatus === "Processing" ||
                  params.row.consignmentStatus === "In Transit") && (
                  <JoyMenuItem
                    title="Mark Delivery Status"
                    onClick={() => handleConfirmDelivery(params.row._id)}
                  >
                    Mark Delivery
                  </JoyMenuItem>
                )}
                {params.row.consignmentStatus === "Processing" && (
                  <JoyMenuItem
                    title="Force Add"
                    onClick={() => handleForceAdd(params.row._id)}
                  >
                    Force Add
                  </JoyMenuItem>
                )}
                {params.row.consignmentStatus === "Processing" && (
                  <JoyMenuItem onClick={() => handleEdit(params.row._id)}>
                    Edit
                  </JoyMenuItem>
                )}

                {params.row.consignmentStatus === "Processing" && (
                  <JoyMenuItem onClick={() => handleDelete(params.row._id)}>
                    Delete
                  </JoyMenuItem>
                )}
                {params.row.consignmentStatus === "Delivered" && (
                  <JoyMenuItem onClick={() => handleViewPOD(params.row._id)}>
                    View POD
                  </JoyMenuItem>
                )}
                {params.row.consignmentStatus === "In Transit" && (
                  <JoyMenuItem
                    onClick={() => handleViewPODFormat(params.row._id)}
                    sx={{ display: "flex", gap: "10px" }}
                  >
                    <DownloadIcon />
                    POD
                  </JoyMenuItem>
                )}
                <JoyMenuItem onClick={() => handleEdit(params.row._id)}>
                  View Details
                </JoyMenuItem>
                <JoyMenuItem onClick={() => handleInvoice(params.row._id)}>
                  View Invoice
                </JoyMenuItem>
              </Menu>
            </Dropdown>
          )}
        </Box>
      ),
    },
  ];

  const rows = consignmentTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    consignmentNumber: item.consignmentNumber,
    pickupbranch:
      branches.find((ite) => String(ite._id) === String(item.pickupbranch))
        ?.branchName ||
      (String(userData?._id) === String(item.pickupbranch) &&
        userData?.branchName),
    deliverybranch:
      branches?.find((ide) => String(ide._id) === String(item.deliverybranch))
        ?.branchName ||
      (String(userData._id) === String(item.deliverybranch) &&
        userData?.branchName),
    consignmentStatus: item.consignmentStatus,
    actualWeight: item.actualWeight,
    lrnumber: item.lrnumber,
    deliverystatus: item.tripid,
    totalcharges: item.totalcharges,
    branch: item.branch ? item.branch.name : "Unassigned",
    invoiceno: item.invoiceno,
    boxcount: item.invoices[0]?.quantity || 0,
    ftl: item.isftl,
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Consignments
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
          mt: 3,
        }}
      >
        <Buttons
          text={"Add Consignments"}
          onclicks={handleOpenAddConsignments}
        />

        {/* Search Field */}
        <TextField
          label="Search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          variant="standard"
          size="small"
          sx={{
            width: "300px",
            backgroundColor: theme.background.button,
            borderRadius: "10px",

            "& .MuiInputBase-input": {
              color: theme.text.secondary,
            },
          }}
          InputLabelProps={{
            sx: {
              ml: 2,
              color: theme.text.primary, // Label color when not focused
              "&.Mui-focused": {
                color: theme.text.primary, // Label color when focused
              },
            },
          }}
        />
        {/* Enable Filters */}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.text.primary, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.text.primary, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.text.primary, // Label color
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <Grid
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={searchQuery}
        />
      </Box>
      {/* Force Add */}
      <Modal
        open={openFAConfirmation}
        onClose={handleCloseFAConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add Consignment to running trip
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Active Trip
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tripid}
                label="Current Trip"
                onChange={(e) => setTripid(e.target.value)}
              >
                {userData?.trips
                  ?.filter((item) => item.status === "In-transit")
                  .map((item, index) => (
                    <MaterialMenuItem key={index} value={item._id}>
                      {item._id}-{item.plannerName || "N/A"}-{item.driverName}
                    </MaterialMenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Unload Branch
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={unload}
                label="Current Trip"
                onChange={(e) => setUnload(e.target.value)}
              >
                {branches?.map((item, index) => (
                  <MaterialMenuItem key={index} value={item._id}>
                    {item.branchName}
                  </MaterialMenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseFAConfirmation}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleFAConfirmations}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Confirm Delivery */}
      <Modal
        open={openDeliveryConfirmation}
        onClose={handleCloseDeliveryConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmDeliveryStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Change Delivery Status
            </Typography>
            {/* Reviever Details */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <TextFields
                name="reciverName"
                label="Reciver Name"
                value={reciverName}
                onChange={(e) => setReciverName(e.target.value)}
                type="text"
              />
              <TextFields
                name="reciverPhone"
                label="Reciver Phone"
                value={reciverPhone}
                onChange={(e) => setReciverPhone(e.target.value)}
                type="Number"
              />
            </Box>
            {/* Upload */}
            <Box>
              <TextField
                label="Upload POD File"
                name="podfile"
                type="file"
                InputLabelProps={{ shrink: true }}
                onChange={handleFileChange}
              />
            </Box>
            {/* Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseDeliveryConfirmation}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleDeliveryConfirmations}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <LRpdf
        openInvoice={openInvoice}
        invoiceId={invoiceId}
        handleCloseInvoice={handleCloseInvoice}
      />
    </Box>
  );
};

export default Consignment;

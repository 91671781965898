import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
  Modal,
  Divider,
  TextField,
} from "@mui/material";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import Buttons from "../../../components/misc/button";
import Grid from "../../../components/misc/grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import AddPickups from "./addPickups";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};
const confirmViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400 },
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Pickups = () => {
  const [OpenAddPickup, setOpenAddPickup] = useState(false);
  const { userData, setRefresh } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [pickupTableData, setPickupTableData] = useState([]);
  const [gridToolbar, setGridToolbar] = useState(false);
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [viewModalData, setViewModalData] = useState([]);
  const [selectId, setSelectId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectId("");
  };
  const handleCloseViewModal = () => {
    setOpenViewModal(false);
    setSelectId("");
    setViewModalData([]);
  };

  const handleViewModal = (id) => {
    setOpenViewModal(true);
    const filtrd = userData?.pickups?.filter((i) => i._id === id)[0];
    setViewModalData(filtrd);
  };

  const handleDeleteConfirmation = (id) => {
    setOpenDeleteModal(true);
    setSelectId(id);
  };

  const handleOpenAddPickup = () => {
    setOpenAddPickup(true);
  };

  const handleCloseAddPickup = () => {
    setOpenAddPickup(false);
    setRefresh(true);
  };

  const handleDelete = async () => {
    const delPickup = await axiosInstance.post(`pickups/delete/${selectId}`);
    if (delPickup.status === 200) {
      toast.success("Pickup Deleted Successfully");
      setRefresh(true);
      handleCloseDeleteModal();
      setSelectId("");
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleDeletePickup = async (id) => {
    navigate(`/dashboard/bookings/pickup/consignment/${id}`);
  };

  const handleChangeStatus = async (id) => {
    // find the pickupid from the consignemnts and get the id and forward it to consignment edit page
    const res = await axiosInstance.post(`pickups/update/${id}`);
    if (res.status === 200) {
      navigate(
        `/dashboard/bookings/pickup/consignment/${res.data.consignmentId}/${id}`
      );
      setRefresh(true);
    } else {
      toast.error("Something went wrong");
    }
  };
  useEffect(() => {
    if (userData) {
      setPickupTableData(
        userData.pickups
          ?.filter((item) => item.pickupStatus !== "erased")
          .reverse()
      );
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 90,
    },
    {
      field: "driverName",
      headerName: "Driver",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "pickupStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "requestedBy",
      headerName: "Requested By",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "approxWeight",
      headerName: "Approx Weight",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "boxes",
      headerName: "Box Count",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "controls",
      headerName: "Controls",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 200,

      renderCell: (params) => {
        const currentpickup = pickupTableData.filter(
          (item) => item._id === params.row._id
        )[0];

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              minWidth: "100%",
              maxHeight: "100%",
              marginTop: "6px",
            }}
          >
            {currentpickup?.pickupStatus !== "Delivered" ? (
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#009FCF",
                  "&:hover": {
                    backgroundColor: "#2E3867",
                    color: "#fff",
                  },
                }}
                onClick={() => handleChangeStatus(params.row._id)}
              >
                Pickup Reached
              </Button>
            ) : (
              <Typography>Completed</Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",

      align: "center",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleViewModal(params.row._id)}
          >
            <VisibilityIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button>

          {params.row.pickupStatus !== "Delivered" && (
            <Button
              sx={{
                padding: "5px",
                minWidth: "auto",
                color: `${theme.text.secondary}`,
                "&:hover": {
                  backgroundColor: "#2E3867",
                  color: "#fff",
                },
              }}
              onClick={() => handleDeleteConfirmation(params.row._id)}
            >
              <DeleteForeverIcon sx={{ fontSize: "25px", padding: "0px" }} />
            </Button>
          )}
        </Box>
      ),
    },
  ];

  const rows = pickupTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    driverName: item.driverName,
    vehicle: item.vehicleNo,
    pickupStatus: item.pickupStatus,
    requestedBy: item.requestedby,
    amount: item.estimate,
    approxWeight: item.approxWeight,
    boxes: item.boxcount,
    invoice: item.invoiceCount,
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Pickups
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          alignItems: "center",
          flexWrap: "wrap",
          gap: "10px",
          mt: 3,
        }}
      >
        <Buttons text={"Create Pickup"} onclicks={handleOpenAddPickup} />
        {/* Search Field */}
        <TextField
          label="Search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          variant="standard"
          size="small"
          sx={{
            width: "300px",
            backgroundColor: theme.background.button,
            borderRadius: "10px",

            "& .MuiInputBase-input": {
              color: theme.text.secondary,
            },
          }}
          InputLabelProps={{
            sx: {
              ml: 2,
              color: theme.text.primary, // Label color when not focused
              "&.Mui-focused": {
                color: theme.text.primary, // Label color when focused
              },
            },
          }}
        />
        {/* Enable Filters */}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.text.primary, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.text.primary, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.text.primary, // Label color
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <Grid
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={searchQuery}
        />
      </Box>
      <AddPickups
        OpenAddPickup={OpenAddPickup}
        handleCloseAddPickup={handleCloseAddPickup}
      />

      {/* Confirm Delete Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Delete
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseDeleteModal}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleDelete}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Confirm View Modal */}
      <Modal
        open={openViewModal}
        onClose={handleCloseViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmViewStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              width: "100%",
              padding: "10px",
              backgroundColor: theme.background.default,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ color: theme.text.secondary }}
            >
              Pickup Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: theme.text.primary,
                }}
              >
                Status : {viewModalData?.pickupStatus}
              </Typography>
              <Divider />

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  width: "100%",
                  overflow: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    color: theme.text.secondary,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography>Trip ID:</Typography>
                  <Typography>Date:</Typography>
                  <Typography>Pickup Confirmation Phone:</Typography>
                  <Typography>Driver Name:</Typography>
                  <Typography>Driver Phone:</Typography>
                  <Typography>Driver Liscence No:</Typography>
                  <Typography>Vehicle No</Typography>
                  <Typography>Vehicle Type</Typography>
                  <Typography>Owner Name:</Typography>
                  <Typography>Owner Phone:</Typography>
                  <Typography>Approx Weight:</Typography>
                  <Typography>Approx Box Count:</Typography>
                  <Typography>Approx Estimate:</Typography>
                  <Typography>Approx Invoice Count:</Typography>
                  <Typography>Pickup Address:</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    color: theme.text.secondary,
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography>{viewModalData?.tripid || "N/A"}</Typography>
                  <Typography>
                    {viewModalData?.createdAt?.split("T")[0] || "N/A"}
                  </Typography>
                  <Typography>
                    {viewModalData?.bookingNumber || "N/A"}
                  </Typography>
                  <Typography>{viewModalData?.driverName || "N/A"}</Typography>
                  <Typography>{viewModalData?.driverPhone || "N/A"}</Typography>
                  <Typography>
                    {viewModalData?.driverlicenseno || "N/A"}
                  </Typography>
                  <Typography>{viewModalData?.vehicleNo || "N/A"}</Typography>
                  <Typography>{viewModalData?.vehicleType || "N/A"}</Typography>
                  <Typography>
                    {viewModalData?.vehicleOwner || "N/A"}
                  </Typography>
                  <Typography>
                    {viewModalData?.vehiclePhone || "N/A"}
                  </Typography>
                  <Typography>
                    {viewModalData?.approxWeight || "N/A"}
                  </Typography>
                  <Typography>{viewModalData?.boxcount || "N/A"}</Typography>
                  <Typography>{viewModalData?.estimate || "N/A"}</Typography>
                  <Typography>
                    {viewModalData?.invoiceCount || "N/A"}
                  </Typography>
                  <Typography>
                    {viewModalData?.pickupAddress || "N/A"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Pickups;

import React, { useContext, useState } from "react";
import AuthContext from "../../context/authContext";
import ThemeContext from "../../context/themeContext";
import { Divider } from "@mui/joy";
import {
  Box,
  Button,
  Modal,
  Typography,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axiosInstance from "../../api/axios";
import toast from "react-hot-toast";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const ViewFTL = ({
  openViewConsignment,
  handleCloseView,
  selectTripData,
  selectConsData,
}) => {
  const { userData, branches, setRefresh } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);

  const handleRemoveCons = async (id) => {
    try {
      const res = await axiosInstance.post(`/consignment/removeftl/${id}`);

      if (res.status === 200) {
        setRefresh(true);
        toast.success("Consignment Removed");
        handleCloseView();
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal
      open={openViewConsignment}
      onClose={handleCloseView}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...confirmStyle,
          backgroundColor: theme.background.default,
          border: `2px solid ${theme.text.primary}`,
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "20px",
            color: theme.text.primary,
          }}
        >
          View Details
        </Typography>
        {/* Date,Status,Branch */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            textTransform: "uppercase",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {/* Pickup Date */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Date:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.startdate.split("T")[0]}
            </Typography>
          </Box>
          {/* Pickup Branch */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",

              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Pickup Branch:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.pickupCity || "N/A"}
            </Typography>
          </Box>
          {/* Delivery Branch */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",

              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Delivery Branch:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.deliveryCity || "N/A"}
            </Typography>
          </Box>
          {/* Status */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Status:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.status}
            </Typography>
          </Box>
        </Box>
        {/* Trip Details */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            gap: "10px",
            alignItems: "center",
            flexWrap: "wrap",
            margin: "10px 0",
            whiteSpace: "nowrap",
          }}
        >
          {/* Vehicle No */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: { xs: "100%", sm: "25%" },
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Vehicle No:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.vehicleNo}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "red" }}
          />
          {/* Vehicle Type */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: { xs: "100%", sm: "25%" },
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Vehicle Type:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.vehicleType}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "red" }}
          />
          {/* Vehicle Owner Name */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: { xs: "100%", sm: "25%" },
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography> Owner Name:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.vehicleOwner}
            </Typography>
          </Box>
          {/* Vehicle Owner Phone */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: { xs: "100%", sm: "25%" },
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography> Owner Phone:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.vehiclePhone}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "red" }}
          />
          {/* Vehicle Driver Name */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: { xs: "100%", sm: "25%" },
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Driver Name:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.driverName}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "red" }}
          />
          {/* Vehicle Driver Name */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: { xs: "100%", sm: "25%" },
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Typography>Driver Phone:</Typography>
            <Typography
              sx={{ fontWeight: "bolder", color: theme.text.primary }}
            >
              {selectTripData?.driverPhone}
            </Typography>
          </Box>
          {/* Trip Notes */}
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: "100%",
              padding: "10px 20px",
              alignItems: "center",
              justifyContent: "space-between",
              color: theme.text.secondary,
            }}
          >
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography>Notes:</Typography>
              <Typography
                sx={{
                  textDecoration: "underline",
                }}
              >
                {selectTripData?.notes || "N/A"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
              <Typography>Delivery Address:</Typography>
              <Typography
                sx={{
                  fontWeight: "bolder",
                  color: theme.text.primary,
                }}
              >
                {selectTripData?.deliveryAdd || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* Consignment Details */}
        <Box>
          <Typography
            sx={{
              fontWeight: "bolder",
              fontSize: "20px",
              textAlign: "center",
              color: theme.text.primary,
            }}
          >
            Consignment Details
          </Typography>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    S.No
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Date
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Tracking ID
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    LR No
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Consignment No
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Delivery Branch
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Weight
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Amount
                  </TableCell>
                  <TableCell align="center" sx={{ color: theme.text.primary }}>
                    Status
                  </TableCell>
                  {selectTripData?.status === "Ready for Loading" && (
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.primary }}
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  overflow: "auto",
                }}
              >
                {selectConsData?.map((item, index) => (
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {item.createdAt?.split("T")[0]}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {item.trackingid}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {item.lrnumber}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {item.consignmentNumber}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {branches?.find(
                        (ie) => String(ie._id) === String(item.deliveryBranch)
                      )?.branchName || userData?.branchName}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      {item.actualWeight} {item.actualWeightUom}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ color: theme.text.secondary }}
                    >
                      Rs. {item.totalcharges}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color:
                          item.consignmentStatus === "Delivered"
                            ? "green"
                            : "red",
                      }}
                    >
                      {item.consignmentStatus}
                    </TableCell>
                    {/* Remove Consignment */}
                    {item.consignmentStatus === "Ready for Shipping" && (
                      <TableCell align="center">
                        <Button
                          sx={{
                            padding: "5px",
                            minWidth: "auto",
                            color: `${theme.text.secondary}`,
                            "&:hover": {
                              backgroundColor: "#01B2EA",
                              color: "#fff",
                            },
                          }}
                          onClick={() => handleRemoveCons(item._id)}
                        >
                          <DeleteForeverIcon />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewFTL;

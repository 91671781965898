import React from "react";
import "./toggle.css";

const Toggle = ({ handleThemeChange }) => {
  return (
    <>
      <label id="theme-toggle-button" style={{ marginTop: "5px" }}>
        <input type="checkbox" id="toggle" onChange={handleThemeChange} />
        <svg viewBox="0 0 34.833 22" xmlns="http://www.w3.org/1999/xlink">
          <g
            transform="translate(1.75 1.75)"
            data-name="Component 15 – 1"
            id="Component_15_1"
          >
            <g
              filter="url(#container)"
              transform="matrix(1, 0, 0, 1, -1.75, -1.75)"
            >
              <rect
                fill="#83cbd8"
                transform="translate(1.75 1.75)"
                rx="8.75"
                height="17.5"
                width="30.333"
                data-name="container"
                id="container"
              ></rect>
            </g>
            <g transform="translate(1.167 1.167)" id="button">
              <g data-name="sun" id="sun">
                <g
                  filter="url(#sun-outer)"
                  transform="matrix(1, 0, 0, 1, -2.915, -2.915)"
                >
                  <circle
                    fill="#f8e664"
                    transform="translate(2.915 2.915)"
                    r="7.583"
                    cy="7.583"
                    cx="7.583"
                    data-name="sun-outer"
                    id="sun-outer-2"
                  ></circle>
                </g>
                <g
                  filter="url(#sun)"
                  transform="matrix(1, 0, 0, 1, -2.915, -2.915)"
                >
                  <path
                    fill="rgba(246,254,247,0.29)"
                    transform="translate(4.665 4.665)"
                    d="M5.833,0A5.833,5.833,0,1,1,0,5.833,5.833,5.833,0,0,1,5.833,0Z"
                    data-name="sun"
                    id="sun-3"
                  ></path>
                </g>
                <circle
                  fill="#fcf4b9"
                  transform="translate(4.083 4.083)"
                  r="3.5"
                  cy="3.5"
                  cx="3.5"
                  id="sun-inner"
                ></circle>
              </g>
              <g data-name="moon" id="moon">
                <g
                  filter="url(#moon)"
                  transform="matrix(1, 0, 0, 1, -15.75, -2.915)"
                >
                  <circle
                    fill="#cce6ee"
                    transform="translate(15.75 2.915)"
                    r="7.583"
                    cy="7.583"
                    cx="7.583"
                    data-name="moon"
                    id="moon-3"
                  ></circle>
                </g>
                <g
                  fill="#a6cad0"
                  transform="translate(-12.208 -0.504)"
                  id="patches"
                >
                  <circle
                    transform="translate(21.504 2.248)"
                    r="1"
                    cy="1"
                    cx="1"
                  ></circle>
                  <circle
                    transform="translate(19.683 8.976)"
                    r="1"
                    cy="1"
                    cx="1"
                    data-name="patch"
                  ></circle>
                  <circle
                    transform="translate(16.508 4.022)"
                    r="0.5"
                    cy="0.5"
                    cx="0.5"
                    data-name="patch"
                  ></circle>
                  <circle
                    transform="translate(25.541 9.444)"
                    r="0.5"
                    cy="0.5"
                    cx="0.5"
                    data-name="patch"
                  ></circle>
                  <circle
                    transform="translate(16.508 11.251)"
                    r="0.5"
                    cy="0.5"
                    cx="0.5"
                    data-name="patch"
                  ></circle>
                  <circle
                    transform="translate(25.041 5.265)"
                    r="0.75"
                    cy="0.75"
                    cx="0.75"
                    data-name="patch"
                  ></circle>
                </g>
              </g>
            </g>
            <g
              filter="url(#cloud)"
              transform="matrix(1, 0, 0, 1, -1.75, -1.75)"
            >
              <path
                fill="#fff"
                transform="translate(-1733.235 -80.47)"
                d="M1756.405,86.907a2.232,2.232,0,0,1,1.122.31.475.475,0,0,1,.36-.641,2.426,2.426,0,0,1,1.311.259c.017.01-.25-.984.141-1.358a1.059,1.059,0,0,1,1.414-.137.911.911,0,0,1,.427.929c.032.019,1.297-.025,1.643.637s-.433,1.272-.404,1.313a6.096,6.096,0,0,1,1.139.446c.277.224.553.996-.81,1.464a3.871,3.871,0,0,1-1.881-.15c-.64-.245-.591-1.325-.569-1.312s-.709,1.1-1.311,1.1a2.086,2.086,0,0,1-1.197-.603,1.912,1.912,0,0,1-1.386.387c-1.715-.23-1.167-1.633-1.1-1.775A1.861,1.861,0,0,1,1756.405,86.907Z"
                data-name="cloud"
                id="cloud"
              ></path>
            </g>
            <g fill="#def8ff" transform="translate(1.793 0.663)" id="stars">
              <path
                transform="matrix(-1, 0.017, -0.017, -1, 12.115, 1.528)"
                d="M.774,0,.566.559,0,.539.458.933.25,1.492l.485-.361.458.394L1.024.953,1.509.592.943.572Z"
              ></path>
              <path
                transform="matrix(-0.777, 0.629, -0.629, -0.777, 11.592, 6.179)"
                d="M1.341.529.836.472.736,0,.505.46,0,.4.4.729l-.231.46L.605.932l.4.326L.9.786Z"
                data-name="star"
              ></path>
              <path
                transform="matrix(0.438, 0.899, -0.899, 0.438, 11.589, 14.868)"
                d="M.015,1.065.475.9l.285.365L.766.772l.46-.164L.745.494.751,0,.481.407,0,.293.285.658Z"
                data-name="star"
              ></path>
              <path
                transform="translate(6.338 0.194) rotate(104)"
                d="M1.161,1.6,1.059,1,1.574.722.962.607.86,0,.613.572,0,.457.446.881.2,1.454l.516-.274Z"
                data-name="star"
              ></path>
              <path
                transform="matrix(-0.07, 0.998, -0.998, -0.07, 5.533, 7.729)"
                d="M.873,1.648l.114-.62L1.579.945,1.03.62,1.144,0,.706.464.157.139.438.7,0,1.167l.592-.083Z"
                data-name="star"
              ></path>
              <path
                transform="translate(4.163 14.03) rotate(11)"
                d="M.593,0,.638.724,0,.982l.7.211.045.724.36-.64.7.211L1.342.935,1.7.294,1.063.552Z"
                data-name="star"
              ></path>
              <path
                transform="translate(2.506 2.981) rotate(172)"
                d="M.816,0,.5.455,0,.311.323.767l-.312.455.516-.215.323.456L.827.911,1.343.7.839.552Z"
                data-name="star"
              ></path>
              <path
                transform="translate(1.109 7.308) rotate(169)"
                d="M1.261,0,.774.571.114.3.487.967,0,1.538.728,1.32l.372.662.047-.749.728-.218L1.215.749Z"
                data-name="star"
              ></path>
            </g>
          </g>
        </svg>
      </label>
    </>
  );
};

export default Toggle;

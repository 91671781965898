import React, { useContext, useState } from "react";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import { Stack, Box, Modal, Button } from "@mui/material";
import { Outlet, Link } from "react-router-dom";
import themeContext from "./context/themeContext";
const mobSideBar = [
  {
    name: "Dashboard",
    path: "/dashboard",
  },
  { name: "Pickup Request", path: "/dashboard/bookings/pickup" },
  { name: "Consignments", path: "/dashboard/bookings/consignment" },
  { name: "Planner", path: "/dashboard/bookings/planner" },
  { name: "Trips", path: "/dashboard/bookings/trips" },
  { name: "FTL", path: "/dashboard/bookings/ftl" },
  { name: "Deliveries", path: "/dashboard/deliveries" },
  { name: "Upcoming Trips", path: "/dashboard/upcomingtrips" },
  { name: "Reports", path: "/dashboard/reports" },
  { name: "Account", path: "/dashboard/account" },
];
const DashLayout = () => {
  const { handleThemeChange, theme } = useContext(themeContext);
  const [openSidebar, setOpenSidebar] = useState(false);
  const handleOpenSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const confirmStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "max-content",
    boxShadow: 24,
    p: 1,
    overflow: "auto",
    borderRadius: "15px",
    border: `2px solid ${theme.text.primary}`,
    backgroundColor: theme.background.default,
  };
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: `${theme.background.default}`,
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Navbar
        handleThemeChange={handleThemeChange}
        handleOpenSidebar={handleOpenSidebar}
        openSidebar={openSidebar}
      />
      <Stack
        sx={{
          minHeight: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar />
        <Box
          sx={{
            height: "90vh",
            margin: { xs: "0px", sm: "15px" },
            width: "100%",
            // background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            borderRadius: "10px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            boxShadow: theme.sidebar.boxShadow,
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Stack>
      <Modal
        open={openSidebar}
        onClose={handleOpenSidebar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
              flexDirection: "column",
              height: "100%",
              mb: 3,
              mt: 3,
            }}
          >
            {mobSideBar.map((item) => (
              <Button
                key={item.name}
                component={Link}
                to={item.path}
                onClick={handleOpenSidebar}
                sx={{
                  backgroundColor: theme.background.button,
                  color: theme.text.secondary,
                  borderRadius: "10px",
                  width: "80%",
                }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DashLayout;

import React, { useContext, useState, useEffect } from "react";
import TextFields from "../../../components/misc/textfield";
import ThemeContext from "../../../context/themeContext";
import { Box, Button, Modal, Typography } from "@mui/material";
import axiosInstance from "../../../api/axios";
import { toast } from "react-hot-toast";
import AuthContext from "../../../context/authContext";
import { branchId } from "../../../context/RequireAuth";
import randomstring from "randomstring";

const AddPickups = ({ OpenAddPickup, handleCloseAddPickup }) => {
  const { setRefresh } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [pickups, setPickups] = useState({
    driver: "",
    vehicle: "",
    requestedby: "",
    estimate: 0,
    approxWeight: 0,
    boxcount: 0,
    pickupAddress: "",
    invoiceCount: 0,
    notes: "",
    pickupDate: new Date().toISOString().split("T")[0],
    bookingNumber: 0,
    requestedphone: 0,
    driverName: "",
    driverPhone: "",
    vehicleNo: "",
    vehicleOwner: "",
    vehiclePhone: "",
    vehicleType: "",
    driverlicenseno: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPickups((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !pickups.driverName ||
      !pickups.driverPhone ||
      !pickups.vehicleNo ||
      !pickups.vehicleType ||
      !pickups.vehiclePhone ||
      pickups.estimate <= 0 ||
      pickups.approxWeight <= 0 ||
      pickups.boxcount <= 0 ||
      !pickups.pickupAddress ||
      pickups.invoiceCount <= 0
    ) {
      toast.error("Please fill all the required fields");
      return;
    }
    const trackingid = randomstring.generate({
      length: 10,
      charset: "alphabetic",
      capitalization: "uppercase",
    });
    const newPickup = {
      ...pickups,
      trackingid: trackingid,
      branch: branchId(),
    };

    try {
      const res = await axiosInstance.post("pickups/add", newPickup);
      if (res.status === 200) {
        toast.success("Pickup Created Successfully");
        setPickups({
          driver: "",
          vehicle: "",
          requestedby: "",
          estimate: 0,
          approxWeight: 0,
          boxcount: 0,
          pickupAddress: "",
          invoiceCount: 0,
          notes: "",
          pickupDate: "",
          bookingNumber: 0,
          requestedphone: 0,
          driverName: "",
          driverPhone: "",
          vehicleNo: "",
          vehicleOwner: "",
          vehiclePhone: "",
          vehicleType: "",
          driverlicenseno: "",
        });
        setRefresh(true);
        handleCloseAddPickup();
      }
    } catch (error) {
      toast.error("Error Adding Pickup");
      console.error(error);
    }
  };
  const handleCloseModal = () => {
    handleCloseAddPickup();
    setPickups({
      driver: "",
      vehicle: "",
      requestedby: "",
      estimate: 0,
      approxWeight: 0,
      boxcount: 0,
      pickupAddress: "",
      invoiceCount: 0,
      notes: "",
      pickupDate: "",
      bookingNumber: 0,
      requestedphone: 0,
    });
  };

  return (
    <Modal
      open={OpenAddPickup}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "300px", sm: "750px" },
          height: { xs: "600px", sm: "auto" },
          overflow: "auto",
          bgcolor: theme.background.default,
          color: "#000",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: "20px",
            margin: "10px",
            textAlign: "center",
          }}
        >
          Add New Pickup
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            margin: "10px",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
           // Drivers
            <Typography sx={{ fontWeight: "bold", color: theme.text.primary }}>
              Pickup Details
            </Typography>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customDetails}
                    onChange={() => setCustomDetails(!customDetails)}
                    name="customDetails"
                    color="primary"
                  />
                }
                label="Custom Details"
              />
            </FormControl>
          </Box> */}
          <Box
            sx={{
              padding: "20px",
              borderRadius: "10px",
              border: `2px solid ${theme.text.primary}`,
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <TextFields
                label="Vehicle No *"
                name="vehicleNo"
                value={pickups.vehicleNo}
                type="text"
                onChange={handleChange}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Vehicle Type *"
                name="vehicleType"
                value={pickups.vehicleType}
                onChange={handleChange}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Vehicle Owner Name"
                name="vehicleOwner"
                value={pickups.vehicleOwner}
                onChange={handleChange}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Vehicle Owner Phone *"
                name="vehiclePhone"
                value={pickups.vehiclePhone}
                onChange={handleChange}
                type="number"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Driver Name *"
                name="driverName"
                value={pickups.driverName}
                onChange={handleChange}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Driver Phone"
                name="driverPhone"
                type="number"
                value={pickups.driverPhone}
                onChange={handleChange}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="License Number"
                name="driverlicenseno"
                value={pickups.driverlicenseno}
                onChange={handleChange}
                sx={{ width: "200px" }}
                type="text"
                color={theme.text.secondary}
              />
              <TextFields
                label="Notes"
                name="notes"
                value={pickups.notes}
                onChange={handleChange}
                sx={{ width: "200px" }}
                type="text"
                color={theme.text.secondary}
              />
              <TextFields
                label="Pickup Date *"
                value={pickups.pickupDate}
                onChange={handleChange}
                name="pickupDate"
                type="date"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
              <TextFields
                label="Requested Phone no"
                value={pickups.requestedphone}
                onChange={handleChange}
                name="requestedphone"
                type="number"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />

              <TextFields
                label="Estimate (₹) *"
                value={pickups.driverContact}
                onChange={handleChange}
                name="estimate"
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Approx Weight (Kgs) *"
                value={pickups.approxWeight}
                onChange={handleChange}
                name="approxWeight"
                type="number"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
              <TextFields
                label="Box Count *"
                value={pickups.boxcount}
                onChange={handleChange}
                name="boxcount"
                type="number"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
              <TextFields
                label="Invoice Count *"
                value={pickups.invoiceCount}
                onChange={handleChange}
                name="invoiceCount"
                type="number"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                padding: "10px",
                borderRadius: "10px",
                flexDirection: { xs: "column" },
              }}
            >
              <TextFields
                label="Pickup Address *"
                value={pickups.pickupAddress}
                onChange={handleChange}
                name="pickupAddress"
                type="text"
                sx={{ flex: 1 }}
                color={theme.text.secondary}
                shrink={true}
              />
              <TextFields
                label="Notes"
                value={pickups.notes}
                onChange={handleChange}
                name="notes"
                type="text"
                sx={{ flex: 1 }}
                color={theme.text.secondary}
                shrink={true}
              />
            </Box>
          </Box>

          {/* Submit Button */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              sx={{
                width: "max-content",
                backgroundColor: theme.button.active,
                color: theme.text.secondary,
                "&:hover": {
                  backgroundColor: theme.button.active,
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPickups;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { Dropdown, Menu, MenuButton, IconButton, MenuItem } from "@mui/joy";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import Buttons from "../../../components/misc/button";
import Grid from "../../../components/misc/grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import axiosInstance from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import AddTrips from "./addTrips";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ViewConsModal from "../../../components/modals/viewConsModal";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const Trips = () => {
  const navigate = useNavigate();
  const { userData, setRefresh, branches, trips, totalConsignments } =
    useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [tripTableData, setTripTableData] = useState([]);
  const [OpenAddTrip, setOpenAddTrip] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const currentbranch = localStorage.getItem("currentbranch");
  const [gridToolbar, setGridToolbar] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editTripData, setEditTripData] = useState({});
  const [deleteMode, setDeleteMode] = useState(false);
  const [tripId, setTripId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [openViewConsignment, setOpenViewConsignment] = useState(false);
  const [selectConsData, setSelectConsData] = useState();
  const [selectTripData, setSelectTripData] = useState();
  const handleViewConsignments = (id) => {
    setOpenViewConsignment(true);
    const selectTrip = trips?.find((trip) => trip._id === id);
    const selectConsignment = totalConsignments?.filter(
      (consignment) => consignment.tripid === id
    );
    setSelectTripData(selectTrip);
    setSelectConsData(selectConsignment);
  };
  const handleCloseView = () => {
    setOpenViewConsignment(false);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
    setTripId("");
  };
  const handleCloseAddTrip = () => {
    setOpenAddTrip(false);
    setEditMode(false);
  };

  const handleOpenAddTrip = () => {
    setEditTripData([]);
    setOpenAddTrip(true);
  };

  const handleDeleteMode = (id) => {
    setDeleteMode(true);
    setOpenConfirmation(true);
    setTripId(id);
  };
  const handleDelete = async () => {
    const res = await axiosInstance.put(`/trips/delete/${tripId}`);
    if (res.status === 200) {
      handleCloseConfirmation();
      setDeleteMode(false);
      setTripId("");
      setRefresh(true);
      toast.success("Trip Deleted Successfully");
    } else {
      toast.error("Something went wrong");
    }
  };
  const handleEdit = (id) => {
    const filtrd = tripTableData.filter((item) => item._id === id);
    setEditMode(true);
    setEditTripData(filtrd[0]);
    setOpenAddTrip(true);
  };
  // const handleEditConsignments = (id) => {
  //   navigate("/dashboard/loadConsignments/" + id);
  // };
  const handleConfirmation = (id) => {
    setOpenConfirmation(true);
    setTripId(id);
  };

  const handleLoadConsignments = (id) => {
    navigate("/dashboard/loadConsignments/" + id);
  };
  const handleStartTrip = async () => {
    const res = await axiosInstance.post(`/trips/start/${tripId}`);
    if (res.status === 200) {
      setRefresh(true);
      handleCloseConfirmation();
      toast.success("Trip Started Successfully");
    } else {
      handleCloseConfirmation();
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (userData) {
      setTripTableData(
        userData?.trips
          ?.filter(
            (item) =>
              // item.status !== "erased" && item.pickupBranch === branch_id
              item.status !== "erased"
          )
          ?.reverse()
      );
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 90,
    },
    {
      field: "vehicle",
      headerName: "Vehicle",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "driver",
      headerName: "Driver",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "Pickupbranch",
      headerName: "Pick at",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "delivery_branch",
      headerName: "Delivery To",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "loadedConsignments",
      headerName: "Consignments",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "controls",
      headerName: "Controls",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        const isCurrentBranch =
          String(params?.row?.currentBranch) === String(currentbranch);
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "100%",
              maxHeight: "100%",
              marginTop: "6px",
              flexDirection: "column",
            }}
          >
            {isCurrentBranch && params?.row?.status === "Ready for Loading" && (
              <Button
                sx={{
                  padding: "5px",
                  width: "100%",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "green",
                    color: "#fff",
                  },
                  backgroundColor: "green",
                  borderRadius: "10px",
                }}
                onClick={() => handleConfirmation(params.row._id)}
              >
                Start Trip
              </Button>
            )}
            {isCurrentBranch && params?.row?.status === "Active" && (
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#000",
                  "&:hover": {
                    backgroundColor: "orange",
                    color: "#fff",
                  },
                  backgroundColor: "orange",
                  borderRadius: "10px",
                }}
                onClick={() => handleLoadConsignments(params.row._id)}
              >
                Load Consignments
              </Button>
            )}

            {isCurrentBranch &&
              params?.row?.status === "In-transit" &&
              String(currentbranch) !== String(params?.row?.pickupBranch) &&
              String(currentbranch) !== String(params?.row?.route?.origin) && (
                <Button
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: "#000",
                    "&:hover": {
                      backgroundColor: "orange",
                      color: "#fff",
                    },
                    backgroundColor: "orange",
                    borderRadius: "10px",
                  }}
                  onClick={() => handleLoadConsignments(params.row._id)}
                >
                  Load more
                </Button>
              )}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          <Dropdown>
            <MenuButton
              slots={{ root: IconButton }}
              sx={{
                border: "none",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <MoreVertIcon sx={{ color: theme.text.secondary }} />
            </MenuButton>
            <Menu>
              {(params.row.status === "Active" ||
                params.row.status === "Ready for Loading") && (
                <MenuItem
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleEdit(params.row._id)}
                >
                  Edit Trip
                </MenuItem>
              )}
              {/* {params.row.status === "Ready for Loading" && (
                <MenuItem
                  title="Edit Consignments"
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleEditConsignments(params.row._id)}
                >
                  Edit Consignments
                </MenuItem>
              )} */}
              {params.row.status === "Active" && (
                <MenuItem
                  title="Delete Trip"
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleDeleteMode(params.row._id)}
                >
                  Delete Trip
                </MenuItem>
              )}
              {(params.row.status === "Completed" ||
                params.row.status === "Ready for Loading" ||
                params.row.status === "In-transit") && (
                <MenuItem
                  title="View Consignment Details"
                  sx={{
                    padding: "5px",
                    minWidth: "auto",
                    color: `${theme.text.secondary}`,
                    "&:hover": {
                      backgroundColor: "#2E3867",
                      color: "#fff",
                    },
                  }}
                  onClick={() => handleViewConsignments(params.row._id)}
                >
                  View Consignments
                </MenuItem>
              )}
            </Menu>
          </Dropdown>
        </Box>
      ),
    },
  ];

  const rows = tripTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    currentBranch: item.currentBranch,
    vehicle: item.vehicleNo,
    Pickupbranch:
      userData._id === item.pickupBranch
        ? userData.branchName
        : branches?.find((ide) => String(ide._id) === String(item.pickupBranch))
            .branchName,
    delivery_branch:
      userData._id === item.deliveryBranch
        ? userData.branchName
        : branches?.find(
            (ide) => String(ide._id) === String(item.deliveryBranch)
          )?.branchName,
    current_location:
      item.status === "Active"
        ? userData._id === item.pickupBranch
          ? userData.branchName
          : branches?.find(
              (ide) => String(ide._id) === String(item.pickupBranch)
            )
        : item.status,
    driver: item.driverName,
    // loadedConsignments: item.LoadedConsignments?.length || 0,
    loadedConsignments:
      totalConsignments?.filter(
        (consignment) => consignment.tripid === item._id
      ).length || 0,
    status: item.status,
    totalcharges: item.totalcharges,
    branch: item.branch ? item.branch.name : "Unassigned",
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Trips
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", sm: "space-between" },
          gap: "10px",
          mt: 3,
          flexWrap: "wrap",
        }}
      >
        <Buttons text={"Create Trips"} onclicks={handleOpenAddTrip} />
        {/* Search Field */}
        <TextField
          label="Search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          variant="standard"
          size="small"
          sx={{
            width: "300px",
            backgroundColor: theme.background.button,
            borderRadius: "10px",

            "& .MuiInputBase-input": {
              color: theme.text.secondary,
            },
          }}
          InputLabelProps={{
            sx: {
              ml: 2,
              color: theme.text.primary, // Label color when not focused
              "&.Mui-focused": {
                color: theme.text.primary, // Label color when focused
              },
            },
          }}
        />
        {/* Enable Filters */}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.text.primary, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.text.primary, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.text.primary, // Label color
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <Grid
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={searchQuery}
        />
      </Box>
      <AddTrips
        OpenAddTrip={OpenAddTrip}
        handleCloseAddTrip={handleCloseAddTrip}
        editMode={editMode}
        editData={editTripData}
      />

      {/* Handle Start Trip */}
      <Modal
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Confirm Trip Start?
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseConfirmation}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={deleteMode ? handleDelete : handleStartTrip}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <ViewConsModal
        openViewConsignment={openViewConsignment}
        handleCloseView={handleCloseView}
        selectTripData={selectTripData}
        selectConsData={selectConsData}
      />
    </Box>
  );
};

export default Trips;

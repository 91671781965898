import React from "react";
import { TextField } from "@mui/material";

const TextFields = ({
  label,
  value,
  onChange,
  name,
  type = "text",
  sx,
  color,
  shrink,
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      type={type}
      variant="standard"
      margin="normal"
      size="small"
      sx={{
        ...sx,
        svg: {
          color: color,
        },
        input: { color: color },
        "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
        },
        "input[type=number]": {
          MozAppearance: "textfield",
        },
        "& .MuiInputLabel-root": { color: color }, // Label color
        "& .MuiInput-underline:before": {
          borderBottomColor: color,
        }, // Underline color before focus
        "& .MuiInput-underline:after": {
          borderBottomColor: color,
        }, // Underline color after focus
        "& .MuiInputBase-input": { color: color }, // Input text color
      }}
      InputLabelProps={{ shrink }}
      InputProps={{
        inputProps: {
          style: { appearance: type === "number" ? "textfield" : undefined }, // Disable spin buttons
        },
      }}
    />
  );
};

export default TextFields;

import React, { useContext, useEffect, useState } from "react";
import AddBranches from "./addBranches";
import { Box, Button, Table, Typography } from "@mui/material";
import ThemeContext from "../../context/themeContext";
import AuthContext from "../../context/authContext";
import Buttons from "../../components/misc/button";
import Grid from "../../components/misc/grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import axiosInstance from "../../api/axios";
import { useNavigate } from "react-router-dom";

const Branches = () => {
  const navigate = useNavigate();
  const [OpenAddBranch, setOpenAddBranch] = useState(false);
  const { userData, setRefresh } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [BranchTableData, setBranchTableData] = useState([]);

  const handleDelete = async (id) => {
    const delUser = await axiosInstance.delete(`Branches/delete/${id}`);
    if (delUser.status === 200) {
      toast.success("Branch Deleted Successfully");
      setRefresh(true);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleEdit = (id) => {
    setOpenAddBranch(true);
    const filtrd = BranchTableData.filter((item) => item._id === id);
    navigate(`edit/${filtrd[0]._id}`);
  };

  // const handleViewUser = (id) => {
  //   console.log("view" + id);
  // };
  const handleOpenAddBranch = () => {
    navigate("add");
  };

  useEffect(() => {
    if (userData) {
      setBranchTableData(userData.branch);
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      width: 90,
    },
    {
      field: "branchName",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "branchCode",
      headerName: "Code",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Phone",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "activeTrips",
      headerName: "Active Trips",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "tripsCompleted",
      headerName: "Trips Completed",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      // width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          {/* <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleViewUser(params.row._id)}
          >
            <VisibilityIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button> */}
          <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleEdit(params.row._id)}
          >
            <EditIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button>
          <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleDelete(params.row._id)}
          >
            <DeleteForeverIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button>
        </Box>
      ),
    },
  ];

  const rows = BranchTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    branchName: item.branchName,
    branchCode: item.branchCode,
    contact: item.contact,
    email: item.email,
    tripsCompleted: item.trips?.length ?? 0,
    activeTrips:
      item.trips?.filter((item) => item.tripStatus === "Active").length ?? 0,
    status: item.status ?? "Active",
    branch: item.branch ? item.branch.name : "Unassigned",
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Branches
      </Typography>
      <Buttons text={"Add Branch"} onclicks={handleOpenAddBranch} />
      <Box>
        <Grid columns={columns} rows={rows} />
      </Box>
    </Box>
  );
};

export default Branches;

import {
  Box,
  Typography,
  Collapse,
  ListItemButton,
  List,
  Grow,
  Button,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ThemeContext from "../../context/themeContext";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { getRoles } from "../../context/RequireAuth";

const Sidebar = () => {
  const location = useLocation();
  const locate = location.pathname;
  const navigate = useNavigate();
  const [openBookings, setOpenBookings] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const { theme } = useContext(ThemeContext);

  const handleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleClickBookings = () => {
    setOpenBookings(!openBookings);
  };

  return (
    <Box
      sx={{
        // background: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(10px)",
        width: showSidebar ? "15%" : "1%",
        display: { xs: "none", sm: "flex" },
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "30px 0",
        borderRight: "1px solid rgba(255, 255, 255, 0.1)",
        boxShadow: theme.sidebar.boxShadow,
        borderRadius: "10px",
        marginTop: "15px",
        position: "relative",
      }}
    >
      {/* categories */}
      {showSidebar && (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <List
            sx={{
              color: "#fff",
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              padding: "0px 10px",
            }}
            component="nav"
          >
            {/* Dashboard */}
            <Link
              to="/dashboard"
              underline="none"
              style={{ width: "100%" }}
              onClick={() => {
                setOpenBookings(false);
              }}
            >
              <ListItemButton
                sx={{
                  backgroundColor:
                    locate === "/dashboard" ? theme.button.active : "",
                  color:
                    locate === "/dashboard"
                      ? theme.button.activetext
                      : theme.button.text,
                  fontSize: "18px",
                  display: "flex",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: theme.button.active,
                    color: "#000",
                  },
                }}
              >
                {/* <ListItemText primary="Dashboard" /> */}
                <Typography>Dashboard</Typography>
              </ListItemButton>
            </Link>

            {/* Bookings */}
            <ListItemButton
              checked={openBookings}
              onClick={() => {
                handleClickBookings();
              }}
              sx={{
                backgroundColor:
                  locate === "/dashboard/bookings/consignment" ||
                  locate === "/dashboard/bookings/planner" ||
                  locate === "/dashboard/bookings/trips" ||
                  locate === "/dashboard/bookings/hired" ||
                  locate === "/dashboard/bookings/pickup"
                    ? theme.button.active
                    : "",
                color:
                  locate === "/dashboard/bookings/consignment" ||
                  locate === "/dashboard/bookings/planner" ||
                  locate === "/dashboard/bookings/trips" ||
                  locate === "/dashboard/bookings/hired" ||
                  locate === "/dashboard/bookings/pickup"
                    ? theme.button.activetext
                    : theme.button.text,
                fontSize: "18px",
                display: "flex",
                textAlign: "left",
                borderRadius: "10px",
                width: "100%",
                "&:hover": {
                  backgroundColor: theme.button.active,
                  color: "#000",
                },
              }}
            >
              {/* <ListItemText primary="Customers" /> */}
              <Typography>Bookings</Typography>

              {openBookings ? (
                <ExpandLess
                  sx={{
                    position: "absolute",
                    right: "5px",
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{
                    position: "absolute",
                    right: "5px",
                  }}
                />
              )}
            </ListItemButton>

            <Collapse
              in={openBookings}
              timeout="auto"
              unmountOnExit
              sx={{
                width: "100%",
                backgroundColor: theme.background.button,
                borderRadius: "10px",
              }}
            >
              <List component="div" disablePadding sx={{ width: "100%" }}>
                {/* Pickup Request */}
                <Grow
                  in={openBookings}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(openBookings ? { timeout: 1000 } : {})}
                >
                  <Link to="bookings/pickup" underline="none">
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          locate === "/dashboard/bookings/pickup"
                            ? theme.button.active
                            : "",
                        color:
                          locate === "/dashboard/bookings/pickup"
                            ? theme.button.activetext
                            : theme.button.text,
                        display: "flex",
                        textAlign: "left",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor:
                            locate === "/dashboard/bookings/pickup"
                              ? "#00b3ea"
                              : "",
                        },
                      }}
                    >
                      <Typography>Pickup Request</Typography>
                    </ListItemButton>
                  </Link>
                </Grow>
                {/* Consignment */}
                <Grow
                  in={openBookings}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(openBookings ? { timeout: 1000 } : {})}
                >
                  <Link to="bookings/consignment" underline="none">
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          locate === "/dashboard/bookings/consignment"
                            ? theme.button.active
                            : "",
                        color:
                          locate === "/dashboard/bookings/consignment"
                            ? theme.button.activetext
                            : theme.button.text,
                        display: "flex",
                        textAlign: "left",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor:
                            locate === "/dashboard/bookings/consignment"
                              ? "#00b3ea"
                              : "",
                        },
                      }}
                    >
                      <Typography>Consignment</Typography>
                    </ListItemButton>
                  </Link>
                </Grow>
                {/* Planner */}
                <Grow
                  in={openBookings}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(openBookings ? { timeout: 1300 } : {})}
                >
                  <Link to="bookings/planner" underline="none">
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          locate === "/dashboard/bookings/planner"
                            ? "#009fd0"
                            : "",
                        display: "flex",
                        textAlign: "left",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor:
                            locate === "/dashboard/bookings/planner"
                              ? "#00b3ea"
                              : "",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            locate === "/dashboard/bookings/planner"
                              ? theme.button.activetext
                              : theme.button.text,
                        }}
                      >
                        Planner
                      </Typography>
                    </ListItemButton>
                  </Link>
                </Grow>
                {/* Trips */}
                <Grow
                  in={openBookings}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(openBookings ? { timeout: 1300 } : {})}
                >
                  <Link to="bookings/trips" underline="none">
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          locate === "/dashboard/bookings/trips"
                            ? "#009fd0"
                            : "",
                        display: "flex",
                        textAlign: "left",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor:
                            locate === "/dashboard/bookings/trips"
                              ? "#00b3ea"
                              : "",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            locate === "/dashboard/bookings/trips"
                              ? theme.button.activetext
                              : theme.button.text,
                        }}
                      >
                        Trips
                      </Typography>
                    </ListItemButton>
                  </Link>
                </Grow>
                {/* Trips */}
                <Grow
                  in={openBookings}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(openBookings ? { timeout: 1300 } : {})}
                >
                  <Link to="bookings/ftl" underline="none">
                    <ListItemButton
                      sx={{
                        backgroundColor:
                          locate === "/dashboard/bookings/ftl" ? "#009fd0" : "",
                        display: "flex",
                        textAlign: "left",
                        borderRadius: "10px",
                        "&:hover": {
                          backgroundColor:
                            locate === "/dashboard/bookings/ftl"
                              ? "#00b3ea"
                              : "",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            locate === "/dashboard/bookings/ftl"
                              ? theme.button.activetext
                              : theme.button.text,
                        }}
                      >
                        FTL
                      </Typography>
                    </ListItemButton>
                  </Link>
                </Grow>
              </List>
            </Collapse>
            {/* Branches */}
            {getRoles() === "admin" && (
              <Link
                to="/dashboard/branches"
                underline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setOpenBookings(false);
                }}
              >
                <ListItemButton
                  sx={{
                    backgroundColor:
                      locate === "/dashboard/branches"
                        ? theme.button.active
                        : "",
                    color:
                      locate === "/dashboard/branches"
                        ? theme.button.activetext
                        : theme.button.text,
                    fontSize: "18px",
                    display: "flex",
                    textAlign: "left",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: theme.button.active,
                      color: "#000",
                    },
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography>Branches</Typography>
                </ListItemButton>
              </Link>
            )}
            {/* Deliveries */}
            <Link
              to="/dashboard/deliveries"
              underline="none"
              style={{ width: "100%" }}
              onClick={() => {
                setOpenBookings(false);
              }}
            >
              <ListItemButton
                sx={{
                  backgroundColor:
                    locate === "/dashboard/deliveries"
                      ? theme.button.active
                      : "",
                  color:
                    locate === "/dashboard/deliveries"
                      ? theme.button.activetext
                      : theme.button.text,
                  fontSize: "18px",
                  display: "flex",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: theme.button.active,
                    color: "#000",

                    //  locate!=="/dashboard" ? "#e7e7e7" : "",
                  },
                }}
              >
                {/* <ListItemText primary="Dashboard" /> */}
                <Typography>Deliveries</Typography>
              </ListItemButton>
            </Link>
            {/* Upcoming Trips */}
            <Link
              to="/dashboard/upcomingtrips"
              underline="none"
              style={{ width: "100%" }}
              onClick={() => {
                setOpenBookings(false);
              }}
            >
              <ListItemButton
                sx={{
                  backgroundColor:
                    locate === "/dashboard/upcomingtrips"
                      ? theme.button.active
                      : "",
                  color:
                    locate === "/dashboard/upcomingtrips"
                      ? theme.button.activetext
                      : theme.button.text,
                  fontSize: "18px",
                  display: "flex",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: theme.button.active,
                    color: "#000",
                  },
                }}
              >
                {/* <ListItemText primary="Dashboard" /> */}
                <Typography>Upcoming Trips</Typography>
              </ListItemButton>
            </Link>
            {/* Vehicles */}
            {getRoles() === "admin" && (
              <Link
                to="/dashboard/vehicles"
                underline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setOpenBookings(false);
                }}
              >
                <ListItemButton
                  sx={{
                    backgroundColor:
                      locate === "/dashboard/vehicles"
                        ? theme.button.active
                        : "",
                    color:
                      locate === "/dashboard/vehicles"
                        ? theme.button.activetext
                        : theme.button.text,
                    fontSize: "18px",
                    display: "flex",
                    textAlign: "left",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: theme.button.active,
                      color: "#000",
                    },
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography>Vehicles</Typography>
                </ListItemButton>
              </Link>
            )}
            {/* Drivers */}
            {getRoles() === "admin" && (
              <Link
                to="/dashboard/drivers"
                underline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setOpenBookings(false);
                }}
              >
                <ListItemButton
                  sx={{
                    backgroundColor:
                      locate === "/dashboard/drivers"
                        ? theme.button.active
                        : "",
                    color:
                      locate === "/dashboard/drivers"
                        ? theme.button.activetext
                        : theme.button.text,
                    fontSize: "18px",
                    display: "flex",
                    textAlign: "left",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: theme.button.active,
                      color: "#000",
                    },
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography>Drivers</Typography>
                </ListItemButton>
              </Link>
            )}
            {/* Reports */}
            <Link
              to="/dashboard/reports"
              underline="none"
              style={{ width: "100%" }}
              onClick={() => {
                setOpenBookings(false);
              }}
            >
              <ListItemButton
                sx={{
                  backgroundColor:
                    locate === "/dashboard/reports" ? theme.button.active : "",
                  color:
                    locate === "/dashboard/reports"
                      ? theme.button.activetext
                      : theme.button.text,
                  fontSize: "18px",
                  display: "flex",
                  textAlign: "left",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: theme.button.active,
                    color: "#000",
                  },
                }}
              >
                {/* <ListItemText primary="Dashboard" /> */}
                <Typography>Reports</Typography>
              </ListItemButton>
            </Link>
            {/* Users */}
            {getRoles() === "admin" && (
              <Link
                to="/dashboard/users"
                underline="none"
                style={{ width: "100%" }}
                onClick={() => {
                  setOpenBookings(false);
                }}
              >
                <ListItemButton
                  sx={{
                    backgroundColor:
                      locate === "/dashboard/users" ? theme.button.active : "",
                    color:
                      locate === "/dashboard/users"
                        ? theme.button.activetext
                        : theme.button.text,
                    fontSize: "18px",
                    display: "flex",
                    textAlign: "left",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: theme.button.active,
                      color: "#000",
                    },
                  }}
                >
                  {/* <ListItemText primary="Dashboard" /> */}
                  <Typography>Users</Typography>
                </ListItemButton>
              </Link>
            )}
          </List>
        </Box>
      )}

      <Button
        onClick={handleSidebar}
        sx={{
          position: "absolute",
          backgroundColor: "#009fd0",
          top: "3%",
          right: showSidebar ? "-10%" : "-100%",
          display: { xs: "none", sm: "block" },
          padding: "10px 0px",
          minWidth: "20px",
          "&:hover": {
            backgroundColor: "#00b3ea",
          },
        }}
      >
        {showSidebar ? (
          <ArrowCircleLeftIcon sx={{ fontSize: "30px" }} />
        ) : (
          <ArrowCircleRightIcon sx={{ fontSize: "30px" }} />
        )}
      </Button>
    </Box>
  );
};

export default Sidebar;

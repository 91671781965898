import React, { useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import ThemeContext from "../../context/themeContext";

const Grids = ({ columns, rows = [], gridToolbar, searchQuery = "" }) => {
  const { theme } = useContext(ThemeContext);
  const filteredRows = rows.filter((row) => {
    return Object.values(row).some((value) => {
      return value
        ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        : false;
    });
  });
  return (
    <Box
      sx={{
        minHeight: { sm: "75vh", xs: "30vh" },
        "& .super-app-theme--header": {
          backgroundColor: `${theme.button.active}`,
        },
        width: { xs: "100%", md: "80vw" },
        margin: "20px 5px",
        textTransform: "capitalize",
        overflow: "auto",
      }}
    >
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={10}
        pageSizeOptions={[10, 100]}
        slots={{
          toolbar: gridToolbar ? GridToolbar : null,
        }}
        sx={{
          boxShadow: 2,
          // border: 2,
          // borderColor: "none",
          "& .MuiDataGrid-cell:hover": {
            color: `${theme.text.secondary}`,
          },
          "& .MuiDataGrid-cell": {
            color: `${theme.text.secondary}`,
            textAlign: "center",
            whiteSpace: "nowrap",
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#4a90e2",
            // width: "100%", // Fill the width of the table
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center", // Center text in headers
            width: "100%", // Fill the width of the table
            whiteSpace: "nowrap",
          },
          "& .MuiDataGrid-row": {
            "&:nth-of-type(even)": {
              backgroundColor: `${theme.button.secondary}`, // Optional: alternate row color
            },
            "&:nth-of-type(odd)": {
              backgroundColor: `${theme.button.primary}`, // Optional: alternate row color
            },
          },
          "& .MuiDataGrid-footerContainer": {
            color: `${theme.button.active}`,
            "& .MuiTablePagination-root": {
              color: `${theme.button.active}`, // Change pagination color to red
            },
          },
        }}
        autoHeight
      />
    </Box>
  );
};

export default Grids;

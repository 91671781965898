import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const Customerpdf = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* Button to open the modal */}
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Open Consignment Form
      </Button>

      {/* Modal containing the form */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Consignment Form
          </Typography>

          {/* Form Grid */}
          <Grid container spacing={2}>
            {/* Shipper's Details */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Shipper's Details</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Name" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Phone Number" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Address" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Postal Code" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="GST No." variant="outlined" />
            </Grid>

            {/* Recipient's Details */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Recipient's Details</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Name" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Phone Number" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField fullWidth label="Address" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Postal Code" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="GST No." variant="outlined" />
            </Grid>

            {/* Package Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Package Information</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Invoice No." variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Invoice Date"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Invoice Value" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Box Dimensions (cm)"
                variant="outlined"
              />
            </Grid>

            {/* Additional Options */}
            <Grid item xs={12} sm={4}>
              <FormControlLabel control={<Checkbox />} label="Fragile" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel control={<Checkbox />} label="Owner's Risk" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel control={<Checkbox />} label="Carrier's Risk" />
            </Grid>

            {/* Payment Information */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Payment Information</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="Payment Type" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="COD Amount" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField fullWidth label="E-way Bill No." variant="outlined" />
            </Grid>

            {/* Charges Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1">Charges</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Base Freight" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField fullWidth label="Docket Charge" variant="outlined" />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" fullWidth>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default Customerpdf;

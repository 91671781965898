import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ThemeContext from "../../../context/themeContext";
import AddPlanner from "./addPlanner";
import { getRoles } from "../../../context/RequireAuth";
import AuthContext from "../../../context/authContext";
import AddTrips from "../Trips/addTrips";
const Planner = () => {
  const { theme } = useContext(ThemeContext);
  const [OpenAddPlanner, setOpenAddPlanner] = useState(false);
  const [OpenAddTrip, setOpenAddTrip] = useState(false);
  const { userData, setRefresh } = useContext(AuthContext);
  const handleCloseAddPlanner = () => {
    setOpenAddPlanner(false);
  };
  const handleOpenAddPlanner = () => {
    setOpenAddPlanner(true);
  };

  const handleCloseAddTrip = () => {
    setOpenAddTrip(false);
  };

  const handleOpenAddTrip = () => {
    setOpenAddTrip(true);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box>
        <Typography
          sx={{
            fontSize: "20px",
            color: theme.text.primary,
            textAlign: "center",
          }}
        >
          Planner
        </Typography>
      </Box>
      {getRoles() !== "user" && (
        <Button
          onClick={handleOpenAddPlanner}
          sx={{
            color: "#fff",
            backgroundColor: theme.button.active,
            "&:hover": {
              backgroundColor: theme.button.primary,
              color: theme.text.primary,
            },
          }}
        >
          Add Planner
        </Button>
      )}
      <Box sx={{ padding: "20px" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            padding: "20px",
            // justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          {userData?.planner?.map((item) => (
            <Box
              key={item?._id}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "300px", sm: "max-content" },
                gap: "10px",
                padding: "20px 10px",
                borderRadius: "10px",
                justifyContent: "space-between",
                border: `2px solid ${theme.text.primary}`,
              }}
            >
              <Box
                sx={{
                  fontWeight: "bold",
                  color: theme.text.primary,
                  fontSize: "40px",
                }}
              >
                {item?.plannerName}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {/* Trip Status */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{ color: theme.text.secondary, fontSize: "20px" }}
                  >
                    Trips
                  </Typography>
                  {/* <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "space-between",
                      color: theme.text.primary,
                    }}
                  > */}
                  <Box>
                    <Typography sx={{ color: theme.text.primary }}>
                      Completed
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: theme.text.secondary,
                        fontWeight: "bolder",
                      }}
                    >
                      {userData?.trips?.filter(
                        (trip) =>
                          trip?.planner_id === item?._id &&
                          trip?.status === "Completed"
                      ).length || 0}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ color: theme.text.primary }}>
                      Active
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: theme.text.secondary,
                        fontWeight: "bolder",
                      }}
                    >
                      {userData?.trips?.filter(
                        (trip) =>
                          trip?.planner_id === item?._id &&
                          trip?.status !== "erased" &&
                          trip?.status !== "Completed"
                      ).length || 0}
                    </Typography>
                  </Box>
                </Box>
                {/* Buttons */}
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                    backgroundColor: "#3D3D3D",
                  }}
                >
                  <Button sx={{ color: "red" }}>Delete</Button>
                  <Button onClick={handleOpenAddTrip} sx={{ color: "yellow" }}>
                    Create Trip
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <AddPlanner
        OpenAddPlanner={OpenAddPlanner}
        handleCloseAddPlanner={handleCloseAddPlanner}
        // editData
        // editMode
      />
      <AddTrips
        OpenAddTrip={OpenAddTrip}
        handleCloseAddTrip={handleCloseAddTrip}
      />
    </Box>
  );
};

export default Planner;

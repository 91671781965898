import React, { useContext, useEffect, useState } from "react";
import AddUsers from "./addUsers";
import { Box, Button, Table, Typography } from "@mui/material";
import ThemeContext from "../../context/themeContext";
import AuthContext from "../../context/authContext";
import Buttons from "../../components/misc/button";
import Grid from "../../components/misc/grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import toast from "react-hot-toast";
import axiosInstance from "../../api/axios";

const Users = () => {
  const [OpenAddUsers, setOpenAddUser] = useState(false);
  const { userData, setRefresh } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [userTableData, setUserTableData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});

  const handleOpenAddUser = () => {
    setOpenAddUser(true);
  };

  const handleCloseAddusers = () => {
    setEditMode(false);
    setEditData({});
    setOpenAddUser(false);
    setRefresh(true);
  };

  const handleDelete = async (id) => {
    const delUser = await axiosInstance.delete(`branches/deleteuser/${id}`);
    if (delUser.status === 200) {
      toast.success("User Deleted Successfully");
      setRefresh(true);
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleEdit = (id) => {
    setOpenAddUser(true);
    const filtrd = userTableData.filter((item) => item._id === id);
    setEditMode(true);
    setEditData(filtrd[0]);
  };

  // const handleViewUser = (id) => {
  //   console.log("view" + id);
  // };

  useEffect(() => {
    if (userData) {
      setUserTableData(
        userData.users?.filter((item) => item.status !== "erased")
      );
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      width: 90,
    },
    {
      field: "empId",
      headerName: "Emp ID",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "username",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "emprole",
      headerName: "Emp Role",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "branch",
      headerName: "Branch",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Phone",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "approvals",
      headerName: "Approvals",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      // width: 200,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          {/* <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleViewUser(params.row._id)}
          >
            <VisibilityIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button> */}
          <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleEdit(params.row._id)}
          >
            <EditIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button>
          <Button
            sx={{
              padding: "5px",
              minWidth: "auto",
              color: `${theme.text.secondary}`,
              "&:hover": {
                backgroundColor: "#2E3867",
                color: "#fff",
              },
            }}
            onClick={() => handleDelete(params.row._id)}
          >
            <DeleteForeverIcon sx={{ fontSize: "25px", padding: "0px" }} />
          </Button>
        </Box>
      ),
    },
  ];

  const rows = userTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    username: item.username,
    email: item.email,
    status: item.status,
    contact: item.contact,
    approvals: item.approvals,
    emprole: item.emprole,
    role: item.role,
    empId: item.empId,
    branch: item.branch || "Unassigned",
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Users
      </Typography>
      <Buttons text={"Add User"} onclicks={handleOpenAddUser} />
      <Box>
        <Grid columns={columns} rows={rows} />
      </Box>
      <AddUsers
        OpenAddUsers={OpenAddUsers}
        handleCloseAddusers={handleCloseAddusers}
        editData={editData}
        editMode={editMode}
      />
    </Box>
  );
};

export default Users;

import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SelectField from "../components/misc/selectfield";
import PickupIcon from "../assets/icons/Pickups.png";
import ThemeContext from "../context/themeContext";
import AuthContext from "../context/authContext";
import ftlIcon from "../assets/icons/ftl.png";
import deliveryIcon from "../assets/icons/delivery.png";
import tripIcon from "../assets/icons/trip.png";
import consignIcon from "../assets/icons/consignment.png";
import earningIcon from "../assets/icons/earning.png";
import weightIcon from "../assets/icons/weight.png";
import { authTokens } from "../api/axios";

const Dashboard = () => {
  const [status, setStatus] = useState("Completed");
  const [totalFilter, setTotalFilter] = useState("Total");
  const { theme } = useContext(ThemeContext);
  const { userData, setRefresh } = useContext(AuthContext);
  const branchId = localStorage.getItem("currentbranch");
  const [cardData, setCardData] = useState({
    pickup: 0,
    delivery: 0,
    ftl: 0,
    consignment: 0,
    trip: 0,
    avg_earnings: 0,
    avg_weights: 0,
  });

  useEffect(() => {
    const currentMonth = new Date().toISOString().split("T")[0].split("-")[1];

    const updateCardData = () => {
      if (userData) {
        // Filter data based on status and totalFilter
        const filteredPickups = userData?.pickups
          ?.filter(
            (pic) =>
              pic.pickupStatus ===
              (status === "Completed" ? "Delivered" : "Pending")
          )
          .filter(
            (el) =>
              totalFilter === "Total" ||
              el.createdAt.split("-")[1] === currentMonth
          );

        const filteredDeliveries = userData?.consignments
          ?.filter((del) => {
            if (status === "Completed") {
              return (
                del.consignmentStatus === "Delivered" &&
                del.deliverybranch === branchId
              );
            } else if (status === "In-transit" || status === "Active") {
              return (
                del.consignmentStatus === "Delivered to Branch" &&
                del.deliverybranch === branchId
              );
            } else {
              return (
                del.status === "Ready for Loading" ||
                del.status === "Processing"
              );
            }
          })
          .filter(
            (el) =>
              totalFilter === "Total" ||
              el.createdAt.split("-")[1] === currentMonth
          );

        const filteredFTL = userData?.ftl
          ?.filter((ftl) => {
            if (status === "Completed") {
              return ftl.status === "Completed";
            } else if (status === "In-transit") {
              return ftl.status === "In-transit";
            } else {
              return (
                ftl.status === "Ready for Loading" ||
                ftl.status === "Processing"
              );
            }
          })
          .filter(
            (el) =>
              totalFilter === "Total" ||
              el.createdAt.split("-")[1] === currentMonth
          );

        const filteredConsignments = userData?.consignments
          ?.filter((con) => {
            if (status === "Completed") {
              return (
                con.consignmentStatus === "Delivered" &&
                con.deliverybranch !== branchId
              );
            } else if (status === "In-transit") {
              return (
                con.consignmentStatus === "In Transit" &&
                con.deliverybranch !== branchId
              );
            } else {
              return (
                (con.consignmentStatus === "Ready for Shipping" ||
                  con.consignmentStatus === "Processing") &&
                con.deliverybranch !== branchId
              );
            }
          })
          .filter((el) => {
            if (totalFilter === "Monthly") {
              return el.docketdate?.split("-")[1] === currentMonth;
            }
            return true; // When totalFilter is "Total", return all records
          });

        const filteredTrips = userData?.trips
          ?.filter((trip) => {
            if (status === "Completed") {
              return trip.status === "Completed";
            } else if (status === "In-transit") {
              return trip.status === "In-transit";
            } else {
              return (
                trip.status === "Active" ||
                trip.status === "Processing" ||
                trip.status === "Ready for Loading"
              );
            }
          })
          .filter(
            (el) =>
              totalFilter === "Total" ||
              el.createdAt.split("-")[1] === currentMonth
          );

        // Set card data based on the filtered data and totalFilter
        setCardData({
          pickup: filteredPickups?.length || 0,
          delivery: filteredDeliveries?.length || 0,
          ftl: filteredFTL?.length || 0,
          consignment: filteredConsignments?.length || 0,
          trip: filteredTrips?.length || 0,
          avg_earnings:
            filteredConsignments?.reduce(
              (acc, curr) => acc + (curr.totalcharges || 0),
              0
            ) || 0,
          avg_weights:
            filteredConsignments?.reduce(
              (acc, curr) => acc + (curr.actualWeight || 0),
              0
            ) || 0,
        });
      }
    };

    updateCardData();
  }, [status, totalFilter, userData]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleTotalFilterChange = (e) => {
    setTotalFilter(e.target.value);
  };

  const Card = ({ text, value, icon }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 20px",
          alignItems: "center",
          boxShadow: `4px 4px 6px rgba(0, 159, 207, 0.5)`,
          width: "250px",
          backgroundColor: `rgba(0, 159, 207, 0.2)`,
          height: "120px",
          borderRadius: "10px",
          gap: "20px",
          position: "relative",
          color: theme.text.primary,
        }}
      >
        <Box sx={{ filter: "drop-shadow(4px 8px 6px rgba(0, 0, 0, 0.6))" }}>
          <img
            src={icon}
            alt={`${text} icon`}
            style={{ width: "75px", opacity: 0.75 }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            right: "50%",
            transform: "translate(80%, -50%)",
          }}
        >
          <Typography
            sx={{
              fontSize: "28px",
              width: "100%",
              color: `rgb(0, 159, 207)`,
              fontWeight: "bolder",
              textAlign: "center",
            }}
          >
            {text}
          </Typography>
          <Typography
            sx={{
              fontSize: "28px",
              width: "100%",
              color: theme.text.secondary,
              textAlign: "center",
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <SelectField
          value={status}
          onChange={handleStatusChange}
          name="status"
          type="text"
          items={["Completed", "Active", "In-transit"]}
          sx={{ width: "200px" }}
          variant="outlined"
          color={theme.text.primary}
        />
        <SelectField
          value={totalFilter}
          onChange={handleTotalFilterChange}
          name="totalFilter"
          type="text"
          items={["Total", "Monthly"]}
          sx={{ width: "200px" }}
          variant="outlined"
          color={theme.text.primary}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexWrap: "wrap",
          mt: 3,
          ml: 2,
        }}
      >
        <Card text="Pickups" value={cardData.pickup} icon={PickupIcon} />
        <Card
          text="Consignment"
          value={cardData.consignment}
          icon={consignIcon}
        />
        <Card text="Trips" value={cardData.trip} icon={tripIcon} />
        <Card text="FTL" value={cardData.ftl} icon={ftlIcon} />
        <Card text="Deliveries" value={cardData.delivery} icon={deliveryIcon} />
        {/* <Card
          text="Earnings"
          value={cardData.avg_earnings}
          icon={earningIcon}
        /> */}
        <Card text="Weight" value={cardData.avg_weights} icon={weightIcon} />
      </Box>
    </Box>
  );
};

export default Dashboard;

import { Box, Modal, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import Button from "../misc/button";
// import ThemeContext from "../../context/themeContext";
import AuthContext from "../../context/authContext";

const style = {
  position: "absolute",
  top: "20%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TripHistory = ({ openTripHistory, handleCloseTripHistory }) => {
  const [showHistory, setShowHistory] = useState(false);
  const { trips } = useContext(AuthContext);
  //   const { theme } = useContext(ThemeContext);
  //   const [tripdata, setTripData] = useState([]);
  const [tripId, setTripId] = useState("");

  const handleFetchTripHistory = () => {
    console.log(tripId);
    const filteredTrips = trips.filter((trip) => trip._id === tripId);
    console.log(filteredTrips);
    setShowHistory(true);
  };
  const handleCloseTrip = () => {
    setShowHistory(false);
    handleCloseTripHistory();
  };

  return (
    <Box>
      <Modal
        open={openTripHistory}
        onClose={handleCloseTrip}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <TextField
              id="outlined-basic"
              label="Trip ID"
              variant="outlined"
              value={tripId}
              onChange={(e) => setTripId(e.target.value)}
              sx={{ width: "200px" }}
            />
            <Button text="Search" onclicks={handleFetchTripHistory} />
          </Box>
          {showHistory && <Box>test</Box>}
        </Box>
      </Modal>
    </Box>
  );
};

export default TripHistory;

import React, { useContext, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import ThemeContext from "../../context/themeContext";
import TripHistory from "../../components/modals/tripHistory";
const Reports = () => {
  const { theme } = useContext(ThemeContext);
  const [openTripHistory, setOpenTripHistory] = useState(false);
  const handleCloseTripHistory = () => setOpenTripHistory(false);
  const handleTripHistory = () => {
    setOpenTripHistory(true);
  };
  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "24px",
          color: theme.text.primary,
        }}
      >
        Reports
      </Typography>
      <Box sx={{ display: "flex", margin: "20px" }}>
        <Button
          onClick={handleTripHistory}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.background.button,
            display: "flex",
            width: "200px",
            padding: "10px 20px",
            borderRadius: "15px",
            "&:hover": {
              backgroundColor: theme.background.button,
            },
          }}
        >
          <Typography sx={{ fontSize: "16px", color: theme.text.secondary }}>
            Trip History
          </Typography>
        </Button>
      </Box>

      <TripHistory
        openTripHistory={openTripHistory}
        handleCloseTripHistory={handleCloseTripHistory}
      />
    </Box>
  );
};

export default Reports;

import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./login";
import DashLayout from "./dashlayout";
import Dashboard from "./Dashboard/dashboard";
import Consignment from "./Dashboard/bookings/Consignments/consignment";
import Planner from "./Dashboard/bookings/Planner/planner";
import Trips from "./Dashboard/bookings/Trips/trips";
import Deliveries from "./Dashboard/deliveries";
import AddConsignment from "./Dashboard/bookings/Consignments/addConsignment";
import Pickups from "./Dashboard/bookings/Pickups/pickups";
// import AddDrivers from "./Dashboard/drivers/adddrivers";
// import Drivers from "./Dashboard/drivers/drivers";
// import Vehicles from "./Dashboard/vehicles/vehicles";
// import AddVehicles from "./Dashboard/vehicles/addVehicles";
import Reports from "./Dashboard/reports/reports";
import Branches from "./Dashboard/Branches/branches";
import AddBranches from "./Dashboard/Branches/addBranches";
import Users from "./Dashboard/users/users";
import AddUsers from "./Dashboard/users/addUsers";
import Pincodes from "./Dashboard/pincodes/pincodes";
import AddPincodes from "./Dashboard/pincodes/addPincode";
import RequireAuth from "./context/RequireAuth";
import LoadCons from "./Dashboard/loadCons";
import Tracking from "./tracking";
import FTL from "./Dashboard/bookings/ftl/ftl";
import UpcomingTrips from "./Dashboard/upcomingtrips";
import Account from "./Dashboard/account";
import LoadFtl from "./Dashboard/loadftl";
import Settings from "./Dashboard/settings";
import LRpdf from "./Dashboard/LRpdf";
import Customerpdf from "./Dashboard/Customerpdf";
import Home from "./home";
import HomePage from "./test";
import { AuthProvider } from "./context/authContext";

const App = () => {
  return (
    <>
      <Routes>
        {/* <Route index element={<Login />} /> */}
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="tracking" element={<Tracking />} />
        <Route path="homepage" element={<HomePage />} />

        <Route path="lrpdf" element={<LRpdf />} />
        <Route path="custpdf" element={<Customerpdf />} />
        <Route
          element={<RequireAuth allowedRoles={["branch", "user", "manager"]} />}
        >
          <Route
            path="dashboard"
            element={
              <AuthProvider>
                <DashLayout />
              </AuthProvider>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="account" element={<Account />} />
            <Route path="settings" element={<Settings />} />
            <Route path="bookings/consignment" element={<Consignment />} />
            <Route
              path="bookings/consignment/add"
              element={<AddConsignment />}
            />
            <Route
              path="bookings/consignment/edit/:id"
              element={<AddConsignment />}
            />
            <Route
              path="bookings/consignment/view/:id"
              element={<AddConsignment />}
            />

            <Route path="bookings/planner" element={<Planner />} />
            <Route path="bookings/planner/add" element={<AddConsignment />} />
            <Route path="bookings/trips" element={<Trips />} />
            <Route path="bookings/trips/add" element={<Trips />} />
            <Route path="bookings/trips/edit/:id" element={<Trips />} />
            <Route path="bookings/ftl" element={<FTL />} />
            <Route path="bookings/pickup" element={<Pickups />} />
            <Route path="bookings/pickup/add" element={<Pickups />} />
            <Route
              path="bookings/pickup/consignment/:id/:pickupid"
              element={<AddConsignment />}
            />
            <Route path="deliveries" element={<Deliveries />} />
            {/* <Route path="vehicles" element={<Vehicles />} /> */}
            {/* <Route path="vehicles/add" element={<AddVehicles />} /> */}
            {/* <Route path="vehicles/edit/:id" element={<AddVehicles />} /> */}
            {/* <Route path="drivers" element={<Drivers />} /> */}
            {/* <Route path="drivers/add" element={<AddDrivers />} /> */}
            <Route path="reports" element={<Reports />} />
            <Route path="branches" element={<Branches />} />
            <Route path="branches/add" element={<AddBranches />} />
            <Route path="branches/edit/:id" element={<AddBranches />} />
            <Route path="users" element={<Users />} />
            <Route path="upcomingtrips" element={<UpcomingTrips />} />
            <Route path="users/add" element={<AddUsers />} />
            <Route path="pincodes" element={<Pincodes />} />
            <Route path="pincodes/add" element={<AddPincodes />} />
            <Route path="pincodes/edit/:id" element={<AddPincodes />} />
            <Route path="loadConsignments/:id" element={<LoadCons />} />
            <Route path="loadftl/:id" element={<LoadFtl />} />
          </Route>{" "}
        </Route>
      </Routes>
      <Toaster position="top-right" />
    </>
  );
};

export default App;

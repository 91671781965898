import { Navigate, Outlet, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const RequireAuth = ({ allowedRoles }) => {
  let token = localStorage.getItem("authTokens");
  const location = useLocation();

  if (token) {
    const decodedToken = jwtDecode(token);

    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      return <Navigate to="/" state={{ from: location }} replace />;
    } else {
      return allowedRoles.find((roles) =>
        decodedToken?.role?.includes(roles)
      ) ? (
        <Outlet />
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      );
    }
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default RequireAuth;

export const getRoles = () => {
  let token = localStorage.getItem("authTokens");

  if (token) {
    const decodedToken = jwtDecode(token);
    const roles = decodedToken.role;
    return roles;
  }

  // Return a default value if no token is found
  return [];
};
export const branchId = () => {
  let token = localStorage.getItem("authTokens");

  if (token) {
    const decodedToken = jwtDecode(token);
    const branchIds = decodedToken.branchId;
    return branchIds;
  } else {
    return [];
  }
  // Return a default value if no token is found
};

import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const ValuedSelectField = ({
  label,
  value,
  onChange,
  name,
  color,
  items,
  sx,
  multiple = false,
}) => {
  return (
    <FormControl
      margin="normal"
      sx={{
        ...sx,
        "& .MuiInputLabel-root": { color: color }, // Label color
        "& .MuiInput-underline:before": {
          borderBottomColor: color,
        }, // Underline color before focus
        "& .MuiInput-underline:after": {
          borderBottomColor: color,
        }, // Underline color after focus
        "& .MuiInputBase-input": { color: color }, // Input text color
        "&:after": {
          borderBottomColor: color,
        },
        "& .MuiSvgIcon-root": {
          color: color,
        },
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        name={name}
        variant="standard"
        size="small"
      >
        {items?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ValuedSelectField;

import React from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Container,
} from "@mui/material";

const HomePage = () => {
  return (
    <Container maxWidth="lg">
      {/* Header Section */}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2 }}
      >
        <Typography variant="h6">UNICEIL</Typography>
        <Box>
          <Typography variant="body1">Toll Free No: 1800-843-4500</Typography>
          <Typography variant="body1">
            Email: enquiry@uniceilmaxx.com
          </Typography>
        </Box>
      </Grid>

      {/* Hero Section */}
      <Grid container spacing={2} sx={{ py: 4 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Transform Your Space with Uniceil’s Wall, Ceiling, Facade
          </Typography>
          <Typography variant="body1">
            Residential | Offices | Malls | Airports | Institutions
          </Typography>
        </Grid>

        {/* Quote Form */}
        <Grid item xs={12} md={6}>
          <Box sx={{ backgroundColor: "#e0f7fa", p: 3, borderRadius: 2 }}>
            <Typography variant="h6">Request a Personalized Quote</Typography>
            <form>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="City"
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Project Type"
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <Button variant="contained" color="primary" fullWidth>
                Get a FREE estimate today!
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>

      {/* Products Section */}
      <Typography variant="h5" gutterBottom>
        Our Products
      </Typography>
      <Grid container spacing={2} sx={{ py: 4 }}>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "red", height: 200 }}></Box>
          <Typography variant="h6">Baffle</Typography>
          <Typography variant="body2">
            A series of compartmentalized ceiling panels...
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "red", height: 200 }}></Box>
          <Typography variant="h6">Soffit</Typography>
          <Typography variant="body2">Ventilation and design...</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "red", height: 200 }}></Box>
          <Typography variant="h6">Facade</Typography>
          <Typography variant="body2">
            Exterior facing with great durability...
          </Typography>
        </Grid>
      </Grid>

      {/* Applications Section */}
      <Typography variant="h5" gutterBottom>
        Applications
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "red", height: 200 }}></Box>
          <Typography variant="h6">Wall</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "red", height: 200 }}></Box>
          <Typography variant="h6">Ceiling</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ backgroundColor: "red", height: 200 }}></Box>
          <Typography variant="h6">Facade</Typography>
        </Grid>
      </Grid>

      {/* Features Section */}
      <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
        How We’re Setting The Gold Standard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Energy Efficiency</Typography>
          <Typography variant="body2">Improves air circulation...</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Unique Features</Typography>
          <Typography variant="body2">
            Packed with modern technologies...
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Durability</Typography>
          <Typography variant="body2">
            Low-maintenance and long-lasting...
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="h6">Multiple Options</Typography>
          <Typography variant="body2">
            Various lighting and design options...
          </Typography>
        </Grid>
      </Grid>

      {/* Contact Section */}
      <Box
        sx={{ mt: 4, py: 2, backgroundColor: "#f5f5f5", textAlign: "center" }}
      >
        <Typography variant="body1">Contact Us: SF No 203/8 B & C</Typography>
        <Typography variant="body1">
          Selakkaradu Road, Appanaickenpalayam, Coimbatore - 641402
        </Typography>
        <Typography variant="body1">
          Email: enquiry@uniceilmaxx.com | Toll Free: 1800-843-4500
        </Typography>
      </Box>
    </Container>
  );
};

export default HomePage;

import React, { createContext, useState } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  const lightTheme = {
    primary: {
      main: "#009fd0",
    },
    secondary: {
      main: "#00b3ea",
    },
    background: {
      default: "#f5f5f5",
      button: "#dfdfdf",
      activebutton: "#009fd0",
    },
    text: {
      primary: "#000000",
      secondary: "#000",
    },
    button: {
      primary: "#f5f5f5",
      active: "#009fd0",
      hover: "#009fd0",
      text: "#000",
      activetext: "#000",
    },
    sidebar: {
      boxShadow: "0 4px 30px rgba(0, 0, 0, 0.15)",
    },
    typography: {
      fontSize: 14,
      h6: {
        fontSize: 18,
      },
    },
  };

  const darkTheme = {
    primary: {
      main: "#009fd0",
    },
    secondary: {
      main: "#00b3ea",
    },
    background: {
      default: "#161616",
      button: "#3d3d3d",
      activebutton: "#009fd0",
    },
    text: {
      primary: "#009fd0",
      secondary: "#fff",
    },
    button: {
      primary: "#3d3d3d",
      active: "#00b3ea",
      hover: "#00b3ea",
      text: "#009fd0",
      activetext: "#000",
    },
    sidebar: {
      boxShadow: "0 4px 30px rgba(255, 255, 255, 0.15)",
    },
    typography: {
      fontSize: 14,
      h6: {
        fontSize: 18,
      },
    },
  };
  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider
      value={{
        handleThemeChange,
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

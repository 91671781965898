// src/components/navbar/navbar.js
import React, { useContext } from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import { Dropdown, MenuItem, Menu, MenuButton } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";
import yellow from "../../assets/images/yellow.jpg";
import Toggle from "../toggle/toggle";
import ThemeContext from "../../context/themeContext";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import AuthContext from "../../context/authContext";
const Navbar = ({ handleThemeChange, handleOpenSidebar }) => {
  const { theme } = useContext(ThemeContext);
  const { userData } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <Box
      sx={{
        backdropFilter: "blur(10px)",
        height: { xs: "15vh", sm: "8vh" },
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid rgba(255, 255, 255, 0.18)",
        boxShadow: theme.sidebar.boxShadow,
        flexWrap: { xs: "wrap", sm: "nowrap" },
        zIndex: 2,
      }}
    >
      <Box
        sx={{ display: "flex", alignItems: "center" }}
        onClick={() => navigate("/dashboard")}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: "30px",
            ml: 3,
            mt: { xs: "10px", sm: "none" },
            whiteSpace: "nowrap",
          }}
        >
          OM VAIRAVA LOGISTICS
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          justifyContent: "space-between",
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Button
          sx={{ display: { xs: "flex", sm: "none" } }}
          onClick={handleOpenSidebar}
        >
          <MenuOpenIcon sx={{ color: theme.text.primary, fontSize: "50px" }} />
        </Button>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            <Toggle handleThemeChange={handleThemeChange} />
          </Box>
          <Box>
            <Typography sx={{ color: theme.text.primary, fontSize: "16px" }}>
              {userData?.branchName}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Dropdown>
              <MenuButton
                sx={{
                  border: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Avatar size="sm" variant="soft" src={yellow} />
              </MenuButton>
              <Menu sx={{ backgroundColor: "#01B2EA" }}>
                <Box
                  sx={{
                    display: { xs: "flex", sm: "none" },
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Toggle handleThemeChange={handleThemeChange} />
                </Box>
                <Link to="/dashboard/account">
                  <MenuItem sx={{ color: "#fff" }}>Accounts</MenuItem>
                </Link>
                <Link to="/dashboard/settings">
                  <MenuItem sx={{ color: "#fff" }}>Settings</MenuItem>
                </Link>
                <Link onClick={handleLogout}>
                  <MenuItem sx={{ color: "#fff" }}>Logout</MenuItem>
                </Link>
              </Menu>
            </Dropdown>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;

import React, { useState, useContext, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import loginpic from "./assets/images/login.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { baseURL } from "./api/axios";
import logo from "./assets/images/logo.png";

const LOGIN_Url = "/login";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const token = localStorage.getItem("authTokens");
  //   const companyId = localStorage.getItem("companyId");
  //   if (token && companyId) {
  //     navigate("/dashboard");
  //   } else {
  //     navigate("/login");
  //   }
  // }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(baseURL + LOGIN_Url, {
        branchId: username,
        password,
      });
      if (response.status === 200) {
        const {
          authTokens,
          branchId,
          currentbranch,
          branchCode,
          companyId,
          branchName,
        } = response.data;
        localStorage.setItem("authTokens", authTokens);
        localStorage.setItem("branchId", branchId);
        localStorage.setItem("currentbranch", currentbranch);
        localStorage.setItem("branchCode", branchCode);
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("branchName", branchName);
        setTimeout(() => {
          toast.success("Login successful");
          navigate("/dashboard");
        }, 500);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("Login failed");
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${loginpic})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        "::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          minHeight: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.75)", // Change opacity here
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#fff",
          textAlign: "center",
        }}
      >
        <img src={logo} alt="Logo" style={{ width: "200px" }} />
        <Typography
          sx={{
            fontSize: { xs: "30px", sm: "50px" },
            fontWeight: 400,
            mb: "20px",
            color: "#009FCF",
            whiteSpace: "nowrap",
          }}
        >
          OM VAIRAVA LOGISTICS
        </Typography>
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            backdropFilter: "blur(10px)",
            borderRadius: "20px",
            p: 4,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "60px",
            width: "260px",
          }}
        >
          <Typography sx={{ fontSize: "30px", fontWeight: 300 }}>
            Login
          </Typography>
          <TextField
            id="netname"
            variant="standard"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{
              input: { color: "#fff" },
              "& .MuiInput-underline:before": { borderBottomColor: "#fff" },
              "& .MuiInput-underline:hover:before": {
                borderBottomColor: "#fff",
              },
              "& .MuiInput-underline:after": { borderBottomColor: "#fff" },
            }}
            placeholder="Username"
            InputProps={{
              style: { color: "#fff" },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
          <TextField
            id="netpass"
            variant="standard"
            fullWidth
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              input: { color: "#fff" },
              "& .MuiInput-underline:before": { borderBottomColor: "#fff" },
              "& .MuiInput-underline:hover:before": {
                borderBottomColor: "#fff",
              },
              "& .MuiInput-underline:after": { borderBottomColor: "#fff" },
            }}
            placeholder="Password"
            InputProps={{
              style: { color: "#fff" },
            }}
            InputLabelProps={{
              style: { color: "#fff" },
            }}
          />
          <Button
            onClick={handleSubmit}
            sx={{
              width: "100%",
              backgroundColor: "#009FCF", // Contrast color to blue
              color: "#fff",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#0095bf", // Darker shade of orange for hover effect
              },
            }}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;

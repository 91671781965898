import { Box, Modal, Typography, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../../../context/themeContext";
import AuthContext from "../../../context/authContext";
import TextFields from "../../../components/misc/textfield";
import axiosInstance from "../../../api/axios";
import { toast } from "react-hot-toast";

const AddFTL = ({ OpenAddFTL, handleCloseAddFTL, editData, editMode }) => {
  const { theme } = useContext(ThemeContext);
  const { userData, setRefresh, branches } = useContext(AuthContext);

  const [ftl, setFTL] = useState({
    manifest_date: new Date().toISOString().split("T")[0],
    vehicleno: "",
    vehicletype: "",
    vehicleowner: "",
    vehiclePhone: 0,
    driverName: "",
    driverPhone: 0,
    driverlicenseno: "",
    deliverycity: "",
    deliveryaddress: "",
    notes: "",
  });

  const handleChangeTrip = (e) => {
    const { name, value } = e.target;
    setFTL((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !ftl.deliverycity ||
      !ftl.deliveryaddress ||
      !ftl.vehicleno ||
      !ftl.vehicletype ||
      !ftl.driverName ||
      !ftl.driverPhone
    ) {
      return toast.error("Please fill all the required fields");
    }

    const newFTL = {
      vehicleNo: ftl.vehicleno,
      vehicleType: ftl.vehicletype,
      vehicleOwner: ftl.vehicleowner,
      vehiclePhone: ftl.vehiclePhone,
      driverName: ftl.driverName,
      driverPhone: ftl.driverPhone,
      driverLicenseNo: ftl.driverlicenseno,
      deliveryCity: ftl.deliverycity,
      deliveryAdd: ftl.deliveryaddress,
      notes: ftl.notes,
      pickupCity: localStorage.getItem("branchName"),
      branch_id: localStorage.getItem("currentbranch"),
      startdate: ftl.manifest_date,
      manifest_date: ftl.manifest_date,
      events: [
        {
          date: new Date(),
          description: "FTL Created",
        },
      ],
    };
    const res = await axiosInstance.post("/ftl/add", newFTL);
    if (res.status === 200) {
      toast.success("FTL Added Successfully");
      setRefresh(true);
      handleCloseAddFTL();
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleUpdate = async () => {
    const newFTL = {
      vehicleNo: ftl.vehicleno,
      vehicleType: ftl.vehicletype,
      vehicleOwner: ftl.vehicleowner,
      vehiclePhone: ftl.vehiclePhone,
      driverName: ftl.driverName,
      driverPhone: ftl.driverPhone,
      driverLicenseNo: ftl.driverlicenseno,
      deliveryCity: ftl.deliverycity,
      deliveryAdd: ftl.deliveryaddress,
      notes: ftl.notes,
      manifest_date: ftl.manifest_date,
    };

    try {
      const res = await axiosInstance.post(`/ftl/update/${editData._id}`, {
        newFTL,
      });
      if (res.status === 200) {
        toast.success("FTL Updated Successfully");
        setRefresh(true);
        handleCloseAddFTL();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (editMode) {
      setFTL({
        ...editData,
        manifest_date: editData?.manifest_date,
        deliverycity: editData?.deliveryCity,
        deliveryaddress: editData?.deliveryAdd,
        notes: editData?.notes,
        vehicleno: editData?.vehicleNo,
        vehicletype: editData?.vehicleType,
        vehicleowner: editData?.vehicleOwner,
        vehiclePhone: editData?.vehiclePhone,
        driverName: editData?.driverName,
        driverPhone: editData?.driverPhone,
        driverlicenseno: editData?.driverLicenseNo,
      });
    } else {
      setFTL({
        manifest_date: new Date().toISOString().split("T")[0],
        vehicleno: "",
        vehicletype: "",
        vehicleowner: "",
        vehiclePhone: 0,
        driverName: "",
        driverPhone: 0,
        driverlicenseno: "",
        deliverycity: "",
        deliveryaddress: "",
        notes: "",
      });
    }
  }, [userData, editMode]);

  return (
    <Modal
      open={OpenAddFTL}
      onClose={handleCloseAddFTL}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "300px", sm: "500px" },
          bgcolor: theme.background.default,
          color: "#000",
          borderRadius: "20px",
          padding: "10px",
          height: "80%",
          overflow: "auto",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: "20px",
            margin: "10px",
            textAlign: "center",
          }}
        >
          Add New Trip
        </Typography>
        <Box
          sx={{
            padding: "20px",
            borderRadius: "10px",
            border: `2px solid ${theme.text.primary}`,
            marginBottom: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              padding: "10px",
              borderRadius: "10px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <TextFields
                label={"Manifest Date"}
                name="manifest_date"
                value={ftl.manifest_date}
                onChange={handleChangeTrip}
                type="date"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
              <TextFields
                label={"Delivery City *"}
                name="deliverycity"
                value={ftl.deliverycity}
                onChange={handleChangeTrip}
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label={"Delivery Address *"}
                name="deliveryaddress"
                value={ftl.deliveryaddress}
                onChange={handleChangeTrip}
                type="text"
                sx={{ width: "100%" }}
                color={theme.text.secondary}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <TextFields
                label="Vehicle No *"
                name="vehicleno"
                value={ftl.vehicleno}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Vehicle Type *"
                name="vehicletype"
                value={ftl.vehicletype}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Owner Name"
                name="vehicleowner"
                value={ftl.vehicleowner}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Owner Phone"
                name="vehiclePhone"
                value={ftl.vehiclePhone}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Driver Name *"
                name="driverName"
                value={ftl.driverName}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Driver Phone *"
                name="driverPhone"
                value={ftl.driverPhone}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="License Number"
                name="driverlicenseno"
                value={ftl.driverlicenseno}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
              <TextFields
                label="Notes"
                name="notes"
                value={ftl.notes}
                onChange={handleChangeTrip}
                sx={{ width: "200px" }}
                color={theme.text.secondary}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",

              width: "100%",
              justifyContent: "center",
            }}
          >
            {editMode ? (
              <Button
                onClick={handleUpdate}
                sx={{
                  color: "#fff",
                  backgroundColor: theme.button.active,

                  "&:hover": {
                    color: "#fff",
                    backgroundColor: theme.button.active,
                  },
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                sx={{
                  color: "#fff",
                  backgroundColor: theme.button.active,

                  "&:hover": {
                    color: "#fff",
                    backgroundColor: theme.button.active,
                  },
                }}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddFTL;

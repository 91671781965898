import React, { useContext, useState, useEffect } from "react";
import ThemeContext from "../../../context/themeContext";
import {
  Box,
  Button,
  Modal,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import axiosInstance from "../../../api/axios";
import { toast } from "react-hot-toast";
import TextFields from "../../../components/misc/textfield";
import AuthContext from "../../../context/authContext";

const AddPlanner = ({
  OpenAddPlanner,
  handleCloseAddPlanner,
  editData,
  editMode,
}) => {
  const { theme } = useContext(ThemeContext);
  const { userData, branches, setRefresh } = useContext(AuthContext);
  const [planner, setPlanner] = useState({
    origin: "",
    plannerName: "",
    destination: "",
    stops: [{ branchId: "" }],
    plannerDetails: "",
  });

  useEffect(() => {
    if (editMode && OpenAddPlanner) {
      setPlanner({
        origin: editData?.origin || "",
        destination: editData?.destination || "",
        stops: editData?.stops || [{ branchId: "" }],
      });
    } else if (OpenAddPlanner && !editMode) {
      setPlanner({
        origin: "",
        destination: "",
        stops: [{ branchId: "" }],
      });
    }
  }, [editData, OpenAddPlanner, editMode]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlanner((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleStopChange = (index, e) => {
    const { value } = e.target;
    const newStops = [...planner.stops];
    newStops[index].branchId = value;
    setPlanner((prevState) => ({
      ...prevState,
      stops: newStops,
    }));
  };

  const handleAddStop = () => {
    const availableBranches = branches.filter(
      (branch) =>
        branch._id !== planner.destination &&
        !planner.stops.some((s) => s.branchId === branch._id)
    );

    if (availableBranches.length > 0) {
      setPlanner((prevState) => ({
        ...prevState,
        stops: [...prevState.stops, { branchId: "" }],
      }));
    } else {
      toast.error("No more branches available to add.");
    }
  };

  const handleSubmit = async () => {
    const filteredStops = planner.stops.filter(
      (stop) => stop.branchId.trim() !== ""
    );
    const newPlanner = {
      plannerName: planner.plannerName,
      plannerDetails: planner.plannerDetails,
      route: {
        origin: planner.origin,
        destination: planner.destination,
        ...(filteredStops.length > 0 && { stops: filteredStops }),
      },
    };

    try {
      const res = await axiosInstance.post("branches/addplanner", newPlanner);

      if (res.status === 200) {
        toast.success("Planner Added Successfully");
        setRefresh(true);
        handleCloseAddPlanner();
      }
    } catch (error) {
      toast.error("Error Adding Planner");
      console.error(error.response.data.message);
    }
  };

  const handleUpdate = async () => {
    const newPlanner = planner;
    try {
      const res = await axiosInstance.put(
        `/branches/editplanner/${editData._id}`,
        newPlanner
      );
      if (res.status === 200) {
        toast.success("Planner Updated Successfully");
        handleCloseAddPlanner();
      }
    } catch (error) {
      toast.error("Error Updating Planner");
      console.error("Error updating Planner:", error);
    }
  };

  return (
    <Modal
      open={OpenAddPlanner}
      onClose={handleCloseAddPlanner}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "300px", sm: "750px" },
          bgcolor: theme.background.default,
          color: "#000",
          borderRadius: "20px",
        }}
      >
        <Typography
          sx={{
            color: theme.text.primary,
            fontSize: "20px",
            margin: "10px",
            textAlign: "center",
          }}
        >
          {editMode ? "Edit Planner" : "Add New Planner"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            margin: "10px",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontWeight: "bold", color: theme.text.primary }}>
            Planner Details
          </Typography>

          <Box
            sx={{
              padding: "20px",
              borderRadius: "10px",
              border: `2px solid ${theme.text.primary}`,
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                padding: "10px",
                borderRadius: "10px",
                flexDirection: "column",
              }}
            >
              <TextFields
                label="Planner Name"
                name="plannerName"
                value={planner.plannerName}
                onChange={(e) =>
                  handleChange({
                    target: { name: "plannerName", value: e.target.value },
                  })
                }
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />

              <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <FormControl
                  sx={{ width: "200px", borderColor: theme.text.secondary }}
                >
                  <InputLabel sx={{ color: theme.text.secondary }}>
                    Origin Branch
                  </InputLabel>
                  <Select
                    label="Origin Branch"
                    value={planner.origin}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "origin", value: e.target.value },
                      })
                    }
                    name="origin"
                    sx={{
                      color: theme.text.secondary,
                      borderColor: theme.text.secondary,
                      backgroundColor: theme.button.primary,
                    }}
                  >
                    {/* Fetch and add your branch options here */}
                    {userData?.branchName && (
                      <MenuItem value={userData?._id}>
                        {userData?.branchName}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "200px" }}>
                  <InputLabel sx={{ color: theme.text.secondary }}>
                    Destination Branch
                  </InputLabel>
                  <Select
                    label="Destination Branch"
                    value={planner.destination}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "destination", value: e.target.value },
                      })
                    }
                    name="destination"
                    sx={{
                      color: theme.text.secondary,
                      borderColor: theme.text.secondary,
                      backgroundColor: theme.button.primary,
                    }}
                  >
                    {/* Fetch and add your branch options here */}
                    {branches.map((ite) => (
                      <MenuItem value={ite._id} key={ite._id}>
                        {ite.branchName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {planner.stops.map((stop, index) => (
                  <FormControl sx={{ width: "200px" }} key={index}>
                    <InputLabel sx={{ color: theme.text.secondary }}>{`Stop ${
                      index + 1
                    }`}</InputLabel>
                    <Select
                      label={`Stop ${index + 1}`}
                      value={stop.branchId}
                      onChange={(e) => handleStopChange(index, e)}
                      name={`stop-${index}`}
                      sx={{
                        color: theme.text.secondary,
                        borderColor: theme.text.secondary,
                        backgroundColor: theme.button.primary,
                      }}
                    >
                      {/* Fetch and add your branch options here */}
                      {/* <MenuItem value="branch1">Branch 1</MenuItem> */}
                      {branches
                        .filter(
                          (branch) =>
                            branch._id !== planner.destination &&
                            !planner.stops.some(
                              (s, i) => s.branchId === branch._id && i !== index
                            )
                        )
                        .map((branch) => (
                          <MenuItem key={branch._id} value={branch._id}>
                            {branch.branchName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                ))}
                <Button
                  onClick={handleAddStop}
                  sx={{ width: "max-content" }}
                  disabled={
                    branches.filter(
                      (branch) =>
                        branch._id !== planner.destination &&
                        !planner.stops.some((s) => s.branchId === branch._id)
                    ).length === 0
                  }
                >
                  Add Stop
                </Button>
              </Box>

              <TextFields
                label="Planner Details"
                name="plannerDetails"
                value={planner.plannerDetails}
                onChange={(e) =>
                  handleChange({
                    target: { name: "plannerDetails", value: e.target.value },
                  })
                }
                type="text"
                sx={{ width: "200px" }}
                color={theme.text.secondary}
                shrink={true}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {editMode ? (
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
                sx={{
                  width: "max-content",
                  backgroundColor: theme.button.active,
                  color: theme.text.secondary,
                  "&:hover": {
                    backgroundColor: theme.button.active,
                  },
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                sx={{
                  width: "max-content",
                  backgroundColor: theme.button.active,
                  color: theme.text.secondary,
                  "&:hover": {
                    backgroundColor: theme.button.active,
                  },
                }}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPlanner;

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Modal,
  TextField,
} from "@mui/material";
import ThemeContext from "../context/themeContext";
import AuthContext from "../context/authContext";
import Grid from "../components/misc/grid";
import DownloadIcon from "@mui/icons-material/Download";
import toast from "react-hot-toast";
import axiosInstance from "../api/axios";
// import { useNavigate } from "react-router-dom";
import TextFields from "../components/misc/textfield";

const confirmDeliveryStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
};

const Deliveries = () => {
  // const navigate = useNavigate();
  const { userData, setRefresh, branches } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [consignmentTableData, setConsignmentTableData] = useState([]);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [consId, setConsId] = useState("");
  const [openDeliveryConfirmation, setOpenDeliveryConfirmation] =
    useState(false);
  const [podfile, setPodfile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [reciverName, setReciverName] = useState("");
  const [reciverPhone, setReciverPhone] = useState(0);
  // const handleConfirm = () => {};
  // const handleChangeDelivery = async (id) => {};
  const handleCloseDeliveryConfirmation = () => {
    setOpenDeliveryConfirmation(false);
    setConsId("");
    setPodfile();
  };
  const handleConfirmDelivery = (id) => {
    setOpenDeliveryConfirmation(true);
    setConsId(id);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload a JPEG, JPG, or PDF file.");
      return;
    }
    const maxSize = 1048576; // 1MB in bytes
    if (file.size > maxSize) {
      toast.error("File size must be less than 1MB.");
      return;
    }

    setPodfile(file); // Set the file if validations pass
  };

  const handleViewPOD = async (id) => {
    const filepath = userData?.consignments?.find(
      (item) => item._id === id
    )?.podfile;
    if (filepath) {
      try {
        // Get the JWT token (assuming it's stored in localStorage or a similar place)
        // const token = localStorage.getItem("token");

        // Open the file in a new tab with authentication
        const response = await axiosInstance.get(
          `www.api.omvairava.com/consignment/pod/${id}`,
          {
            responseType: "blob", // To handle the file response
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filepath.split("/").pop()); // Extract file name
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error("Unauthorized or file not found");
        console.error("Error downloading file:", error);
      }
    } else {
      toast.error("POD file not found");
    }
  };

  const handleDeliveryConfirmations = async () => {
    if (!consId || !podfile) {
      toast.error("Please select POD file");
    } else {
      const formdata = new FormData();
      formdata.append("podfile", podfile);
      try {
        const res = await axiosInstance.post(
          `consignment/delivery/${consId}`,
          formdata,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (res.status === 200) {
          handleCloseDeliveryConfirmation();
          toast.success("Marked as delivered successfully");
          setRefresh(true);
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    if (userData) {
      setConsignmentTableData(
        userData.consignments?.filter(
          (item) =>
            item.consignmentStatus !== "erased" &&
            item.consignmentStatus !== "convert" &&
            item.deliverybranch === localStorage.getItem("currentbranch")
        )
      );
    }
  }, [userData]);

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      minWidth: 90,
    },

    {
      field: "lrnumber",
      headerName: "Lr No",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "pickupbranch",
      headerName: "Origin",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "deliverybranch",
      headerName: "Destination",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "actualWeight",
      headerName: "Weight",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "totalcharges",
      headerName: "Amount",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "deliverystatus",
      headerName: "Controls",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          {params.row.consignmentStatus === "Delivered to Branch" && (
            <Button
              sx={{
                padding: "5px",
                minWidth: "auto",
                color: "#000",
                "&:hover": {
                  backgroundColor: "orange",
                  color: "#fff",
                },
                backgroundColor: "orange",
                borderRadius: "10px",
              }}
              onClick={() => handleConfirmDelivery(params.row._id)}
            >
              Mark Delivered
            </Button>
          )}
        </Box>
      ),
    },

    {
      field: "consignmentStatus",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,

      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            minWidth: "100%",
            maxHeight: "100%",
            marginTop: "6px",
          }}
        >
          {params?.row.consignmentStatus === "Delivered" && (
            <Button
              title="Download POD"
              sx={{
                padding: "5px",
                minWidth: "auto",
                color: `${theme.text.secondary}`,
                "&:hover": {
                  backgroundColor: "#2E3867",
                  color: "#fff",
                },
              }}
              onClick={() => handleViewPOD(params.row._id)}
            >
              <DownloadIcon sx={{ fontSize: "25px", padding: "0px" }} />
            </Button>
          )}
        </Box>
      ),
    },
  ];

  const rows = consignmentTableData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    consignmentNumber: item.consignmentNumber,
    pickupbranch:
      branches.find((ite) => String(ite._id) === String(item.pickupbranch))
        ?.branchName ||
      (String(userData?._id) === String(item.pickupbranch) &&
        userData?.branchName),
    deliverybranch:
      branches?.find((ide) => String(ide._id) === String(item.deliverybranch))
        ?.branchName ||
      (String(userData._id) === String(item.deliverybranch) &&
        userData?.branchName),
    consignmentStatus: item.consignmentStatus,
    actualWeight: item.actualWeight,
    lrnumber: item.lrnumber.toUpperCase(),
    deliverystatus: item.tripid,
    totalcharges: item.totalcharges,
    branch: item.branch ? item.branch.name : "Unassigned",
  }));

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          color: `${theme.text.primary}`,
          fontSize: "20px",
          textAlign: "center",
        }}
      >
        Deliveries
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
        {/* Search Field */}
        <TextField
          label="Search"
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          type="text"
          variant="standard"
          size="small"
          sx={{
            width: "300px",
            backgroundColor: theme.background.button,
            borderRadius: "10px",

            "& .MuiInputBase-input": {
              color: theme.text.secondary,
            },
          }}
          InputLabelProps={{
            sx: {
              ml: 2,
              color: theme.text.primary, // Label color when not focused
              "&.Mui-focused": {
                color: theme.text.primary, // Label color when focused
              },
            },
          }}
        />
        {/* Enable Filters */}
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.text.primary, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.text.primary, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.text.primary, // Label color
            }}
          />
        </FormControl>
      </Box>

      <Box>
        <Grid
          columns={columns}
          rows={rows}
          gridToolbar={gridToolbar}
          searchQuery={searchQuery}
        />
      </Box>
      {/* Confirm Delivery */}
      <Modal
        open={openDeliveryConfirmation}
        onClose={handleCloseDeliveryConfirmation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={confirmDeliveryStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Change Delivery Status
            </Typography>
            {/* Reviever Details */}
            <Box sx={{ display: "flex", gap: "10px" }}>
              <TextFields
                name="reciverName"
                label="Reciver Name"
                value={reciverName}
                onChange={(e) => setReciverName(e.target.value)}
                type="text"
              />
              <TextFields
                name="reciverPhone"
                label="Reciver Phone"
                value={reciverPhone}
                onChange={(e) => setReciverPhone(e.target.value)}
                type="Number"
              />
            </Box>
            <Box>
              <TextField
                label="Upload POD File"
                name="podfile"
                type="file"
                InputLabelProps={{ shrink: true }}
                onChange={handleFileChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleCloseDeliveryConfirmation}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  padding: "5px",
                  minWidth: "auto",
                  color: "#fff",
                  backgroundColor: "#01B2EA",
                  "&:hover": {
                    backgroundColor: "#01B2EA",
                    color: "#fff",
                  },
                }}
                onClick={handleDeliveryConfirmations}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Deliveries;

import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ThemeContext from "../context/themeContext";
import AuthContext from "../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axiosInstance from "../api/axios";

const LoadCons = () => {
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { id } = useParams();
  const { userData, setRefresh, branches } = useContext(AuthContext);
  const [tripData, setTripData] = useState({});
  const [baseConsigns, setBaseConsigns] = useState([]);
  const [selectedConsignments, setSelectedConsignments] = useState([]);
  const [toLoad, setToLoad] = useState([]);

  const handleSelect = (consignId) => {
    const filtrd = toLoad.filter(
      (item) => String(item._id) !== String(consignId)
    );
    setToLoad(filtrd);
    const selectConsignData = baseConsigns.filter(
      (item) => String(item._id) === String(consignId)
    );
    setSelectedConsignments([...selectedConsignments, selectConsignData[0]]);
  };

  const handleReverseSelect = (consignId) => {
    const filtrd = selectedConsignments.filter(
      (item) => String(item._id) !== String(consignId)
    );
    setSelectedConsignments(filtrd);
    const selectConsignData = baseConsigns.filter(
      (item) => String(item._id) === String(consignId)
    );
    setToLoad([...toLoad, selectConsignData[0]]);
  };

  const handleConfirmLoading = async () => {
    const confirmLoading = {
      consignments: [...selectedConsignments.map((item) => item._id)],
    };

    const res = await axiosInstance.post(`/trips/confirmload/${id}`, {
      confirmLoading,
    });
    if (res.status === 200) {
      setRefresh(true);
      toast.success("Consignment Loaded Successfully");
      navigate("/dashboard/bookings/trips");
    } else {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (userData) {
      const currentTrip = userData?.trips?.find((item) => item._id === id);
      setTripData(currentTrip);

      if (currentTrip) {
        const totaltripids = [];

        const tripRoute = currentTrip?.route[0];

        if (tripRoute) {
          totaltripids.push(
            tripRoute?.origin,
            ...(tripRoute?.stops?.map((item) => item.branchId) || []),
            tripRoute?.destination
          );
        }
        const consignments = userData?.consignments?.filter(
          (item) =>
            item.consignmentStatus === "Processing" && item.isftl === false
        );

        const filtrd = consignments?.filter((item) =>
          totaltripids.includes(String(item?.deliverybranch))
        );
        setToLoad(filtrd);
        setBaseConsigns(consignments);
        // Loaded Consignments
        const loaded = userData?.consignments?.filter(
          (item) => item?.tripid === id
        );
        // setSelectedConsignments(...selectedConsignments, loaded);
      }
    } else {
      setRefresh(true);
    }
  }, [userData, id]);

  return (
    <Box sx={{ padding: "20px", height: "100%" }}>
      {/* To Load */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          padding: "20px",
          height: "80%",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            color: theme.text.primary,
            fontSize: "25px",
            fontWeight: "bolder",
          }}
        >
          {
            userData?.planner?.find((el) => el._id === tripData?.planner_id)
              ?.plannerName
          }
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
            height: "100%",
          }}
        >
          {/* To Load */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              overflow: "scroll",
              border: `2px solid ${theme.button.active}`,
              borderRadius: "10px",
              height: "60%",
              padding: "10px 20px",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: theme.text.primary,
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "20px",
              }}
            >
              Consignments to Load
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                mt: 2,
              }}
            >
              {toLoad?.map((ite) => (
                <Box
                  key={ite._id}
                  sx={{
                    backgroundColor: theme.background.button,
                    padding: "5px 15px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    color: theme.text.secondary,
                  }}
                  onClick={() => handleSelect(ite._id)}
                >
                  {/* Requested By */}
                  <Typography
                    sx={{
                      color: theme.text.primary,
                      marginTop: "10px",
                      cursor: "pointer",
                      textTransform: "capitalize",
                    }}
                  >
                    {ite.lrnumber} - {ite.serviceType}
                  </Typography>
                  <Box>
                    {/* Requested By */}
                    <Typography
                      sx={{
                        color: theme.text.primary,
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Delivery to:{" "}
                      {
                        branches?.find((el) => el._id === ite.deliverybranch)
                          ?.branchName
                      }{" "}
                      | Box - {ite.boxes?.length} | {ite.actualWeight}{" "}
                      {ite.actualWeightUom}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            {/* <Box> */}
            {/* <Typography sx={{ color: theme.text.primary, marginTop: "10px" }}>
              {
                branches?.find((el) => el._id === tripData?.deliveryBranch)
                  ?.branchName
              }
            </Typography> */}
            {/* Delivery Branch */}
            {/* <Box
              sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                flexWrap: "wrap",
                marginTop: "20px",
                color: theme.text.secondary,
              }}
            >
              {deliveryBranchConsignments?.map((ite) => (
                <Box
                  key={ite._id}
                  sx={{
                    backgroundColor: theme.background.button,
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                  // onClick={() => handleSelect(ite._id)}
                >
                  <Box sx={{ display: "flex", gap: "20px" }}>
                    <Typography>{ite.serviceType}</Typography>
                    <Typography sx={{ textTransform: "uppercase" }}>
                      {ite.lrnumber}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <Typography>{ite.SafetyPriority}</Typography>
                    <Typography>
                      {ite.actualWeight} {ite.actualWeightUom}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box> */}
            {/* Other Branches on the Way */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              {RouteStops?.reverse().map((el) => {
                const branch = branches.find((it) => it._id === el.branchId);
                const filteredConsigns = baseConsigns.filter((ite) =>
                  ite.deliverybranch.includes(el.branchId)
                );

                return (
                  <Box key={el.branchId}>
                    <Typography sx={{ color: theme.text.primary }}>
                      {branch ? branch.branchName : "Unknown Branch"}
                    </Typography>
                    {filteredConsigns.map((item) => (
                      <Box
                        key={item.lrnumber}
                        sx={{
                          backgroundColor: theme.background.button,
                          padding: "10px",
                          borderRadius: "10px",
                          marginTop: "10px",
                          color: theme.text.secondary,
                        }}
                        // onClick={() => handleSelect(item._id)}
                      >
                        <Box sx={{ display: "flex", gap: "20px" }}>
                          <Typography>{item.serviceType}</Typography>
                          <Typography sx={{ textTransform: "uppercase" }}>
                            {item.lrnumber}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "20px" }}>
                          <Typography>{item.SafetyPriority}</Typography>
                          <Typography>
                            {item.actualWeight} {item.actualWeightUom}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                );
              })}
            </Box> */}
            {/* </Box> */}
          </Box>
          {/* Selected Loaded Consignments */}
          <Box
            sx={{
              display: "flex",
              flex: 1,

              border: `2px solid ${theme.button.active}`,
              borderRadius: "10px",
              height: "60%",
              padding: "10px 20px",
              overflow: "auto",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: theme.text.primary,
                textAlign: "center",
                fontWeight: "bolder",
                fontSize: "20px",
              }}
            >
              Loaded Consignments
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                mt: 2,
              }}
            >
              {selectedConsignments?.map((ite) => (
                <Box
                  key={ite._id}
                  sx={{
                    backgroundColor: theme.background.button,
                    padding: "5px 15px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleReverseSelect(ite._id)}
                >
                  {/* Requested By */}
                  <Typography
                    sx={{
                      color: theme.text.primary,
                      marginTop: "10px",
                      cursor: "pointer",
                      textTransform: "capitalize",
                    }}
                  >
                    {ite.lrnumber} - {ite.serviceType}
                  </Typography>
                  <Box>
                    {/* Requested By */}
                    <Typography
                      sx={{
                        color: theme.text.primary,
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Delivery to:{" "}
                      {
                        branches?.find((el) => el._id === ite.deliverybranch)
                          ?.branchName
                      }{" "}
                      | Box - {ite.boxes?.length} | {ite.actualWeight}{" "}
                      {ite.actualWeightUom}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
        <Button
          sx={{
            backgroundColor: theme.button.active,
            color: "#fff",
            "&:hover": {
              backgroundColor: theme.button.active,
            },
          }}
          onClick={handleConfirmLoading}
        >
          Confirm Loading
        </Button>
      </Box>
    </Box>
  );
};

export default LoadCons;
